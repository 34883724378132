export function MoreButton() {
  return (
    <path
      d="M12.707 11.293a1 1 0 11-1.414 1.414 1 1 0 011.414-1.414zM12.707 4.293a1 1 0 11-1.414 1.414 1 1 0 011.414-1.414zM12.707 18.293a1 1 0 11-1.414 1.414 1 1 0 011.414-1.414z"
      stroke="#BDBDBD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
}
