export function IconShapeClassic() {
  return (
    <>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
        fill="none"
        d="M15 28l-3-3V15l3-3h10l3 3v10l-3 3H15zM12 15l-6-4M15 12l-4-6M25 12l3-6M15 28l-4 6M12 25l-6 3M25 28l3 6M28 25l6 3M28 15l6-4"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
        d="M15.243 35C12.526 35 9.92 33.92 8 32v0c-1.92-1.92-3-4.526-3-7.243v-9.514C5 12.526 6.08 9.92 8 8v0c1.92-1.92 4.526-3 7.243-3h9.514C27.474 5 30.08 6.08 32 8v0c1.92 1.92 3 4.526 3 7.243v9.514c0 2.717-1.08 5.322-3 7.243v0c-1.92 1.92-4.526 3-7.243 3h-9.514z"
      ></path>
    </>
  )
}
