import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading';
import { parse } from '@config/theme';
import { CleanButton, Icon } from '@gassan-ui';
import { FC } from 'react';
type Props = {
  onClickClose: () => void;
};
export const HeaderSearchHeader: FC<Props> = ({
  onClickClose,
  children
}) => {
  return <div className={parent}>
      <div className={content}>
        <CleanButton onClick={onClickClose}>
          <Icon icon="cross" />
        </CleanButton>
        <LinariaHeading variant="h3" as="h3" mb="0">
          {children}
        </LinariaHeading>
        <div className={spacer} />
      </div>
    </div>;
};
const parent = parse({
  display: {
    _: 'flex',
    large: 'none'
  },
  justifyContent: 'space-between',
  position: 'absolute',
  width: '100%',
  bg: 'pampas'
}, "p167sifu");
const content = parse({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center'
}, "cvtpuam");
const spacer = "s23g7df";

require("./Header.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Header.tsx");