// @ts-strict-ignore
import Cookies from 'js-cookie'
import { useTranslation } from 'next-i18next'
import { NextRouter } from 'next/router'
import React, { FC, useContext } from 'react'

type State = {
  language: string
}

type ContextType = {
  setLang: (lang: string) => void
  parseGraphQlError: (errorMessage: string) => string
}

type ProviderContext = State & ContextType

const Context = React.createContext<ProviderContext>({
  language: 'nl',
  setLang: (lang: string) => null,
  parseGraphQlError: (errorMessage: string) => null,
})

interface Props {
  initialLang: string
  languages: string[]
  router: NextRouter
}

const LocalizationProvider: FC<Props> = ({ initialLang, children, languages, router }) => {
  const { t, i18n } = useTranslation('user-errors')
  const handleSetLang = async (newLang: string) => {
    if (typeof window === 'undefined') return

    if (languages.includes(newLang)) {
      Cookies.set('NEXT_LOCALE', newLang, { expires: 365 })
      const alternate: HTMLLinkElement | null = document.querySelector(
        `link[hreflang="${newLang}"]`,
      )
      if (alternate && alternate.href) {
        // We're using window.location.href to reload the page entirely
        // this ensures all scripts are reloaded with the correct language without having to check that manually
        window.location.href = alternate.href
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.error('No alternate hreflang found')
        }
      }
    }
  }
  const handleParseGraphQlError = (errorMessage: string) => {
    if (errorMessage.includes('invalid loginID or password')) {
      return t('user-error-password-invalid')
    } else if (errorMessage.includes('user-error')) {
      let key = errorMessage.replace('[GraphQL] ', '').replace('user-error:', 'user-error-')
      return i18n.exists(key, { ns: 'user-errors' }) ? t(key) : t('pleaseTryAgain')
    } else {
      return t('pleaseTryAgain')
    }
  }

  return (
    <Context.Provider
      value={{
        language: initialLang,
        setLang: handleSetLang,
        parseGraphQlError: handleParseGraphQlError,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useLocalization = (): ProviderContext => {
  const ctx = useContext(Context)
  if (typeof ctx === 'undefined') {
    throw new Error('Can only use useLocalization within a LocalizationProvider')
  }
  return ctx
}

export default LocalizationProvider
