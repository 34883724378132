import { ImageLinkColumn } from '../utils/types'
import * as styles from './styles'
import { Image } from '@components/gassan-ui'
import { getKontentImage } from '@lib/kontent'
import Link from 'next/link'
import { FC } from 'react'

type HeaderImageColumnProps = ImageLinkColumn & {
  showOnlyOnXlarge?: boolean
}

export const HeaderImageColumn: FC<HeaderImageColumnProps> = ({
  mobileSrc,
  desktopSrc,
  alt,
  url,
  label,
  showOnlyOnXlarge = false,
}) => {
  return (
    <div className={styles.wrap} data-show-on-xlarge={showOnlyOnXlarge ? '' : undefined}>
      <Link href={url}>
        <a className={styles.parent}>
          <Image
            placeholderSrc={getKontentImage(desktopSrc, {
              width: 40,
            })}
            sources={[
              getKontentImage(mobileSrc, { width: 768 }),
              getKontentImage(mobileSrc, { width: 1024 }),
              getKontentImage(desktopSrc, { width: 600 }),
            ]}
            className="absolute inset-0 z-[-1]"
            alt={alt || ''}
          />
          <div className={styles.content} data-content>
            <div className={styles.title} data-title>
              {label}
            </div>
          </div>
          <div className={styles.hoverContent} data-hover-content>
            <div className={styles.title}>{label}</div>
          </div>
        </a>
      </Link>
    </div>
  )
}
