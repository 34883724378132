import { Text } from './gassan-ui'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useRef } from 'react'

type TrustPilotWidgetProps = {
  templateId: string
  businessUnitId: string
  height: string
  width: string
}

const locales = {
  nl: 'nl-NL',
  en: 'en-GB',
  de: 'de-DE',
}
type Locale = keyof typeof locales

export const TrustPilotWidget: FC<TrustPilotWidgetProps> = ({
  templateId,
  businessUnitId,
  height,
  width,
}) => {
  const ref = useRef(null)
  const { locale } = useRouter()
  const trustPilotLocale = locale ? locales[locale as Locale] : locales['en']

  useEffect(() => {
    if (ref.current && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={trustPilotLocale}
      data-template-id={templateId}
      data-businessunit-id={businessUnitId}
      data-style-height={height}
      data-style-width={width}
      data-theme="light"
    >
      <a
        href="https://nl.trustpilot.com/review/gassan.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text variant="small" mb="0">
          Trustpilot
        </Text>
      </a>
    </div>
  )
}
