export function IconGoldColor() {
  return (
    <>
      <path
        fill="currentColor"
        d="M20 20.386l-.767.641a1 1 0 001.534 0L20 20.386zm-7.917-9.468l-.838-.546-.403.62.474.568.767-.642zm2.88-4.418v-1a1 1 0 00-.839.454l.838.546zm10.075 0l.838-.546a1 1 0 00-.838-.454v1zm2.879 4.418l.767.642.474-.568-.404-.62-.837.546zM20 6.5l.79-.614h-1.58L20 6.5zm8.5 17.743c0 4.536-3.781 8.257-8.5 8.257v2c5.774 0 10.5-4.568 10.5-10.257h-2zM20 32.5c-4.719 0-8.5-3.721-8.5-8.257h-2C9.5 29.932 14.226 34.5 20 34.5v-2zm-8.5-8.257c0-4.536 3.781-8.257 8.5-8.257v-2c-5.774 0-10.5 4.568-10.5 10.257h2zm8.5-8.257c4.719 0 8.5 3.72 8.5 8.257h2c0-5.69-4.726-10.257-10.5-10.257v2zm.767 3.758l-7.916-9.467-1.535 1.283 7.917 9.467 1.534-1.283zm-7.846-8.28L15.8 7.046l-1.676-1.092-2.879 4.418 1.676 1.092zM14.962 7.5h10.076v-2H14.962v2zm9.238-.454l2.879 4.418 1.675-1.092-2.878-4.418L24.2 7.046zm2.95 3.23l-7.917 9.468 1.534 1.283 7.917-9.467-1.535-1.283zm-11.525.983l3.435 9.468 1.88-.682-3.435-9.468-1.88.682zm-3.542.66h4.482v-2h-4.482v2zm7.127-6.033l-3.434 4.418 1.579 1.228 3.434-4.418-1.579-1.228zm-2.645 6.032h6.87v-2h-6.87v2zm6.87 0h4.482v-2h-4.482v2zm-2.495 8.809l3.435-9.468-1.88-.682-3.435 9.468 1.88.682zM19.21 7.114l3.435 4.418 1.58-1.228-3.436-4.418-1.579 1.228z"
        className="hide-on-small"
      ></path>
      <path
        fill="currentColor"
        d="M20 20.365l-.388.316a.5.5 0 00.775 0L20 20.365zm-7.308-8.956l-.422-.268-.194.304.229.28.387-.316zm2.657-4.18v-.5a.5.5 0 00-.422.232l.422.269zm9.3 0l.423-.268a.5.5 0 00-.422-.231v.5zm2.658 4.18l.388.316.228-.28-.194-.304-.422.268zM20 7.23l.398-.301H19.6L20 7.23zm8.269 16.784c0 4.56-3.702 8.257-8.27 8.257v1c5.12 0 9.27-4.144 9.27-9.257h-1zm-8.27 8.257c-4.567 0-8.268-3.697-8.268-8.257h-1c0 5.113 4.15 9.257 9.269 9.257v-1zm-8.268-8.257c0-4.559 3.701-8.256 8.269-8.256v-1c-5.119 0-9.27 4.143-9.27 9.256h1zM20 15.757c4.567 0 8.269 3.697 8.269 8.256h1c0-5.113-4.15-9.256-9.27-9.256v1zm.387 4.292l-7.308-8.956-.774.632 7.307 8.956.775-.632zm-7.273-8.372l2.657-4.179-.844-.537-2.657 4.18.844.536zm2.235-3.947h9.3v-1h-9.3v1zm8.879-.232l2.657 4.18.844-.537-2.657-4.18-.844.537zm2.692 3.595l-7.308 8.956.775.632 7.308-8.956-.775-.632zm-10.562.483l3.17 8.956.943-.334-3.17-8.956-.943.334zm-3.666.333h4.137v-1h-4.137v1zm6.91-4.981l-3.171 4.179.796.604 3.17-4.18-.796-.603zm-2.773 4.981h6.341v-1H16.83v1zm6.341 0h4.137v-1H23.17v1zm-2.7 8.623l3.172-8.956-.943-.334-3.17 8.956.942.334zm-.869-13l3.17 4.18.798-.605-3.171-4.18-.797.605z"
        className="show-on-small"
      ></path>
    </>
  )
}
