import React, { FC, LabelHTMLAttributes } from 'react'

type CookiesLabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  children: React.ReactNode
}

export const CookiesLabel: FC<CookiesLabelProps> = ({ children, ...rest }) => {
  return (
    <label className="m-0 border-b-transparent pr-4" {...rest}>
      {children}
    </label>
  )
}
