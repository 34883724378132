import { parse } from '@config/theme';
export const parent = parse({
  position: 'relative',
  display: 'grid',
  justifyContent: 'center',
  alignItems: 'center'
}, "p1744zdm");
export const logo = "llgv648";
export const hamburgerContainer = "h1m42fw6";

require("./styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");