import { parse } from '@config/theme';
export const parent = parse({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}, "p1blhkby");
export const iconButton = parse({
  position: 'relative',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  ml: {
    _: '0',
    small: '3',
    xlarge: '4'
  }
}, "iuenrua");
export const wishlistIndicator = parse({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  fontFamily: 'specials',
  bg: 'bigStoneLight',
  color: 'white'
}, "wpnsng1");
export const rolexClock = parse({
  display: {
    _: 'none',
    large: 'block'
  },
  ml: '4'
});

require("./SecondaryNav.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SecondaryNav.styles.ts");