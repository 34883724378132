import LocalizedLink from '@components/Localization/LocalizedLink'
import { TrustPilotWidget } from '@components/TrustPilotWidget'
import { CleanButton, Container, Icon, IconType } from '@components/gassan-ui'
import { cn } from '@lib/cn'
import { useAuth } from '@lib/hooks/use-auth'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { FC } from 'react'
import { PreHeaderDropdownItem } from './PreHeaderDropdownItem'

export const PreHeader: FC = () => {
  const { t } = useTranslation(['navigation', 'other'])
  const { isLoggedIn, isLoading } = useAuth()
  const { logout } = useAuth()

  return (
    <div className="hidden w-full bg-pampasLight lg:block">
      <Container>
        <NavigationMenu.Root className="relative z-[100]">
          <NavigationMenu.List className="flex items-center justify-end gap-8 py-1">
            <TrustPilotWidget
              templateId="5419b637fa0340045cd0c936"
              businessUnitId="552f9c6e0000ff00057ecd72"
              height="20px"
              width="230px"
            />
            {getItems().map((navItem) => {
              const icon = (
                <span className="flex h-8 w-8 items-center justify-center">
                  <Icon icon={navItem.icon as IconType} dynamicStroke={false} />
                </span>
              )

              const label = (
                <span className="border-b border-transparent group-hover:border-b-bigStone">
                  {navItem.title}
                </span>
              )

              const anchorClass = 'group flex items-center text-sm text-bigStone'

              return (
                <NavigationMenu.Item className="relative" key={navItem.title}>
                  {navItem.href ? (
                    <LocalizedLink href={navItem.href} legacyBehavior={false}>
                      <a className={anchorClass}>
                        {icon}
                        {label}
                      </a>
                    </LocalizedLink>
                  ) : (
                    <>
                      <NavigationMenu.Trigger
                        className={anchorClass}
                        onPointerMove={preventHover}
                        onPointerLeave={preventHover}
                      >
                        <CleanButton className={anchorClass}>
                          {icon}
                          {label}
                        </CleanButton>
                      </NavigationMenu.Trigger>
                      <NavigationMenu.Content
                        className={cn(
                          'absolute right-0 top-[calc(100%+4px)] w-[200px] py-2',
                          'flex flex-col items-stretch',
                          'border border-shade-200 bg-white shadow-depth-1',
                        )}
                      >
                        {navItem.items &&
                          navItem.items.map((dropdownItem) => {
                            return (
                              <NavigationMenu.Link asChild key={dropdownItem.title}>
                                {dropdownItem.href ? (
                                  <Link href={dropdownItem.href} className="bg-oldLace" passHref>
                                    <a>
                                      <PreHeaderDropdownItem
                                        icon={dropdownItem.icon as IconType}
                                        title={dropdownItem.title}
                                      />
                                    </a>
                                  </Link>
                                ) : (
                                  <CleanButton
                                    onClick={() => dropdownItem.onClick && dropdownItem.onClick()}
                                  >
                                    <PreHeaderDropdownItem
                                      icon={dropdownItem.icon as IconType}
                                      title={dropdownItem.title}
                                    />
                                  </CleanButton>
                                )}
                              </NavigationMenu.Link>
                            )
                          })}
                      </NavigationMenu.Content>
                    </>
                  )}
                </NavigationMenu.Item>
              )
            })}
          </NavigationMenu.List>
        </NavigationMenu.Root>
      </Container>
    </div>
  )
  function getItems(): Array<
    | {
        icon: IconType
        title: string
        href: string
        items?: never
      }
    | {
        icon: IconType
        title: string
        href?: never
        items: { icon?: IconType; title: string; href?: string; onClick?(): void }[]
      }
  > {
    if (isLoading || !isLoggedIn) {
      return [
        {
          icon: 'account-contact',
          title: t('secondaryNav.contact.title'),
          href: t('secondaryNav.contact.url'),
        },
        {
          icon: 'diamond',
          title: t('secondaryNav.myGassan.title'),
          href: t('secondaryNav.myGassan.url'),
        },
        {
          icon: 'account-profile',
          title: t('account.login.title'),
          href: t('account.login.url'),
        },
      ]
    }

    return [
      {
        icon: 'account-contact',
        title: t('secondaryNav.contact.title'),
        href: t('secondaryNav.contact.url'),
      },
      {
        icon: 'diamond',
        title: t('secondaryNav.myGassan.title'),
        items: [
          {
            icon: 'diamond',
            title: t('membership.overview.title'),
            href: t('membership.overview.url'),
          },
          {
            icon: 'account-my-gassan',
            title: t('membership.my-membership.title'),
            href: t('membership.my-membership.url'),
          },
          {
            icon: 'account-star',
            title: t('membership.privileges.title'),
            href: t('membership.privileges.url'),
          },
          {
            icon: 'account-order',
            title: t('membership.orders.title'),
            href: t('membership.orders.url'),
          },
          {
            icon: 'account-document',
            title: t('membership.documents.title'),
            href: t('membership.documents.url'),
          },
          {
            icon: 'account-heart',
            title: t('membership.wishlist.title'),
            href: t('membership.wishlist.url'),
          },
          {
            icon: 'account-giftcard',
            title: t('membership.giftcards.title'),
            href: t('membership.giftcards.url'),
          },
          {
            icon: 'account-check',
            title: t('membership.preferences.title'),
            href: t('membership.preferences.url'),
          },
        ],
      },
      {
        icon: 'account-profile',
        title: t('secondaryNav.account.title'),
        items: [
          {
            title: t('account.overview.title'),
            href: t('account.overview.url'),
          },
          {
            title: t('account.accountInfo.title'),
            href: t('account.accountInfo.url'),
          },
          {
            title: t('account.contactPreferences.title'),
            href: t('account.contactPreferences.url'),
          },
          {
            title: t('account.addresses.title'),
            href: t('account.addresses.url'),
          },
          {
            title: t('logOut', { ns: 'other' }),
            onClick: () => logout(),
          },
        ],
      },
    ]
  }
}

/*
Used to block default radix hover to open menu behaviour
Props: https://github.com/radix-ui/primitives/issues/1630
*/
function preventHover(event: any) {
  const e = event as Event
  e.preventDefault()
}
