export function AlwaysInsured() {
  return (
    <>
      <path
        d="M18.3889 28.6613H5.5V13.7419M31.2778 18.5806V8.5H5.5V13.7419M5.5 13.7419H30.4722"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
      />
      <ellipse
        cx="27.6529"
        cy="26.6452"
        rx="6.84722"
        ry="6.85484"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
      />
      <path
        d="M24.0273 26.9018L26.444 29.4677L31.2773 23.8226"
        className="dynamic-stroke-width"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
      />
    </>
  )
}
