export const getClosestIndex = (element: HTMLElement): number => {
  const parentRect = element.getBoundingClientRect()

  // Creates an array with the relative distances to the parent e.g. [390, -2, -396]
  // The closest can be -2 or -10 or something instead of 0, because scrolling can still be underway due to snap point
  const distances = Array.from(element.children).map((child, index) =>
    Math.round(parentRect.x - child.getBoundingClientRect().x),
  )
  // Get the index of the value that's closest to 0. E.g. [390, -2, -396].indexOf(-2) === 1
  const closestIndex = distances.indexOf(
    distances.reduce((prev, curr) => (Math.abs(curr - 0) < Math.abs(prev - 0) ? curr : prev)),
  )
  return closestIndex
}
