import { Box, Container, Heading, Text } from '@gassan-ui'
import React, { FC } from 'react'
import { MaxWidthProps, PaddingBottomProps, TextAlignProps } from 'styled-system'

type Props = PaddingBottomProps &
  TextAlignProps &
  MaxWidthProps & {
    title: string
    text?: string
    link?: React.ReactChild
  }

const PageHeader: FC<Props> = ({
  title,
  text,
  link,
  pb = { _: '2.5rem', large: '4rem', xlarge: '5rem' },
  textAlign = 'left',
  maxWidth,
}) => {
  return (
    <Container maxWidth={maxWidth}>
      <Box
        maxWidth="35rem"
        pt={{ _: '1.5rem', small: '3rem', large: '5rem', xlarge: '7rem' }}
        pb={pb}
        textAlign={textAlign}
      >
        <Heading variant="h1" as="h1" mb={['1rem', '1.5rem']}>
          {title}
        </Heading>
        {text && <Text mb={0}>{text}</Text>}
        {link && <Box mt={{ _: '1.5rem', small: '2rem', large: '2.5rem' }}>{link}</Box>}
      </Box>
    </Container>
  )
}

export default PageHeader
