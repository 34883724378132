// @ts-strict-ignore
import { useLocalization } from '@components/Localization'
import { Flex, Heading, Listbox } from '@gassan-ui'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useMemo } from 'react'
import styled from 'styled-components'

const Item = styled(Flex)`
  align-items: center;

  img {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
`

const flag = {
  en: { image: '/logos/flag_en.png', text: 'english' },
  nl: { image: '/logos/flag_nl.png', text: 'dutch' },
  de: { image: '/logos/flag_de.png', text: 'german' },
}

const LanguageDropdown: FC = () => {
  const { locale: lang } = useRouter()
  const { setLang } = useLocalization()
  const { t } = useTranslation('global')

  const items = useMemo(() => {
    return [
      {
        value: 'nl',
        label: (
          <Item>
            <img src={flag['nl'].image} alt="flag of nl" />
            {t(flag['nl'].text)}
          </Item>
        ),
      },
      {
        value: 'en',
        label: (
          <Item>
            <img src={flag['en'].image} alt="flag of en" />
            {t(flag['en'].text)}
          </Item>
        ),
      },
    ]
  }, [t]) // eslint-disable-line

  return (
    <>
      <Heading
        variant="h4"
        as="h4"
        mb={{
          _: '0.5rem',
          small: '1rem',
        }}
      ></Heading>
      <Listbox items={items} value={lang} onChange={setLang}>
        <Item>
          <img src={flag[lang]?.image} alt={`flag of ${lang}`} />
          {t(flag[lang]?.text)}
        </Item>
      </Listbox>
    </>
  )
}

export default LanguageDropdown
