import { getCookieSettings, hasCookieConsent } from '@lib/cookies'
import { useThirdPartyScriptStore } from '@lib/hooks/useThirdPartyScript'
import { useEmarsysStore } from '@lib/use-emarsys-store'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { FC, useEffect } from 'react'

export const Scripts: FC = () => {
  const { setScriptIsLoaded: setEmarsysScriptLoaded } = useEmarsysStore()
  const { setScriptIsLoaded } = useThirdPartyScriptStore()
  const cookieSettings = getCookieSettings()
  const gtmConsent = cookieSettings.includes('personal') ? 'granted' : 'denied'
  const { locale } = useRouter()

  useEffect(() => {
    if (!hasCookieConsent) return
    if (window.gtag === undefined) return

    // Update consent in Google Tag Manager
    //
    // Have to call one at a time
    window.gtag('consent', 'update', {
      ad_storage: gtmConsent,
    })
    window.gtag('consent', 'update', {
      ad_personalization: gtmConsent,
    })
    window.gtag('consent', 'update', {
      ad_user_data: gtmConsent,
    })
  }, [cookieSettings, gtmConsent])

  return (
    <>
      {['staging', 'preview', 'development'].includes(process.env.NEXT_PUBLIC_STAGE as string) && (
        <Script
          type="text/javascript"
          src="//cdn.scarabresearch.com/js/191DE6DDDBE2E02C/scarab-v2.js"
          strategy="afterInteractive"
          onLoad={() => setEmarsysScriptLoaded()}
          async
        />
      )}
      {['production'].includes(process.env.NEXT_PUBLIC_STAGE as string) && (
        <Script
          type="text/javascript"
          src="//cdn.scarabresearch.com/js/1254709CF2F17E59/scarab-v2.js"
          strategy="afterInteractive"
          onLoad={() => setEmarsysScriptLoaded()}
          async
        />
      )}
      {['staging', 'preview', 'production', 'development'].includes(
        process.env.NEXT_PUBLIC_STAGE as string,
      ) && (
        <>
          <Script
            type="text/javascript"
            src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
            strategy="afterInteractive"
            async
          />
          <Script
            src="https://static.rolex.com/retailers/clock/retailercall.js"
            strategy="afterInteractive"
            onLoad={() => setScriptIsLoaded('rolex')}
          />
          <Script
            id="tag-manager"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              // Setup gtag function & default consent
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_personalization': 'denied',
                'ad_user_data': 'denied',
                'analytics_storage': 'granted'
              });
              // Tag manager
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', 'GTM-WRDV5CC');
            `,
            }}
          />
        </>
      )}
      <Script
        type="text/javascript"
        id="gigya-script"
        key={`gigya-script-${locale}`}
        src={`https://cdns.eu1.gigya.com/js/gigya.js?apikey=${
          process.env.NEXT_PUBLIC_GIGYA_API_KEY as string
        }&lang=${locale}`}
        strategy="afterInteractive"
      />
    </>
  )
}
