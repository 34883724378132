import { breakpoints } from '../theme'
import { regular } from './Text'
import styled from 'styled-components'

export const Checklist = styled.ul`
  ${regular};

  li {
    position: relative;
    list-style-image: url(/images/checkmark-small.svg);
    padding-left: 0.25rem;
    margin-left: 1.25rem;
    list-style-position: outside;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
    @media screen and (min-width: ${breakpoints.small}) {
      list-style-image: url(/images/checkmark.svg);
    }
  }
`
