import { parse } from '@config/theme';
export const scrollContainer = parse({
  height: '100%'
}, "s17931u9");
export const closeButton = parse({
  color: 'shade600',
  display: 'flex',
  alignItems: 'center'
}, "c6c3l9c");
export const parent = parse({
  position: {
    _: 'absolute',
    large: 'relative'
  },
  width: {
    _: '100%',
    large: 'auto'
  },
  height: {
    _: '100%',
    large: 'auto'
  }
}, "p1kvuzzv");
export const content = parse({
  width: '100%'
}, "cfa1a5r");
export const buttonParent = parse({
  display: {
    _: 'none',
    large: 'flex'
  },
  justifyContent: 'flex-end',
  mt: '4'
});

require("./styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");