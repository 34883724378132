import { useCartReferenceStore } from '@components/LoadCart'
import { CartFragmentFragment, useCartQuery } from '@generated'

export function useCart(): null | CartFragmentFragment {
  const cartReferenceStore = useCartReferenceStore()
  let pause = true
  if (
    cartReferenceStore.reference !== null &&
    !cartReferenceStore.reference.includes('NOT-EXISTING')
  ) {
    pause = false
  }
  const [{ data }] = useCartQuery({
    variables: { reference: cartReferenceStore.reference },
    pause: pause,
  })

  return data?.cart || null
}
