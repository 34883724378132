import * as S from './elements'
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from 'react'
import { DisplayProps, SpaceProps } from 'styled-system'

export type BaseLink = SpaceProps &
  DisplayProps & {
    variant?: 'brown' | 'blue' | 'grey'
  }

type LinkType = BaseLink &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    as?: 'link'
  }

type ButtonType = BaseLink &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    as?: 'button'
  }

type LinkTypes = LinkType | ButtonType

export type LinkProps = BaseLink & LinkTypes

export const Link: FC<LinkProps> = React.forwardRef((props, ref: any) => {
  const { as, children, variant = 'blue', ...rest } = props
  return (
    <S.Link as={as === 'button' ? 'button' : 'a'} ref={ref} variant={variant} {...rest}>
      {children}
    </S.Link>
  )
})
