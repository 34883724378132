import { bodyRegularStyle } from '../Typography/styles'
import { rem } from 'polished'
import { ButtonHTMLAttributes, FC } from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  ${bodyRegularStyle};
  display: flex;
  align-items: center;
  outline: 0;
  margin-bottom: 1rem;
  &[aria-expanded='true'] {
    font-weight: bold !important;
  }

  img {
    display: inline-block;
    width: ${rem(12)};
    height: ${rem(12)};
    margin-top: 2px;
    margin-left: 0.5rem;
  }
`

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {}

export const FilterShowMoreButton: FC<Props> = ({ children, ...rest }) => {
  return (
    <StyledButton {...rest}>
      {children} <img src="/images/plus.svg" alt="" />
    </StyledButton>
  )
}
