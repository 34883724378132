import { SearchClient } from 'algoliasearch'
import algolia from 'algoliasearch/lite'

let client: null | SearchClient = null

export const getAlgoliaClient = () => {
  if (!client) {
    client = algolia('YJ924I42UA', '2e88f526cbb59d8c4fcad68be56feec9') as SearchClient
  }

  return client
}
