export function Exclamation() {
  return (
    <>
      <g className="hide-on-small">
        <path
          fill="currentColor"
          d="M22.1053 13H20.0526H18L19.2717 21.9016C19.3272 22.2903 19.66 22.5789 20.0526 22.5789C20.4452 22.5789 20.7781 22.2903 20.8336 21.9016L22.1053 13Z"
        />
        <circle fill="currentColor" cx="20.053" cy="24.6316" r="1.36842" />
      </g>
      <g className="show-on-small">
        <path
          fill="currentColor"
          d="M22.6316 12.5H20.0658H17.5L19.0896 23.627C19.159 24.1128 19.575 24.4737 20.0658 24.4737C20.5565 24.4737 20.9726 24.1128 21.042 23.627L22.6316 12.5Z"
        />
        <circle fill="currentColor" cx="20.066" cy="27.0395" r="1.71053" />
      </g>
    </>
  )
}
