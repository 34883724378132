import React, { FC } from 'react'
import { Icon, IconType } from '@components/gassan-ui'

type PreHeaderDropdownItemProps = {
  icon: IconType
  title: string
}

export const PreHeaderDropdownItem: FC<PreHeaderDropdownItemProps> = ({ icon, title }) => {
  return (
    <div className="flex h-10 items-center gap-1 px-4 hover:bg-shade-100">
      {icon && (
        <span className="-ml-2 flex h-8 w-8 items-center justify-center text-shade-600">
          <Icon icon={icon} dynamicStroke={false} />
        </span>
      )}
      <span className="text-base text-shade-800">{title}</span>
    </div>
  )
}
