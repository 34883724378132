import { Box, Icon, Text } from '@components/gassan-ui'
import React, { AnchorHTMLAttributes, FC } from 'react'

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  title: string
}

export const CheckoutLink: FC<Props> = React.forwardRef(({ title, ...rest }, ref) => {
  return (
    <Box
      as="a"
      display="flex"
      alignItems="center"
      color="shades.500"
      ml="-0.75rem"
      ref={ref as any}
      title={title}
      {...rest}
    >
      <Icon icon="chevron-left-small" />
      <Text variant="micro" as="span" mb="0" color="shades.500">
        {title}
      </Text>
    </Box>
  )
})
