export function IconAccountStar() {
  return (
    <>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        className="dynamic-stroke-width"
        d="M20 10l3.09 6.26L30 17.27l-5 4.87 1.18 6.88L20 25.77l-6.18 3.25L15 22.14l-5-4.87 6.91-1.01L20 10z"
      />
    </>
  )
}
