// @ts-strict-ignore
import { CleanButton } from '../Button'
import { Icon } from '../Icon'
import { Box } from '../Layout'
import { Menu } from './Menu'
import { MenuItem } from './MenuItem'
import Downshift from 'downshift'
import React, { FC } from 'react'

export type Item = { value: string | number; label: React.ReactChild }

type Props = {
  items: Item[]
  onChange: (value: string) => void
  value: string
  align?: 'left' | 'right'
}

export const MenuButton: FC<Props> = ({ items, onChange, value, children, align = 'left' }) => {
  return (
    <Downshift
      selectedItem={null}
      itemToString={(item) => item && item.value}
      onSelect={(item) => {
        if (item) {
          onChange(item.value)
        }
      }}
    >
      {({ getToggleButtonProps, getMenuProps, getRootProps, getItemProps, isOpen }) => {
        return (
          <Box {...getRootProps()} position="relative" display="inline-block">
            <CleanButton {...getToggleButtonProps()}>{children}</CleanButton>
            {isOpen && (
              <Menu
                {...getMenuProps()}
                pt=".75rem"
                pb=".75rem"
                right={align === 'right' ? 0 : undefined}
              >
                {items.map((item, index) => (
                  <MenuItem
                    {...getItemProps({ item, index })}
                    key={`item-${index}`}
                    isActive={item.value === value}
                  >
                    {item.label}
                    {item.value === value && <Icon icon="check" />}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </Box>
        )
      }}
    </Downshift>
  )
}
