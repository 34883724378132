import { FC } from 'react'
import styled, { keyframes } from 'styled-components'
import { SizeProps, size } from 'styled-system'

const spin = keyframes`
 from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const RotatingCircle = styled.circle`
  transform-origin: 50% 50%;
  animation-name: ${spin};
  animation-timing-function: linear;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
`

const Svg = styled.svg<SizeProps>`
  ${size};
`

interface Props extends SizeProps {}

export const Spinner: FC<Props> = ({ size = 32, ...rest }) => {
  const strokeWidth = 3
  const r = 16 - strokeWidth
  const C = 2 * r * Math.PI
  const offset = C - (1 / 4) * C
  return (
    <Svg
      viewBox="0 0 32 32"
      strokeWidth={strokeWidth}
      fill="none"
      stroke="currentcolor"
      role="img"
      size={size}
      {...rest}
    >
      <circle cx={16} cy={16} r={r} opacity={1 / 8} />
      <RotatingCircle cx={16} cy={16} r={r} strokeDasharray={C} strokeDashoffset={offset} />
    </Svg>
  )
}
