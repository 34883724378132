// @ts-strict-ignore
import * as Elements from '@lib/kontent/models/Elements'
import { useTranslation } from 'next-i18next'
import { NextSeo, NextSeoProps } from 'next-seo'
import { MetaTag, OpenGraph } from 'next-seo/lib/types'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC } from 'react'

type LanguageAlternate = {
  hrefLang: string
  href: string
}

type SEOProps = {
  url_slug?: Elements.UrlSlugElement
  seo_metadata__alternate_url_slug?: Elements.TextElement
  seo_metadata__meta_og_image?: Elements.AssetsElement
  seo_metadata__description?: Elements.TextElement
  seo_metadata__title?: Elements.TextElement
  seo_metadata__meta_og_description?: Elements.TextElement
  seo_metadata__meta_og_title?: Elements.TextElement
  seo_metadata__meta_twitter_image?: Elements.AssetsElement
  seo_metadata__meta_twitter_title?: Elements.TextElement
  seo_metadata__meta_twitter_description?: Elements.TextElement
}

export const pickSeoProps = (props: SEOProps): SEOProps => {
  const {
    url_slug,
    seo_metadata__alternate_url_slug,
    seo_metadata__meta_og_image,
    seo_metadata__description,
    seo_metadata__title,
    seo_metadata__meta_og_description,
    seo_metadata__meta_og_title,
    seo_metadata__meta_twitter_image,
    seo_metadata__meta_twitter_title,
    seo_metadata__meta_twitter_description,
  } = props

  return {
    url_slug,
    seo_metadata__alternate_url_slug,
    seo_metadata__meta_og_image,
    seo_metadata__description,
    seo_metadata__title,
    seo_metadata__meta_og_description,
    seo_metadata__meta_og_title,
    seo_metadata__meta_twitter_image,
    seo_metadata__meta_twitter_title,
    seo_metadata__meta_twitter_description,
  }
}

export type PageProps = SEOProps &
  NextSeoProps & {
    slug?: string
    altSlug?: string
    hideCanonical?: boolean
  }

const getDefaultUrl = (lang) => {
  return lang === 'nl' ? '' : `/${lang}`
}

const buildAlternativeLink = (language, activeLang, altLangBis, baseUrl, altSlug, slug) => {
  const href = slug ? `${baseUrl}${getDefaultUrl(activeLang)}/${slug}` : `/${activeLang}`
  const alternates = []
  alternates.push({ href, hrefLang: language })

  altLangBis.forEach((lang) => {
    // In case of there is a
    const pickAltSlug = lang === 'de' && activeLang === 'en' ? slug : altSlug
    const altHref = slug
      ? `${baseUrl}${getDefaultUrl(lang)}/${pickAltSlug ? pickAltSlug : slug}`
      : getDefaultUrl(lang)
    alternates.push({ href: altHref, hrefLang: lang })
  })
  return alternates
}

const Page: FC<PageProps> = ({
  children,
  languageAlternates,
  url_slug,
  seo_metadata__alternate_url_slug,
  seo_metadata__meta_og_image,
  seo_metadata__description,
  seo_metadata__title,
  seo_metadata__meta_og_description,
  seo_metadata__meta_og_title,
  seo_metadata__meta_twitter_image,
  seo_metadata__meta_twitter_title,
  seo_metadata__meta_twitter_description,
  slug,
  altSlug,
  title,
  hideCanonical = false,
  ...rest
}) => {
  const {
    i18n: { language },
  } = useTranslation()
  const router = useRouter()
  //
  // Stripped out 'de' from the languages array,
  // 'de' is stripped out because it's not officially supported for now and leads to 404s
  // https://linear.app/reversed-digital/issue/GASDV-535
  //
  // TODO: We should have a fresh look at this once we officially support 'de',
  // or tell Gassan that were not able to support alternate links due to Kontent API limitations
  const languages = router.locales.filter((lang) => !['de'].includes(lang))

  const activeLang = ['sg', 'en'].includes(language) ? 'en' : language
  const altLangBis = languages.filter((lang) => lang !== activeLang)

  // We only need an absolute url in production
  const baseUrl = process.env.NEXT_PUBLIC_STAGE === 'production' ? 'https://www.gassan.com' : ''
  let alternates: LanguageAlternate[] = []

  if (languageAlternates) {
    alternates = languageAlternates
      ? languageAlternates.map((item) => ({
          ...item,
          href: `${baseUrl}${item.href}`,
        }))
      : undefined
  } else if (slug) {
    alternates = buildAlternativeLink(language, activeLang, altLangBis, baseUrl, altSlug, slug)
  } else if (url_slug && url_slug.value.length) {
    const slug = url_slug.value
    const altSlug = seo_metadata__alternate_url_slug.value
    alternates = buildAlternativeLink(language, activeLang, altLangBis, baseUrl, altSlug, slug)
  } else {
    if (process.env.NODE_ENV === 'development') {
      console.error(
        'No page slug or languageAlternates passed. Pass at least one of these to generate the correct alternate language links',
      )
    }
  }

  // edited so slug is the first option, as slug is more specific than url_slug
  const url = `${baseUrl}${getDefaultUrl(activeLang)}/${slug || url_slug?.value || ''}`
  const pageTitle = title || seo_metadata__title?.value || ''
  const description = seo_metadata__description?.value || rest.description || ''

  let additionalMetaTags: MetaTag[] = [
    {
      name: 'title',
      content: title,
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      name: 'twitter:site',
      content: '@gassandiamonds',
    },
    {
      name: 'twitter:creator',
      content: '@gassandiamonds',
    },
  ]

  let openGraph: OpenGraph = {
    type: 'website',
    site_name: 'GASSAN',
    url: url,
  }
  if (seo_metadata__meta_og_title?.value) {
    openGraph.title = seo_metadata__meta_og_title.value
  }
  if (seo_metadata__meta_og_description?.value) {
    openGraph.description = seo_metadata__meta_og_description.value
  }
  if (seo_metadata__meta_og_image?.value[0]) {
    openGraph.images = [{ url: seo_metadata__meta_og_image.value[0].url }]
  }

  if (seo_metadata__meta_twitter_title?.value) {
    additionalMetaTags.push({
      name: 'twitter:title',
      content: seo_metadata__meta_twitter_title.value,
    })
  }
  if (seo_metadata__meta_twitter_description?.value) {
    additionalMetaTags.push({
      name: 'twitter:description',
      content: seo_metadata__meta_twitter_description.value,
    })
  }
  if (seo_metadata__meta_twitter_image?.value[0]) {
    additionalMetaTags.push({
      name: 'twitter:image',
      content: seo_metadata__meta_twitter_image.value[0].url,
    })
  }
  return (
    <>
      <Head>
        {pageTitle && <title>{pageTitle}</title>}
        {!hideCanonical && <link rel="canonical" key="canonical" href={url} />}
      </Head>
      <NextSeo
        additionalMetaTags={additionalMetaTags}
        description={description}
        languageAlternates={alternates}
        noindex={process.env.NEXT_PUBLIC_STAGE !== 'production'}
        nofollow={process.env.NEXT_PUBLIC_STAGE !== 'production'}
        openGraph={openGraph}
        {...rest}
      />
      {children}
    </>
  )
}

export default Page
