export function FreeReturns() {
  return (
    <>
      <path
        d="M27.5697 26.8611H10.9883V16.75M27.5697 18.5556V10.25H10.9883V13.1389"
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinejoin="round"
      />
      <path
        d="M27.5698 26.8611H35.5001V20.8125L32.1014 12.4167H27.5698V18.5555"
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinejoin="round"
      />
      <path
        d="M17.94 25.008C19.0229 26.0928 19.0229 27.8516 17.94 28.9364C16.8572 30.0212 15.1015 30.0212 14.0187 28.9364C12.9358 27.8516 12.9358 26.0928 14.0187 25.008C15.1015 23.9232 16.8572 23.9232 17.94 25.008"
        fill="white"
      />
      <path
        d="M17.94 25.008C19.0229 26.0928 19.0229 27.8516 17.94 28.9364C16.8572 30.0212 15.1015 30.0212 14.0187 28.9364C12.9358 27.8516 12.9358 26.0928 14.0187 25.008C15.1015 23.9232 16.8572 23.9232 17.94 25.008"
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinejoin="round"
      />
      <path
        d="M32.359 25.008C33.4418 26.0928 33.4418 27.8516 32.359 28.9364C31.2761 30.0212 29.5205 30.0212 28.4376 28.9364C27.3548 27.8516 27.3548 26.0928 28.4376 25.008C29.5205 23.9232 31.2761 23.9232 32.359 25.008Z"
        fill="white"
        stroke="currentColor"
        className="dynamic-stroke-width"
        strokeLinejoin="round"
      />
      <path
        d="M4.86035 14.9444L19.6394 14.9444C19.6394 14.9444 22.5231 14.9444 22.5231 17.8333C22.5231 20.7222 19.6394 20.7222 19.6394 20.7222L13.872 20.7222"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
      />
      <path
        d="M6.90663 11.6944C6.55677 13.5 4.5 14.9444 4.5 14.9444C4.5 14.9444 6.55677 16.3889 6.90664 18.1944"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
      />
    </>
  )
}
