export function AccountTrash() {
  return (
    <>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinejoin="round"
        d="M11 14.6h18.45"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.7 14.6v-1.8 0a1.8 1.8 0 011.8-1.8h3.6v0a1.8 1.8 0 011.8 1.8v1.8m2.7 0v12.6a1.8 1.8 0 01-1.8 1.8h-9v0a1.8 1.8 0 01-1.8-1.8V14.6h12.6z"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinejoin="round"
        d="M18.425 19.1v5.4M22.025 19.1v5.4"
      ></path>
    </>
  )
}
