// @ts-strict-ignore
import { theme } from '../theme'
import { cn } from '@lib/cn'
import { FC, ImgHTMLAttributes, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

const imageCache = {}

const inImageCache = (src: string) => imageCache[src] || false

const addToCache = (src: string) => {
  imageCache[src] = true
}

const getSources = (sources: string[]) =>
  sources
    .map((src: string, index: number) => (
      <source
        key={index}
        media={index !== 0 ? `(min-width: ${theme.breakpoints[index]})` : undefined}
        srcSet={src}
      />
    ))
    .reverse()

export type ImageProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  'loading' | 'height' | 'width'
> & {
  sources: string[]
  placeholderSrc?: string
  loading?: 'lazy' | 'eager' | 'delayed' | 'none'
  objectFit?: 'cover' | 'contain'
}
export const Image: FC<ImageProps> = ({
  sources,
  placeholderSrc,
  loading = 'lazy',
  alt,
  objectFit = 'cover',
  className,
  style,
  onLoad,
  ...rest
}) => {
  // base initial value on presence in cache.
  // if sources[0] is present in cache, never animate image in
  const [isImageLoaded, setIsImageLoaded] = useState(inImageCache(sources[0]))
  const [isMounted, setIsMounted] = useState(false)

  const pictureRef = useRef<HTMLImageElement>()
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '500px',
  })
  const isCritical = loading === 'eager'
  const isLazy = loading === 'lazy'
  const isDelayed = loading === 'delayed'

  const isBrowser = typeof window !== 'undefined'
  const hasIOSupport = isBrowser && typeof window.IntersectionObserver !== 'undefined'
  const hasNativeLazyLoadSupport =
    typeof HTMLImageElement !== `undefined` && `loading` in HTMLImageElement.prototype

  // Determine if the picture tag should be rendered
  // In these cases picture should render:
  // - When critical
  // - Lazy && No IO support
  // - Lazy && In View
  // - isDelayed && image is loaded
  const shouldRenderPicture =
    isCritical ||
    (isLazy && isBrowser && !hasIOSupport) ||
    (inView && isLazy) ||
    (isMounted && isDelayed)

  const isImageVisible = isCritical || !hasIOSupport || isImageLoaded // if loading is eager or no IO, or if image is loaded, set opacity to 1

  const handleOnLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    onLoad && onLoad(event)
    setIsImageLoaded(true)
    if (!inImageCache(sources[0])) {
      addToCache(sources[0])
    }
  }

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <div
      className={cn('relative flex w-full overflow-hidden', className)}
      ref={ref}
      style={style}
      suppressHydrationWarning
    >
      {!isCritical && (!hasNativeLazyLoadSupport || loading === 'none') ? (
        <img
          aria-hidden="true"
          src={placeholderSrc}
          alt=""
          className={cn(
            'absolute left-0 top-0 h-full w-full blur-lg transition-opacity duration-300',
            shouldRenderPicture ? 'opacity-0' : 'opacity-100',
          )}
          style={{
            objectFit,
          }}
          {...rest}
        />
      ) : null}

      {shouldRenderPicture && (
        <picture
          className={cn(
            'absolute left-0 top-0 z-[2] h-full w-full',
            isImageVisible ? 'opacity-100' : 'opacity-0',
          )}
          style={{
            objectFit,
          }}
        >
          {getSources(sources)}
          <img
            suppressHydrationWarning
            onLoad={handleOnLoad}
            src={sources[0]}
            ref={pictureRef}
            alt={alt}
            // @ts-ignore
            loading={hasNativeLazyLoadSupport && loading !== 'delayed' ? loading : undefined}
            className="flex h-full w-full justify-center"
            style={{
              objectFit,
            }}
          />
        </picture>
      )}
    </div>
  )
}
