export function NoPhoto() {
  return (
    <>
      <g className="hide-on-small" fill="none">
        <path
          fill="#B2B1A5"
          fillRule="evenodd"
          d="M8.86 14.295v16.787a2 2 0 002 2h18.09a2 2 0 002-2V14.296h2v16.788a4 4 0 01-4 4H10.86a4 4 0 01-4-4V14.294h2z"
          clipRule="evenodd"
        />
        <path
          fill="#B2B1A5"
          fillRule="evenodd"
          d="M6 9.854h27.81v3.301a3 3 0 01-3 3h-4.453v-2h4.453a1 1 0 001-1v-1.301H20.882l.01.072c.066.408.205.872.436 1.313.453.863 1.238 1.618 2.572 1.784l-.248 1.985c-2.106-.263-3.4-1.515-4.094-2.838-.093.15-.19.297-.293.44-.866 1.204-2.232 2.251-4.233 1.966l.283-1.98c1.01.144 1.722-.314 2.326-1.154.345-.479.617-1.037.841-1.588H8v1.301a1 1 0 001 1h4.452v2H9a3 3 0 01-3-3V9.854zM17.888 18.457h4.034l2.444.304 3.735 5.085-8.196 8.21-8.197-8.21 3.735-5.085 2.445-.304zm-1.343 2.182l.07-.009-.544 2.117h-1.074l1.548-2.108zm-1.112 4.108l1.372 1.374-.61-1.374h-.761zm2.95 0l1.522 3.428 1.521-3.428h-3.042zm5.231 0l-.61 1.374 1.372-1.374h-.762zm1.198-2H23.74l-.544-2.117.07.01 1.547 2.107zm-3.727-2.29h-2.36l-.59 2.29h3.539l-.59-2.29z"
          clipRule="evenodd"
        />
        <path
          fill="#B2B1A5"
          fillRule="evenodd"
          d="M18.357 9.387c-.166.03-.36.053-.574.062-.813.031-1.618-.156-2.183-.585-.767-.582-1.228-1.271-1.415-1.83-.19-.571-.05-.796-.024-.831.027-.035.206-.23.807-.2.589.03 1.376.288 2.143.87.566.429.962 1.154 1.15 1.945.05.209.08.401.096.57zm-3.966 1.07c2.092 1.588 5.243.957 5.752.286.51-.67.27-3.875-1.822-5.463s-4.668-1.716-5.753-.286-.269 3.875 1.823 5.463z"
          clipRule="evenodd"
        />
        <path
          fill="#B2B1A5"
          fillRule="evenodd"
          d="M21.935 9.387c.166.03.36.053.574.062.813.031 1.618-.156 2.183-.585.767-.582 1.228-1.271 1.415-1.83.19-.571.05-.796.024-.831-.027-.035-.206-.23-.807-.2-.589.03-1.376.288-2.143.87-.566.429-.962 1.154-1.15 1.945a4.12 4.12 0 00-.096.57zm3.966 1.07c-2.091 1.588-5.243.957-5.752.286-.51-.67-.27-3.875 1.822-5.463s4.667-1.716 5.753-.286c1.085 1.43.269 3.875-1.823 5.463z"
          clipRule="evenodd"
        />
      </g>
      <g className="show-on-small">
        <path
          fill="#B2B1A5"
          fillRule="evenodd"
          d="M8.356 14.681v15.89a2.5 2.5 0 002.5 2.5h18.286a2.5 2.5 0 002.5-2.5v-15.89h1v15.89a3.5 3.5 0 01-3.5 3.5H10.856a3.5 3.5 0 01-3.5-3.5v-15.89h1z"
          clipRule="evenodd"
        />
        <path
          fill="#B2B1A5"
          fillRule="evenodd"
          d="M6.643 10.091h26.81v2.802a2.5 2.5 0 01-2.5 2.5H26.5v-1h4.452a1.5 1.5 0 001.5-1.5v-1.802H20.491c-.003.182.01.403.05.652.073.456.228.972.487 1.466.513.978 1.425 1.857 2.953 2.048l-.124.993c-1.914-.24-3.08-1.368-3.715-2.576a5.777 5.777 0 01-.386-.927c-.21.447-.458.897-.754 1.309-.8 1.112-2.004 2.013-3.757 1.762l.141-.99c1.258.18 2.134-.425 2.804-1.356.524-.729.887-1.614 1.159-2.38H7.643v1.801a1.5 1.5 0 001.5 1.5h4.452v1H9.143a2.5 2.5 0 01-2.5-2.5v-2.802zM18.061 18.695h3.973l2.2.273 3.353 4.566-7.54 7.553-7.54-7.553 3.354-4.566 2.2-.273zm-.636 1.086l-1.013.126-2.26 3.078h2.449l.824-3.204zm-.762 4.204l1.834 4.134-4.127-4.134h2.293zm4.934 4.134l4.127-4.134h-2.293l-1.834 4.134zm.74-4.134h-4.58l2.29 5.161 2.29-5.161zm.124-1h-4.828l.847-3.29h3.135l.846 3.29zm1.033 0l-.824-3.204 1.012.126 2.26 3.078h-2.448z"
          clipRule="evenodd"
        />
        <path
          fill="#B2B1A5"
          fillRule="evenodd"
          d="M19.23 9.595a1.91 1.91 0 01-.311.127 4.59 4.59 0 01-1.196.195c-.963.038-2.02-.176-2.826-.787-.907-.688-1.5-1.537-1.759-2.31-.26-.78-.156-1.377.128-1.752.285-.375.832-.635 1.653-.595.815.041 1.792.385 2.698 1.073.806.611 1.296 1.573 1.519 2.51.11.463.146.885.133 1.204-.005.149-.021.26-.038.335zm-4.938.332c2.092 1.587 5.243.957 5.752.286.51-.671.27-3.876-1.822-5.464-2.092-1.587-4.667-1.716-5.752-.286-1.086 1.43-.27 3.876 1.822 5.464z"
          clipRule="evenodd"
        />
        <path
          fill="#B2B1A5"
          fillRule="evenodd"
          d="M20.864 9.595c.067.037.17.082.312.127.305.098.72.177 1.196.195.963.038 2.02-.176 2.826-.787.906-.688 1.5-1.537 1.758-2.31.26-.78.157-1.377-.128-1.752-.284-.375-.831-.635-1.652-.595-.815.041-1.792.385-2.699 1.073-.805.611-1.295 1.573-1.518 2.51-.11.463-.146.885-.134 1.204.006.149.022.26.039.335zm4.939.332c-2.092 1.587-5.243.957-5.753.286-.509-.671-.269-3.876 1.823-5.464 2.091-1.587 4.667-1.716 5.752-.286s.27 3.876-1.822 5.464z"
          clipRule="evenodd"
        />
      </g>
    </>
  )
}
