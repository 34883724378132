import { getId } from '@lib/get-id'

export function IconAccountBarcode() {
  const id = getId()
  return (
    <>
      <g className="hide-on-small">
        <g clipPath={`url(#clip0_8552_82501-${id})`}>
          <g fill="#182F40" clipPath={`url(#clip1_8552_82501-${id})`}>
            <path d="M9.83 11H8.775v18.423H9.83V11zM31.99 11h-1.053v18.423h1.052V11zM12.46 11h-1.052v18.423h1.053V11zM30.357 11h-1.052v18.423h1.052V11zM27.726 11h-1.053v18.423h1.053V11zM17.725 11H14.04v18.423h3.685V11zM25.62 11h-3.158v18.423h3.158V11zM20.883 11h-1.58v18.423h1.58V11z"></path>
          </g>
        </g>
        <defs>
          <clipPath id={`clip0_8552_82501-${id}`}>
            <path fill="#fff" d="M0 0H24V24H0z" transform="translate(8 8)"></path>
          </clipPath>
          <clipPath id={`clip1_8552_82501-${id}`}>
            <path fill="#fff" d="M0 0H24.5V17.865H0z" transform="translate(8.25 11)"></path>
          </clipPath>
        </defs>
      </g>
      <g className="show-on-small">
        <g clipPath={`url(#clip0_8552_82502-${id})`}>
          <g fill="#182F40" clipPath={`url(#clip1_8552_82502-${id})`}>
            <path d="M9.303 11h-.527v18.423h.527V11zM31.99 11h-1.053v18.423h1.052V11zM11.935 11h-.527v18.423h.527V11zM29.831 11h-.526v18.423h.526V11zM27.2 11h-.527v18.423h.526V11zM15.882 11H14.04v18.423h1.842V11zM24.041 11h-1.58v18.423h1.58V11zM20.093 11h-.79v18.423h.79V11z"></path>
          </g>
        </g>
        <defs>
          <clipPath id={`clip0_8552_82502-${id}`}>
            <path fill="#fff" d="M0 0H24V24H0z" transform="translate(8 8)"></path>
          </clipPath>
          <clipPath id={`clip1_8552_82502-${id}`}>
            <path fill="#fff" d="M0 0H24.5V17.865H0z" transform="translate(8.25 11)"></path>
          </clipPath>
        </defs>
      </g>
    </>
  )
}
