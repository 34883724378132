export function ChevronUpSmall() {
  return (
    <path
      d="M24.375 21.875C22.6923 21.5341 20 18.125 20 18.125C20 18.125 17.3077 21.5341 15.625 21.875"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
    />
  )
}
