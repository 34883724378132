// @ts-strict-ignore
import { hideMenuElement } from '@components/Page/Header/styles'
import { useBodyScrollLock } from '@lib/hooks/useBodyScrollLock'
import { NavigationContent } from '@lib/kontent/navigation-helpers/normalize-navigation-content'
import { cx } from '@linaria/core'
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useEffect, useRef, useState } from 'react'
import HeaderMobileNavFooter from './Footer'
import { HeaderMobileNavHouseOfGassan } from './HouseOfGassan'
import Row from './Row'
import HeaderMobileSubNav from './SubNav'
import { subrowContent } from './Subrow.styles'
import Shop from './shop'
import Diamonds from './shop/Diamonds'
import { scrollContainer } from './styles'
import { ROW_HEIGHT_MEDIUM, ROW_HEIGHT_SMALL } from './utils/config'
import { useResponsiveRowHeight } from './utils/useResponsiveRowHeight'

const getAnimation = ({
  index,
  active,
  height,
}: {
  index: number
  active: number | null
  height: number
}) => {
  if (active === null) {
    return {
      animate: { y: 0, opacity: 1 },
      transition: { ease: 'easeOut', duration: 0.1 },
    }
  }
  if (index === active) {
    return {
      animate: { y: 0 - index * height, opacity: 2 },
      transition: { ease: 'easeOut', duration: 0.2, delay: 0.2 },
    }
  } else {
    return {
      animate: { opacity: 0 },
      transition: { ease: 'easeOut', duration: 0.2 },
    }
  }
}
type Props = {
  content: NavigationContent
  isVisible: boolean
}

const MobileNav: FC<Props> = ({ content, isVisible }) => {
  const [active, setActive] = useState(null)
  const [isMounted, setMounted] = useState(false)
  const rowHeight = useResponsiveRowHeight(ROW_HEIGHT_SMALL, ROW_HEIGHT_MEDIUM)
  const ref = useRef()
  const router = useRouter()
  const {
    t,
    i18n: { language },
  } = useTranslation(['other', 'navigation'])

  const toggle = (index) => {
    setActive(active === index ? null : index)
  }

  useEffect(() => {
    if (isVisible) {
      setMounted(true)
    }
    return () => {
      setMounted(false)
    }
  }, [isVisible])

  useBodyScrollLock(ref, isMounted)

  return (
    <div className={cx(scrollContainer, hideMenuElement)} data-is-visible={isVisible} ref={ref}>
      <Row
        {...getAnimation({ index: 0, active: active, height: rowHeight })}
        hasChildren={false}
        onClick={() => {
          router.push(`/${language}/rolex`, `/${language}/rolex`)
        }}
        label="ROLEX"
        isActive={false}
      >
        ROLEX
      </Row>
      <Row
        {...getAnimation({ index: 1, active: active, height: rowHeight })}
        label="Rolex Certified Pre-Owned"
        onClick={() => router.push(`/${language}${t('rcpo.index.url', { ns: 'navigation' })}`)}
        isActive={false}
        hasChildren={false}
        style={{ top: 1 * rowHeight }}
      >
        Rolex Certified Pre-Owned
      </Row>
      <Row
        {...getAnimation({ index: 2, active: active, height: rowHeight })}
        label="Shop"
        onClick={() => toggle(2)}
        isActive={active === 2}
        style={{ top: 2 * rowHeight }}
      >
        <Shop content={content} />
      </Row>
      <Row
        {...getAnimation({ index: 3, active: active, height: rowHeight })}
        label="Pre-owned"
        onClick={() => router.push(`/${language}${t('preOwnedWatches', { ns: 'navigation' })}`)}
        isActive={false}
        hasChildren={false}
        style={{ top: 3 * rowHeight }}
      >
        Pre-owned
      </Row>

      <Row
        {...getAnimation({ index: 4, active: active, height: rowHeight })}
        label={t('mainNav.diamonds', { ns: 'navigation' })}
        onClick={() => toggle(4)}
        isActive={active === 4}
        style={{ top: 4 * rowHeight }}
      >
        <motion.div className={subrowContent}>
          <Diamonds {...content.diamonds} variant="extended" />
        </motion.div>
      </Row>
      <Row
        {...getAnimation({ index: 5, active: active, height: rowHeight })}
        label={t('mainNav.houseOfGassan', { ns: 'navigation' })}
        onClick={() => toggle(5)}
        isActive={active === 5}
        style={{ top: 5 * rowHeight }}
      >
        <motion.div className={subrowContent}>
          <HeaderMobileNavHouseOfGassan columns={content.houseOfGassan} />
        </motion.div>
      </Row>
      <Row
        {...getAnimation({ index: 6, active: active, height: rowHeight })}
        label="Tours"
        onClick={() => router.push(`/${language}${t('tours', { ns: 'navigation' })}`)}
        isActive={false}
        hasChildren={false}
        style={{ top: 6 * rowHeight }}
      >
        Tours
      </Row>
      <HeaderMobileSubNav isVisible={active === null} style={{ top: 7 * rowHeight }} />
      <HeaderMobileNavFooter />
    </div>
  )
}

export default MobileNav
