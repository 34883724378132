export function SliderArrowLeft() {
  return (
    <>
      <g className="hide-on-small">
        <path
          d="M8.83823 13.5C8.35294 16 3.5 20 3.5 20C3.5 20 8.35294 24 8.83823 26.5"
          stroke="currentColor"
          className="dynamic-stroke-width"
          fill="none"
        />
        <path
          d="M3.5 20L36.5 20"
          stroke="currentColor"
          className="dynamic-stroke-width"
          fill="none"
        />
      </g>
      <g className="show-on-small">
        <path
          d="M22 13C21.4545 15.6923 16 20 16 20C16 20 21.4545 24.3077 22 27"
          stroke="currentColor"
          fill="none"
          className="dynamic-stroke-width"
        />
      </g>
    </>
  )
}
