export function ArrowRight() {
  return (
    <>
      <path
        d="M31.1618 26.5C31.6471 24 36.5 20 36.5 20C36.5 20 31.6471 16 31.1618 13.5"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
      />
      <path
        d="M36.5 20L3.5 20"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
      />
    </>
  )
}
