import { parse } from '@config/theme';
export const grid = parse({
  display: 'grid'
}, "g1ks5792");
export const subItemGrid = parse({
  position: 'relative',
  display: 'grid'
}, "s19cucyp");
export const wrapSubItem = "w19kbrep";

require("./styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");