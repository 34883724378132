import { parse } from '@config/theme';
export const linkButton = parse({
  position: 'relative',
  color: 'shade800',
  textAlign: 'left',
  fontFamily: 'heading'
}, "l271w8p");
export const button = parse({
  position: 'relative',
  fontFamily: 'heading',
  color: 'shade800',
  textAlign: 'left'
}, "b136siho");

require("./MenuButton.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./MenuButton.styles.ts");