import { ResultsState } from '.'
import NoInput from './NoInput'
import NoResults from './NoResults'
import Results from './Results'
import { parseAll } from '@config/theme'
import { Spinner } from '@gassan-ui'
import { cx } from '@linaria/core'
import { FC } from 'react'

type Props = {
  query: string
  results: ResultsState
}

const ResultsList: FC<Props> = ({ query, results }) => {
  let render
  const status = results.status
  const totalResults = results.brands.length + results.categories.length + results.products.length
  if (query === '') {
    render = <NoInput />
  } else if (status === 'searching') {
    render = (
      <div className={cx(parseAll({ color: 'shade800' }))}>
        <Spinner size={30} />
      </div>
    )
  } else if (status === 'idle') {
    if (totalResults === 0 && query === '') {
      render = <NoInput />
    } else if (totalResults === 0) {
      render = <NoResults />
    } else {
      render = <Results {...results} query={query} />
    }
  } else {
    render = null
  }
  return <div className={cx(parseAll({ mt: { _: '4', medium: '10' } }))}>{render}</div>
}

export default ResultsList
