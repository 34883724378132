import { Icon } from '../Icon'
import { Spinner } from '../Spinner'
import * as S from './elements'
import { useVideoPlayer } from './hooks/use-video-player'
import { cn } from '@lib/cn'
import { useCombinedRefs } from '@lib/hooks/useCombinedRefs'
import React, { RefObject, VideoHTMLAttributes, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { MaxWidthProps, SpaceProps } from 'styled-system'

export type PlayMode = 'loop-muted' | 'auto-play' | 'regular' | 'loop-muted-toggle-audio'
type Props = VideoHTMLAttributes<HTMLVideoElement> &
  MaxWidthProps &
  SpaceProps & {
    ratio?: number | number[]
    videoSrc: string
    placeholderSrc: string
    images: string[]
    text?: string
    ref?: RefObject<HTMLVideoElement>
    isFixedRatio?: boolean
    playMode?: PlayMode
  }

export const VideoPlayer = React.forwardRef<HTMLVideoElement, Props>(
  (
    {
      maxWidth = '44rem',
      ratio = [0.5625],
      videoSrc,
      placeholderSrc,
      images,
      text,
      isFixedRatio = false,
      playMode = 'regular',
      ...rest
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLVideoElement>(null)
    const [viewRef, inView] = useInView({ triggerOnce: true })
    const combinedRef = useCombinedRefs<HTMLVideoElement>(ref, innerRef)
    const { loopedAudioMuted, toggleAudio, requestVideoPlay, status } = useVideoPlayer(
      combinedRef,
      playMode,
      inView,
    )

    const allowActivatingAudio = playMode === 'loop-muted-toggle-audio'
    const hasLoopedPlayMode = ['loop-muted', 'loop-muted-toggle-audio'].includes(playMode)

    return (
      <S.Container
        ref={viewRef}
        id="video-player"
        ratio={typeof ratio === 'number' ? [ratio] : ratio}
        isFixedRatio={isFixedRatio}
        isPlaying={status === 'playing'}
        maxWidth={maxWidth}
      >
        <S.Image
          placeholderSrc={placeholderSrc}
          className={cn('absolute', status === 'playing' && 'opacity-0')}
          sources={images}
          loading="lazy"
          status={status}
        />
        <S.Video
          status={status}
          src={videoSrc}
          ref={combinedRef}
          onLoadedData={requestVideoPlay}
          preload="none"
          muted
          playsInline
          controls={!hasLoopedPlayMode}
          loop={hasLoopedPlayMode}
          {...rest}
        />

        {playMode === 'regular' && (
          <S.Content status={status}>
            <S.PlayButton
              onClick={requestVideoPlay}
              status={status}
              tabIndex={-1}
              disabled={status === 'loading'}
            >
              {status === 'loading' ? <Spinner size={40} /> : <Icon icon="play" size={50} />}
            </S.PlayButton>
            {text && <S.Text>{text}</S.Text>}
          </S.Content>
        )}
        {allowActivatingAudio && (
          <>
            <S.AudioButtonBackground />
            <S.ToggleAudioButton onClick={toggleAudio} tabIndex={-1}>
              <Icon
                size={48}
                color="white"
                icon={loopedAudioMuted ? 'audio-disabled' : 'audio-enabled'}
              />
            </S.ToggleAudioButton>
          </>
        )}
      </S.Container>
    )
  },
)
