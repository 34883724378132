// @ts-strict-ignore
import * as qs from 'query-string'

type ImageArgs = {
  width?: number
  height?: number
}

export function imgix(imageUrl: string, args?: ImageArgs) {
  if (!imageUrl) {
    return ''
  }
  let params = {
    auto: 'format',
    lossless: true,
  }
  if (args.width) {
    params['w'] = args.width
  }
  if (args.height) {
    params['h'] = args.height
  }

  return `${imageUrl}?${qs.stringify(params)}`
}
