export function AudioDisabled() {
  return (
    <>
      <path
        d="M19 13L14 17H10V23H14L19 27V13Z"
        stroke="currentColor"
        className="dynamic-stroke-width"
        strokeLinecap="round"
      />
      <path
        d="M30 17L24 23"
        stroke="currentColor"
        className="dynamic-stroke-width"
        strokeLinecap="square"
      />
      <path
        d="M24 17L30 23"
        stroke="currentColor"
        className="dynamic-stroke-width"
        strokeLinecap="square"
      />
    </>
  )
}
