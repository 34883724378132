export function AccountReturn() {
  return (
    <>
      <path
        strokeLinejoin="round"
        stroke="currentColor"
        className="dynamic-stroke-width"
        d="M27.167 27.932H11.234v-9.698m15.933 1.732V12H11.234v2.77M27.167 27.932h7.62v-5.801l-3.266-8.053h-4.354v5.888"
      ></path>
      <path
        fill="#FAFAFA"
        d="M17.913 26.155a2.664 2.664 0 11-3.767 3.768 2.664 2.664 0 013.767-3.768z"
      ></path>
      <path
        strokeLinejoin="round"
        stroke="currentColor"
        className="dynamic-stroke-width"
        d="M17.913 26.155a2.664 2.664 0 11-3.767 3.768 2.664 2.664 0 013.767-3.768"
      ></path>
      <path
        fill="#FAFAFA"
        strokeLinejoin="round"
        stroke="currentColor"
        className="dynamic-stroke-width"
        d="M31.768 26.155A2.664 2.664 0 1128 29.923a2.664 2.664 0 013.768-3.768z"
      ></path>
      <path
        stroke="currentColor"
        className="dynamic-stroke-width"
        d="M5.347 16.503h14.2s2.771 0 2.771 2.77c0 2.771-2.77 2.771-2.77 2.771h-5.542"
      ></path>
      <path
        stroke="currentColor"
        className="dynamic-stroke-width"
        d="M7.312 13.385C6.976 15.117 5 16.503 5 16.503s1.976 1.385 2.312 3.117"
      ></path>
    </>
  )
}
