// @ts-strict-ignore

/* eslint-disable */
import Head from 'next/head'
import { FC, useEffect, useState } from 'react'

type Window = globalThis.Window &
  typeof globalThis & {
    pintrk: any
  }
const typedWindow = typeof window !== 'undefined' ? (window as Window) : null

const PinterestTracking: FC = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    // @ts-ignore
    !(function (e) {
      if (!typedWindow.pintrk) {
        typedWindow.pintrk = function () {
          typedWindow.pintrk.queue.push(Array.prototype.slice.call(arguments))
        }
        var n = typedWindow.pintrk
        ;(n.queue = []), (n.version = '3.0')
        var t = document.createElement('script')
        ;(t.async = !0),
          (t.src = e),
          (t.onload = () => {
            setLoaded(true)
          })
        var r = document.getElementsByTagName('script')[0]
        r.parentNode.insertBefore(t, r)
      }
    })('https://s.pinimg.com/ct/core.js')
  }, [])

  useEffect(() => {
    typedWindow.pintrk('load', '2614189041238', { em: '<user_email_address>' })
    typedWindow.pintrk('page')
  }, [loaded])

  return (
    <Head>
      <meta name="p:domain_verify" content="76eb1c09d1ba24a96c8717a7816f8188" />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://ct.pinterest.com/v3/?event=init&tid=2614189041238&pd[em]=<hashed_email_address>&noscript=1"
        />
      </noscript>
    </Head>
  )
}

export default PinterestTracking
