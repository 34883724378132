// @ts-strict-ignore
import {
  CartDocument,
  PreferencesDocument,
  RenewTokenDocument,
  UpdatePreferencesInput,
  WishlistsDocument,
} from '@generated'
import { devtoolsExchange } from '@urql/devtools'
import { cacheExchange } from '@urql/exchange-graphcache'
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch'
import { retryExchange } from '@urql/exchange-retry'
import { Client, Exchange, createClient, dedupExchange, fetchExchange, ssrExchange } from 'urql'

let client: null | Client

export const createUrqlClient = (language: string, reset = false) => {
  // let url = process.env.NEXT_PUBLIC_API_URL
  //   ? `${process.env.NEXT_PUBLIC_API_URL}`
  //   : 'http://localhost:8080/graphql'
  let url = process.env.NEXT_PUBLIC_API_URL
  if (typeof window !== 'undefined') {
    url = '/graphql'
  }

  const exchanges = [
    dedupExchange,
    cacheExchange({
      keys: {
        ArticleAttribute: null,
        ArticlesPayload: null,
        FilterPayload: null,
        ArticleFilterAttributeValue: null,
        ArticleAttributeFilter: null,
        BrandCollectionFilter: null,
        BrandTypeFilter: null,
        ArticleTypeFilters: null,
        ArticleTypeFilter: null,
        CartMeta: null,
        Transactions: null,
        DAFUser: null,
        InvoicesPayload: null,
        InvoiceSalesLine: null,
        DAFUserOptin: null,
        FilesPayload: null,
        OrderByIdAndEmailPayload: (data) => data.order['id'],
        OrderByOrderNrAndEmailPayload: (data) => data.order['id'],
      },
      optimistic: {
        updateUserWishlist: (args, cache) => {
          // The handlers below work on a single entity, e.g. cart:id
          // but this case is a bit different because we want to update a single wishlist
          // but update the cache key for the wishilists query, therefore we update the cache 'manually'
          cache.updateQuery(
            {
              query: WishlistsDocument,
            },
            (data) => {
              if (!data) {
                return null
              }
              return {
                ...data,
                wishlists: [
                  {
                    ...data.wishlists[0],
                    articleIds: args.articleIds,
                  },
                ],
              }
            },
          )
          return null
        },
        updateBrandPreferences: (args, cache) => {
          cache.updateQuery(
            {
              query: PreferencesDocument,
            },
            (data) => {
              if (!data) {
                return null
              }
              const input = args.input as UpdatePreferencesInput[]
              const preferences = input.map((item) => {
                return {
                  ...item,
                  __typename: 'DAFPreferenceTypeItem',
                }
              })
              return {
                ...data,
                preferences,
              }
            },
          )
          return null
        },
        removeItemFromCart: (variables, cache) => {
          const query = cache.readQuery({
            query: CartDocument,
            variables: { reference: variables.reference },
          })
          if (query) {
            // @ts-ignore
            const items = query.cart.items.filter((item) => item.id !== variables.cartItemId)
            // @ts-ignore
            return { ...query.cart, items }
          }
        },
        updateCartItemSize: (variables, cache) => {
          const query = cache.readQuery({
            query: CartDocument,
            variables: { reference: variables.reference },
          })
          if (query) {
            // @ts-ignore
            const items = query.cart.items.map((item) => {
              return item.id === variables.cartItemId
                ? {
                    ...item,
                    // @tes-ignore
                    size: variables.size,
                  }
                : item
            })
            // @ts-ignore
            return { ...query.cart, items }
          }
        },
      },
      updates: {
        Mutation: {
          updateAddress: (result, args, cache, info) => {
            const allFields = cache.inspectFields('Query')
            const addresses = allFields.filter((x) => x.fieldName === 'addresses')
            addresses.forEach(({ fieldName, arguments: variables }) => {
              cache.invalidate('Query', fieldName, variables)
            })
          },
          addAddress: (result, args, cache, info) => {
            const allFields = cache.inspectFields('Query')
            const addresses = allFields.filter((x) => x.fieldName === 'addresses')
            addresses.forEach(({ fieldName, arguments: variables }) => {
              cache.invalidate('Query', fieldName, variables)
            })
          },
          deleteAddress: (result, args, cache, info) => {
            cache.invalidate({ __typename: 'Address', id: args.id as number })
          },
          deleteFile: (result, args, cache, info) => {
            cache.invalidate({ __typename: 'File', id: args.id as number })
          },
          uploadFile: (result, args, cache, info) => {
            const allFields = cache.inspectFields('Query')
            const files = allFields.filter((x) => x.fieldName === 'files')
            files.forEach(({ fieldName, arguments: variables }) => {
              cache.invalidate('Query', fieldName, variables)
            })
          },
          deleteGiftcard: (result, args, cache, info) => {
            cache.invalidate({ __typename: 'Giftcard', id: args.id as number })
          },
          addGiftcard: (result, args, cache, info) => {
            const allFields = cache.inspectFields('Query')
            const addresses = allFields.filter((x) => x.fieldName === 'giftcards')
            addresses.forEach(({ fieldName, arguments: variables }) => {
              cache.invalidate('Query', fieldName, variables)
            })
          },
          updatePreferences: (result, args, cache, info) => {
            const allFields = cache.inspectFields('Query')
            const preferences = allFields.filter((x) => x.fieldName === 'preferences')
            preferences.forEach(({ fieldName, arguments: variables }) => {
              cache.invalidate('Query', fieldName, variables)
            })
          },
          login: (result, args, cache, info) => {
            const allFields = cache.inspectFields('Query')
            const me = allFields.filter((x) => x.fieldName === 'me')
            me.forEach(({ fieldName, arguments: variables }) => {
              cache.invalidate('Query', fieldName, variables)
            })
          },
          uploadUserFile: (_, __, cache) => {
            const all = cache.inspectFields('Query')
            const files = all.filter((x) => x.fieldName === 'userFiles')
            files.forEach(({ fieldName, arguments: variables }) => {
              cache.invalidate('Query', fieldName, variables)
            })
          },
          deleteUserFile: (_, __, cache) => {
            const all = cache.inspectFields('Query')
            const files = all.filter((x) => x.fieldName === 'userFiles')
            files.forEach(({ fieldName, arguments: variables }) => {
              cache.invalidate('Query', fieldName, variables)
            })
          },
          orderAddVoucher: (_, __, cache) => {
            const all = cache.inspectFields('Query')
            const orders = all.filter((x) => x.fieldName === 'orderByIdAndEmail')
            orders.forEach(({ fieldName, arguments: variables }) => {
              cache.invalidate('Query', fieldName, variables)
            })
          },
          orderAddGiftcard: (_, __, cache) => {
            const all = cache.inspectFields('Query')
            const orders = all.filter((x) => x.fieldName === 'orderByIdAndEmail')
            orders.forEach(({ fieldName, arguments: variables }) => {
              cache.invalidate('Query', fieldName, variables)
            })
          },
          orderRemoveGiftcard: (_, __, cache) => {
            const all = cache.inspectFields('Query')
            const orders = all.filter((x) => x.fieldName === 'orderByIdAndEmail')
            orders.forEach(({ fieldName, arguments: variables }) => {
              cache.invalidate('Query', fieldName, variables)
            })
          },
          orderRemoveVoucher: (_, __, cache) => {
            const all = cache.inspectFields('Query')
            const orders = all.filter((x) => x.fieldName === 'orderByIdAndEmail')
            orders.forEach(({ fieldName, arguments: variables }) => {
              cache.invalidate('Query', fieldName, variables)
            })
          },
        },
      },
    }),
    ssrExchange({
      isClient: typeof window !== 'undefined',
      initialState: undefined,
    }),
    retryExchange({
      maxNumberAttempts: 2,
      // @ts-ignore
      retryIf: async (error) => {
        // If current error === user:error:auth-not-authorised the current token could be expired
        // We try to renew it once using the refresh token
        // If that fails, remove both tokens
        if (
          error &&
          error.graphQLErrors.length > 0 &&
          error.graphQLErrors[0].message.includes('user-error:auth-not-authorised')
        ) {
          const authToken = localStorage.getItem('auth_token')
          const refreshToken = localStorage.getItem('refresh_token')
          if (authToken && refreshToken) {
            try {
              if (process.env.NODE_ENV === 'development') {
                console.info(
                  `Attempting to refresh token on error ${error.graphQLErrors[0].message}`,
                )
              }
              const response = await client
                .mutation(RenewTokenDocument, { authToken, refreshToken })
                .toPromise()
              window.localStorage.setItem('auth_token', response.data.renewToken.authToken)
              window.localStorage.setItem('refresh_token', response.data.renewToken.refreshToken)
              return true
            } catch (error) {
              window.localStorage.removeItem('auth_token')
              window.localStorage.removeItem('refresh_token')
              return false
            }
          } else {
            return false
          }
        }
        if (error.networkError) {
          return true
        }
      },
    }),
    multipartFetchExchange,
    fetchExchange,
    process.env.NODE_ENV === 'development' && devtoolsExchange,
  ].filter((t) => t)

  // On each server request create a new client
  if (!client || reset) {
    client = createClient({
      url,
      exchanges: exchanges as Exchange[],
      fetch,
      fetchOptions: () => {
        const token = typeof window !== 'undefined' ? localStorage.getItem('auth_token') : null
        let headers = {
          Language: language,
        }
        if (token) {
          headers['authorization'] = `Bearer ${token}`
        }
        return {
          headers,
          credentials: 'include',
        }
      },
    })
  }

  return client
}
