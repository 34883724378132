export function OpenNav() {
  return (
    <>
      <path
        d="M10 15H30M10 24.75H30"
        stroke="currentColor"
        strokeWidth="1"
        className="show-on-small"
      />
      <path d="M8 15H32M8 25H32" stroke="currentColor" strokeWidth="2" className="hide-on-small" />
    </>
  )
}
