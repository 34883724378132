export function AudioEnabled() {
  return (
    <>
      <path
        d="M19 13L14 17H10V23H14L19 27V13Z"
        stroke="currentColor"
        className="dynamic-stroke-width"
        strokeLinecap="round"
      />
      <path
        d="M27.07 12.93C28.9447 14.8053 29.9979 17.3484 29.9979 20C29.9979 22.6516 28.9447 25.1947 27.07 27.07M23.54 16.46C24.4774 17.3976 25.004 18.6692 25.004 19.995C25.004 21.3208 24.4774 22.5924 23.54 23.53"
        stroke="currentColor"
        className="dynamic-stroke-width"
        strokeLinecap="square"
      />
    </>
  )
}
