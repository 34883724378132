export function AccountGiftcard() {
  return (
    <>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        d="M18.5 14.5H9v15h22v-15h-9.5"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        stroke="transparent"
        d="M32 26.5H8v-4h24v4z"
        className="dynamic-stroke-width"
        clipRule="evenodd"
      ></path>
      <path
        fill="none"
        stroke="currentColor"
        d="M23.964 11.438c.554.956.225 2.18-.733 2.732a2.007 2.007 0 01-2.74-.732 1.997 1.997 0 01.735-2.732 2.007 2.007 0 012.738.732zM15.982 11.438a1.997 1.997 0 00.734 2.732 2.007 2.007 0 002.739-.732 1.997 1.997 0 00-.734-2.732 2.007 2.007 0 00-2.739.732z"
      ></path>
      <path
        fill="none"
        stroke="currentColor"
        className="dynamic-stroke-width"
        d="M20.492 13.438a2.007 2.007 0 002.738.732 1.997 1.997 0 00.734-2.732 2.007 2.007 0 00-2.738-.732 1.997 1.997 0 00-.734 2.732zm0 0L25 19.5m-5.545-6.062a2.007 2.007 0 01-2.739.732 1.997 1.997 0 01-.734-2.732 2.007 2.007 0 012.739-.732 1.997 1.997 0 01.734 2.732zm0 0L14.946 19.5"
      ></path>
    </>
  )
}
