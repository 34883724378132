export function Settings() {
  return (
    <>
      <path d="M11.5 16L28.5 16" stroke="currentColor" className="dynamic-stroke-width" />
      <path d="M11.5 24L28.5 24" stroke="currentColor" className="dynamic-stroke-width" />
      <path d="M24.5 13L27.5 16L24.5 19L21.5 16L24.5 13Z" fill="currentColor" />
      <path d="M15.5 21L18.5 24L15.5 27L12.5 24L15.5 21Z" fill="currentColor" />
    </>
  )
}
