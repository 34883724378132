export function IconContact() {
  return (
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5px"
      d="M11 20a8.96 8.96 0 0 0 1.048 4.219l-.808 3.034a.6.6 0 0 0 .681.746l3.067-.523A9 9 0 1 0 11 20ZM16 22h6M16 18h8"
    />
  )
}
