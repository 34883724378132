import { breakpoints } from '../theme'
import { AccountAddress } from './account-address'
import { IconAccountBarcode } from './account-barcode'
import { AccountCheck } from './account-check'
import { AccountContact } from './account-contact'
import { IconAccountDiamondDocument } from './account-diamond-document'
import { AccountDocument } from './account-document'
import { IconAccountEvents } from './account-events'
import { AccountGiftcard } from './account-giftcard'
import { AccountHeart } from './account-heart'
import { AccountLock } from './account-lock'
import { AccountLogout } from './account-logout'
import { AccountMyGassan } from './account-my-gassan'
import { AccountOrder } from './account-order'
import { AccountOrderLarge } from './account-order-large'
import { IconAccountPartners } from './account-partners'
import { IconAccountPrivileges } from './account-privileges'
import { AccountProfile } from './account-profile'
import { AccountReturn } from './account-return'
import { AccountSettings } from './account-settings'
import { IconAccountStar } from './account-star'
import { AccountTrash } from './account-trash'
import { IconAccountTrends } from './account-trends'
import { AlwaysInsured } from './always-insured'
import { ArrowLeft } from './arrow-left'
import { ArrowRight } from './arrow-right'
import { AudioDisabled } from './audio-disabled'
import { AudioEnabled } from './audio-enabled'
import { IconBarCode } from './bar-code'
import { Bullet } from './bullet'
import { Calendar } from './calendar'
import { Check } from './check'
import { CheckCircle } from './check-circle'
import { ChevronDown } from './chevron-down'
import { ChevronDownSmall } from './chevron-down-small'
import { ChevronLeft } from './chevron-left'
import { ChevronLeftSmall } from './chevron-left-small'
import { ChevronRight } from './chevron-right'
import { ChevronRightSmall } from './chevron-right-small'
import { ChevronUp } from './chevron-up'
import { ChevronUpSmall } from './chevron-up-small'
import { Clock } from './clock'
import { IconContact } from './contact'
import { Cross } from './cross'
import { IconDiamond } from './diamond'
import { Exclamation } from './exclamation'
import { IconFacebook } from './facebook'
import { FreeDelivery } from './free-delivery'
import { FreeReturns } from './free-returns'
import { Globe } from './globe'
import { IconGoldColor } from './gold-color'
import { Heart } from './heart'
import { HeartFilled } from './heart-filled'
import { IconInfo } from './info'
import { IconInstagram } from './instagram'
import { IconLinkedIn } from './linkedin'
import { Lock } from './lock'
import { IconMapMarker } from './map-marker'
import { MoreButton } from './more-button'
import { NoImage } from './no-image'
import { NoPhoto } from './no-photo'
import { OpenNav } from './open-nav'
import { PeopleDouble } from './people-double'
import { PersonalAdvice } from './personal_advice'
import { Play } from './play'
import { Plus } from './plus'
import { Profile } from './profile'
import { Search } from './search'
import { Settings } from './settings'
import { SettingsAlt } from './settings-alt'
import { IconShapeBohoChic } from './shape-boho-chic'
import { IconShapeClassic } from './shape-classic'
import { IconShapeOval } from './shape-oval'
import { ShoppingBag } from './shopping-bag'
import { SliderArrowLeft } from './slider-arrow-left'
import { SliderArrowRight } from './slider-arrow-right'
import { IconStore } from './store'
import { TrashCan } from './trash-can'
import { Wheelchair } from './wheelchair'
import { Wifi } from './wifi'
import { FC, SVGAttributes } from 'react'
import styled from 'styled-components'
import { SizeProps, SpaceProps, size, space } from 'styled-system'

export const icons = {
  'account-address': AccountAddress,
  'account-barcode': IconAccountBarcode,
  'account-check': AccountCheck,
  'account-contact': AccountContact,
  'account-diamond-document': IconAccountDiamondDocument,
  'account-document': AccountDocument,
  'account-events': IconAccountEvents,
  'account-giftcard': AccountGiftcard,
  'account-heart': AccountHeart,
  'account-lock': AccountLock,
  'account-logout': AccountLogout,
  'account-my-gassan': AccountMyGassan,
  'account-order-large': AccountOrderLarge,
  'account-order': AccountOrder,
  'account-partners': IconAccountPartners,
  'account-privileges': IconAccountPrivileges,
  'account-profile': AccountProfile,
  'account-return': AccountReturn,
  'account-settings': AccountSettings,
  'account-star': IconAccountStar,
  'account-trash': AccountTrash,
  'account-trends': IconAccountTrends,
  'always-insured': AlwaysInsured,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'bar-code': IconBarCode,
  'check-circle': CheckCircle,
  'chevron-down-small': ChevronDownSmall,
  'chevron-down': ChevronDown,
  'chevron-left-small': ChevronLeftSmall,
  'chevron-left': ChevronLeft,
  'chevron-right-small': ChevronRightSmall,
  'chevron-right': ChevronRight,
  'chevron-up-small': ChevronUpSmall,
  'chevron-up': ChevronUp,
  'free-delivery': FreeDelivery,
  'free-returns': FreeReturns,
  'gold-color': IconGoldColor,
  'heart-filled': HeartFilled,
  'map-marker': IconMapMarker,
  'more-button': MoreButton,
  'no-image': NoImage,
  'no-photo': NoPhoto,
  'open-nav': OpenNav,
  'people-double': PeopleDouble,
  'personal-advice': PersonalAdvice,
  'settings-alt': SettingsAlt,
  'shape-boho-chic': IconShapeBohoChic,
  'shape-classic': IconShapeClassic,
  'shape-oval': IconShapeOval,
  'shopping-bag': ShoppingBag,
  'slider-arrow-left': SliderArrowLeft,
  'slider-arrow-right': SliderArrowRight,
  'trash-can': TrashCan,
  bullet: Bullet,
  calendar: Calendar,
  check: Check,
  clock: Clock,
  contact: IconContact,
  cross: Cross,
  diamond: IconDiamond,
  exclamation: Exclamation,
  facebook: IconFacebook,
  globe: Globe,
  heart: Heart,
  instagram: IconInstagram,
  linkedin: IconLinkedIn,
  lock: Lock,
  play: Play,
  plus: Plus,
  profile: Profile,
  search: Search,
  settings: Settings,
  store: IconStore,
  wheelchair: Wheelchair,
  wifi: Wifi,
  'audio-enabled': AudioEnabled,
  'audio-disabled': AudioDisabled,
  info: IconInfo,
}

export type Icons = typeof icons

type SvgProps = SizeProps & SpaceProps

const Svg = styled.svg<SvgProps>`
  ${size};
  ${space};

  &[data-dynamic-stroke='true'] .dynamic-stroke-width {
    stroke-width: 1px;
    @media screen and (min-width: ${breakpoints.small}) {
      stroke-width: 2px;
    }
  }

  .show-on-small {
    display: initial;
    @media screen and (min-width: ${breakpoints.small}) {
      display: none;
    }
  }
  .hide-on-small {
    display: none;
    @media screen and (min-width: ${breakpoints.small}) {
      display: initial;
    }
  }

  circle,
  ellipse,
  line,
  path,
  polygon,
  polyline,
  rect {
    vector-effect: non-scaling-stroke;
  }
`

export type IconType = keyof Icons

export type UIIconProps = Omit<SVGAttributes<SVGElement>, 'display'> &
  SvgProps & {
    dynamicStroke?: boolean
    icon: IconType
  }

export const Icon: FC<UIIconProps> = ({
  icon,
  size = [32, 32, 40],
  dynamicStroke = true,
  ...rest
}) => {
  const Icon = icons[icon]
  return icons[icon] ? (
    <Svg viewBox="0 0 40 40" fill="none" size={size} data-dynamic-stroke={dynamicStroke} {...rest}>
      <Icon />
    </Svg>
  ) : null
}
