// @ts-strict-ignore
import { breakpoints } from '@gassan-ui'
import { useEffect, useState } from 'react'

export const useResponsiveRowHeight = (small: number, medium: number): number => {
  const [rowHeight, setRowHeight] = useState(small)
  useEffect(() => {
    const mediaQuery = window.matchMedia(`(min-width: ${breakpoints.medium})`)

    function handleMediaQueryChange(mql) {
      setRowHeight(mql.matches ? medium : small)
    }

    handleMediaQueryChange(mediaQuery)
    mediaQuery.addListener(handleMediaQueryChange)
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange)
    }
  }, [small, medium])

  return rowHeight
}
