import { ActionProps } from '.'
import { Link } from '../Link'
import { Text } from '../Typography'
import { NotificationIcon } from './Icon'
import { NotificationImage } from './Image'
import { NotificationLabel } from './Label'
import { cn } from '@lib/cn'
import { rem } from 'polished'
import { FC } from 'react'

export const Action: FC<ActionProps> = ({
  variant,
  image,
  description,
  label,
  sublabel,
  onClickAction,
  actionLabel,
}) => {
  return (
    <>
      <div className={cn('flex items-center gap-3', 'sm:gap-4 ')}>
        {variant.includes('image') && <NotificationImage src={image} alt="" />}
        {variant.includes('icon') && <NotificationIcon icon="check" />}
        <div className="block sm:hidden">
          <Text variant="regular" as="span" mb={0}>
            {description}
          </Text>
        </div>
        <div className="hidden overflow-hidden sm:block">
          <Text variant="micro" as="div" mb={[1, rem(3)]}>
            {description}
          </Text>
          <NotificationLabel className="w-full sm:w-[90%]">
            <b>{label}</b>
            {sublabel && ' • '}
            {sublabel}
          </NotificationLabel>
        </div>
        <div className="flex justify-end">
          <Link
            as="button"
            variant={variant.includes('undo') ? 'grey' : 'blue'}
            onClick={onClickAction}
          >
            {actionLabel}
          </Link>
        </div>
      </div>
    </>
  )
}
