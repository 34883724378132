export function TrashCan() {
  return (
    <>
      <g className="hide-on-small">
        <path
          d="M11 14.5991H13.05H29.45"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinejoin="round"
        />
        <path
          d="M16.7 14.6V12.8V12.8C16.7 11.8059 17.5059 11 18.5 11H22.1V11C23.0941 11 23.9 11.8059 23.9 12.8V14.6M26.6 14.6V27.2C26.6 28.1941 25.7941 29 24.8 29H15.8V29C14.8059 29 14 28.1941 14 27.2V14.6H26.6Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M18.4248 19.0991V24.4991"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M22.0254 19.0991V24.4991"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
      <g className="show-on-small">
        <path
          d="M11.25 14H13.25H29.25"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M16.25 14V12V12C16.25 10.8954 17.1454 10 18.25 10H22.25V10C23.3546 10 24.25 10.8954 24.25 12V14M27.25 14V28C27.25 29.1046 26.3546 30 25.25 30H15.25V30C14.1454 30 13.25 29.1046 13.25 28V14H27.25Z"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M18.25 19V25"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M22.25 19V25"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </>
  )
}
