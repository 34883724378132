export function AccountCheck() {
  return (
    <>
      <rect
        width="18"
        height="18"
        x="11"
        y="11"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        rx="9"
      ></rect>
      <path
        strokeLinejoin="round"
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        d="M15 20.364L18.333 24S19.5 22 21 20c1.5-2 4-4 4-4"
      ></path>
    </>
  )
}
