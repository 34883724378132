export function ArrowLeft() {
  return (
    <>
      <path
        d="M8.83823 13.5C8.35294 16 3.5 20 3.5 20C3.5 20 8.35294 24 8.83823 26.5"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
      />
      <path
        d="M3.5 20L36.5 20"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
      />
    </>
  )
}
