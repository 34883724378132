import { FC } from 'react'

export const Fonts: FC = () => {
  return (
    // @ts-ignore
    <style jsx global>
      {`
        @font-face {
          font-family: 'Louize Display';
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          src:
            url('/fonts/louize-display-regular.woff2') format('woff2'),
            url('/fonts/louize-display-regular.woff') format('woff');
        }
        @font-face {
          font-family: 'Quentin';
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          src:
            url('/fonts/quentin.woff2') format('woff2'),
            url('/fonts/quentin.woff') format('woff');
        }
        @font-face {
          font-family: 'Graphik';
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          src:
            url('/fonts/graphik-regular-web.woff2') format('woff2'),
            url('/fonts/graphik-regular-web.woff') format('woff');
        }
        @font-face {
          font-family: 'Graphik';
          font-weight: 600;
          font-style: normal;
          font-display: swap;
          src:
            url('/fonts/graphik-semibold-web.woff2') format('woff2'),
            url('/fonts/graphik-semibold-web.woff') format('woff');
        }
        @font-face {
          font-family: 'Artegra Sans Extended';
          font-weight: 600;
          font-style: normal;
          font-display: swap;
          src:
            url('/fonts/artegra_sans-extended-semibold-web.woff2') format('woff2'),
            url('/fonts/artegra_sans-extended-semibold-web.woff') format('woff');
        }
      `}
    </style>
  )
}
