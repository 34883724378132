export function IconInfo() {
  return (
    <path
      d="M20 24.8859V19.1508M20 15.5287V15.414M29.375 20C29.375 25.1777 25.1777 29.375 20 29.375C14.8223 29.375 10.625 25.1777 10.625 20C10.625 14.8223 14.8223 10.625 20 10.625C25.1777 10.625 29.375 14.8223 29.375 20Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="1.25"
    />
  )
}
