export function IconShapeBohoChic() {
  return (
    <>
      <path
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
        d="M28 27.549c0 9.615-15.77 9.85-15.997.238A9.959 9.959 0 0112 27.55c0-5.898 5.082-16.72 7.145-20.869a.947.947 0 011.71 0C22.918 10.83 28 21.651 28 27.55v0z"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
        d="M24 26.58c0 4.87-7.892 4.987-7.999.116A5.08 5.08 0 0116 26.58C16 22.78 20.107 13 20.107 13S24 22.78 24 26.58v0z"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        d="M20 6L20 16"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        d="M22.782 29.377L25.897 33.289"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        d="M23.47 23.652L27.47 21.152"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        d="M0 -1L5 -1"
        transform="scale(-1 1) rotate(51.474 -40.281 -4.012)"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        d="M0 -1L5 -1"
        transform="scale(-1 1) rotate(-30 37.553 42.722)"
      ></path>
    </>
  )
}
