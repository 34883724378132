export function ShoppingBag() {
  return (
    <>
      <path
        d="M12.3874 17.3756C12.4513 16.7366 12.989 16.25 13.6312 16.25H26.3688C27.011 16.25 27.5487 16.7366 27.6126 17.3756L28.75 28.75H11.25L12.3874 17.3756Z"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
      />
      <path
        d="M23.75 18.75V13.6184C23.75 11.62 22.0711 10 20 10C17.9289 10 16.25 11.62 16.25 13.6184V18.75"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
      />
    </>
  )
}
