import { Icon } from '@components/gassan-ui/Icon'
import { cn } from '@lib/cn'
import { ButtonHTMLAttributes, FC } from 'react'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  direction: 'prev' | 'next'
}

export const SliderNavButton: FC<Props> = ({ direction, className, children, ...rest }) => {
  return (
    <button
      {...rest}
      className={cn(
        'relative m-0 flex h-8 w-8 items-center justify-center p-0 text-current opacity-100 outline-none transition-all duration-300',
        'sm:h-12 sm:w-12',
        'md:h-16 md:w-16',
        'hoverAndPointerScreens:hover:opacity-100',
        'active:scale-95 active:opacity-100',
        'disabled:pointer-events-none disabled:opacity-25',
        className,
      )}
    >
      <Icon icon={direction === 'prev' ? 'slider-arrow-left' : 'slider-arrow-right'} />
    </button>
  )
}
