// @ts-strict-ignore
import { NavActiveOptions } from '../HeaderContent'
import { PageNavigationHamburger } from './Hamburger'
import { HeaderNavigationMainNav } from './MainNav'
import { HeaderNavigationSecondaryNav } from './SecondaryNav'
import * as styles from './styles'
import Logo from '@components/Logo'
import Link from 'next/link'
import { FC } from 'react'

type HeaderNavigationProps = {
  onClickItem?: (key: NavActiveOptions) => void
  active: NavActiveOptions
}

export const HeaderNavigation: FC<HeaderNavigationProps> = ({ onClickItem, active }) => {
  return (
    <nav className={styles.parent} data-is-active={active ? '' : undefined}>
      <div className={styles.logo}>
        <Link href="/" passHref>
          <a>
            <Logo width="auto" height={['1rem', '1.5rem', '1.5rem', '1.5rem', '2rem']} />
          </a>
        </Link>
      </div>
      <div className={styles.hamburgerContainer}>
        <PageNavigationHamburger
          handleClick={() => onClickItem('mobile-nav')}
          isActive={!!active}
        />
      </div>

      <HeaderNavigationMainNav onClickItem={onClickItem} />

      <HeaderNavigationSecondaryNav onClickItem={onClickItem} />
    </nav>
  )
}
