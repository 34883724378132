// @ts-strict-ignore
import urlSlugItemsDe from '../public/locales/de/url-slug-items.json'
import urlSlugItemsEn from '../public/locales/en/url-slug-items.json'
import urlSlugItemsNl from '../public/locales/nl/url-slug-items.json'

export const getUrlSlugInLocale = (
  urlSlug: keyof typeof urlSlugItemsNl,
  locale: string, //'de' | 'en' | 'nl'
): string => {
  switch (locale) {
    case 'de':
      return urlSlugItemsDe[urlSlug]
    case 'en':
      return urlSlugItemsEn[urlSlug]
    case 'nl':
      return urlSlugItemsNl[urlSlug]
  }
}
