export function AccountHeart() {
  return (
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="dynamic-stroke-width"
      d="M24.696 11.25A5.297 5.297 0 0 0 20 14.111a5.297 5.297 0 0 0-4.696-2.861c-2.93 0-5.304 2.389-5.304 5.329s1.817 5.635 4.165 7.848C16.513 26.641 20 28.75 20 28.75s3.374-2.074 5.835-4.323C28.46 22.03 30 19.527 30 16.58c0-2.949-2.374-5.329-5.304-5.329Z"
    />
  )
}
