import { Icon } from '../Icon'
import { bodyRegularStyle } from '../Typography/styles'
import { rem } from 'polished'
import { FC, InputHTMLAttributes } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  height: ${rem(60)};
  background-color: #fff;
  border-bottom: 1px solid ${(p) => p.theme.colors.shades[300]};
  grid-template-columns: ${rem(40)} auto;
  align-items: center;
  padding: 0 1rem;
  color: ${(p) => p.theme.colors.shades[400]};
`

const Input = styled.input`
  height: ${rem(40)};
  outline: 0;
  border: 0;
  ${bodyRegularStyle};
  color: ${(p) => p.theme.colors.shades[800]};
  &::placeholder {
    color: ${(p) => p.theme.colors.shades[400]};
  }
`
type Props = InputHTMLAttributes<HTMLInputElement> & {}

export const Search: FC<Props> = (props) => {
  return (
    <Container>
      <Icon icon="search"></Icon>
      <Input {...props} />
    </Container>
  )
}
