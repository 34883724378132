// @ts-strict-ignore
import { useEffect, useRef, useState } from 'react'

export function useFinishedButtonState(loading: boolean, timeout?: number): boolean {
  const [finished, setFinished] = useState(false)
  const prev = useRef(loading)
  const timer = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (loading === false && prev.current === true) {
      setFinished(true)
      timer.current = setTimeout(() => {
        setFinished(false)
      }, timeout || 2000)
    }
    prev.current = loading
    return () => {
      clearTimeout(timer.current)
    }
  }, [loading, timeout])

  return finished
}
