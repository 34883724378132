export function Check() {
  return (
    <path
      d="M15 20.3636L18.3333 24C18.3333 24 19.5 22 21 20C22.5 18 25 16 25 16"
      stroke="currentColor"
      className="dynamic-stroke-width"
      strokeLinejoin="round"
      fill="none"
    />
  )
}
