// @ts-strict-ignore
import { NProgress } from '@tanem/react-nprogress'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

let prevRoute
let routeTimeout

const TopLoadingBar: FC = () => {
  const router = useRouter()
  const [isRouteChanging, setIsRouteChanging] = useState(false)

  const routeChangeStartHandler = (newRoute) => {
    if (prevRoute !== newRoute.split('?')[0]) {
      prevRoute = newRoute.split('?')[0]

      routeTimeout = setTimeout(() => {
        setIsRouteChanging(true)
      }, 300)
    }
  }

  const routeChangeEndHandler = () => {
    if (routeTimeout) {
      clearTimeout(routeTimeout)
    }
    setIsRouteChanging(false)
  }

  useEffect(() => {
    router.events.on('routeChangeStart', routeChangeStartHandler)
    router.events.on('routeChangeComplete', routeChangeEndHandler)
    router.events.on('routeChangeError', routeChangeEndHandler)
  }, []) //eslint-disable-line

  return (
    <NProgress isAnimating={isRouteChanging}>
      {({ isFinished, progress, animationDuration }) => (
        <div
          style={{
            opacity: isFinished ? 0 : 1,
            pointerEvents: 'none',
            transition: `opacity ${animationDuration}ms linear`,
          }}
        >
          <div
            style={{
              background: '#2D4353',
              height: 2,
              left: 0,
              marginLeft: `${(-1 + progress) * 100}%`,
              position: 'fixed',
              top: 0,
              transition: `margin-left ${animationDuration}ms linear`,
              width: '100%',
              zIndex: 1031,
            }}
          >
            <div
              style={{
                boxShadow: '0 0 10px #2D4353, 0 0 5px #2D4353',
                display: 'block',
                height: '100%',
                opacity: 1,
                position: 'absolute',
                right: 0,
                transform: 'rotate(3deg) translate(0px, -4px)',
                width: 100,
              }}
            />
          </div>
        </div>
      )}
    </NProgress>
  )
}

export default TopLoadingBar
