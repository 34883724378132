// @ts-strict-ignore
import * as styles from './Dropdown.styles'
import { hideMenuElement } from './styles'
import { cx } from '@linaria/core'
import React, { FC, useEffect, useRef } from 'react'

type Props = {
  onRequestClose?: () => void
  variant: 'fullscreen-on-mobile' | 'default'
  menuIsActive: boolean
}

export const HeaderDropdown: FC<Props> = ({
  children,
  onRequestClose,
  variant,
  menuIsActive,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>()
  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onRequestClose()
      }
    }
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, []) // eslint-disable-line

  const handleBackdropClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (ref.current === e.target) {
      onRequestClose()
    }
  }

  return (
    <div
      ref={ref}
      className={cx(styles.backdrop, hideMenuElement)}
      data-is-visible={menuIsActive}
      onClick={handleBackdropClick}
      data-fullscreen-on-mobile={variant === 'fullscreen-on-mobile' ? '' : undefined}
    >
      <div className={styles.dropdown} {...rest}>
        {children}
      </div>
    </div>
  )
}
