export function Clock() {
  return (
    <>
      <g className="hide-on-small">
        <path
          d="M27.071 12.9289C30.9763 16.8342 30.9763 23.1658 27.071 27.071C23.1658 30.9763 16.8341 30.9763 12.9289 27.071C9.02369 23.1658 9.02369 16.8341 12.9289 12.9289C16.8342 9.02369 23.1658 9.02369 27.071 12.9289"
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.9999 20H18.9999C18.9999 20.3788 19.2139 20.725 19.5527 20.8944L19.9999 20ZM18.9999 14V20H20.9999V14H18.9999ZM19.5527 20.8944L23.5527 22.8944L24.4472 21.1056L20.4472 19.1056L19.5527 20.8944Z"
          fill="currentColor"
        />
      </g>
      <g className="show-on-small">
        <path
          d="M27.0711 12.9289C30.9764 16.8342 30.9764 23.1659 27.0711 27.0711C23.1658 30.9764 16.8342 30.9764 12.9289 27.0711C9.02369 23.1658 9.02369 16.8342 12.9289 12.9289C16.8342 9.02369 23.1659 9.02369 27.0711 12.9289"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 20H19.5C19.5 20.1894 19.607 20.3625 19.7764 20.4472L20 20ZM19.5 14V20H20.5V14H19.5ZM19.7764 20.4472L23.7764 22.4472L24.2236 21.5528L20.2236 19.5528L19.7764 20.4472Z"
          fill="currentColor"
        />
      </g>
    </>
  )
}
