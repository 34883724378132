import { Modal } from '@components/Modal'
import { Button, Text } from '@components/gassan-ui'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type FloatingServiceMenuModalProps = {
  handleRequestClose: () => void
}

export const FloatingServiceMenuModal: FC<FloatingServiceMenuModalProps> = ({
  handleRequestClose,
}) => {
  const { t } = useTranslation('other')

  return (
    <Modal
      defaultOpen
      onOpenChange={(open) => {
        if (!open) {
          handleRequestClose()
        }
      }}
      title={t('contactModalTitle')}
    >
      <Text variant="regular" mb="4">
        {t('contactModalDescription')}
      </Text>
      <Text variant="regular" mb="4">
        <span
          dangerouslySetInnerHTML={{
            __html: t('contactModalCall'),
          }}
        />
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: t('contactModalEmail'),
          }}
        />
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: t('contactModalHours'),
          }}
        />
      </Text>
      <div className="mt-auto">
        <div className="md:none block">
          <Button variant="dark" as="link" className="mb-3 min-w-full" href="tel:+31206225333">
            {t('callCta')}
          </Button>
        </div>
        <Button
          variant="light"
          as="link"
          className="min-w-full"
          href={t('contactLink')}
          target="_blank"
        >
          {t('contactLinkTitle')}
        </Button>
      </div>
    </Modal>
  )
}
