export function IconAccountPartners() {
  return (
    <>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 15l-2.172 2.172a4 4 0 01-5.656 0L13 17l4.929-4.929a7.072 7.072 0 0110 0v0A7.074 7.074 0 0129 20.7"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22.266 18.266l3.613 3.613a3 3 0 010 4.242l-1.636 1.636a6 6 0 01-8.486 0l-5.686-5.686a7.072 7.072 0 010-10v0A7.048 7.048 0 0115 10M25.391 26.609l-3.891-3.89M22.513 28.965l-3.63-3.63"
      ></path>
    </>
  )
}
