export function AccountProfile() {
  return (
    <>
      <circle
        cx="20"
        cy="14.429"
        r="3.429"
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
      ></circle>
      <path
        fill="none"
        stroke="currentColor"
        className="dynamic-stroke-width"
        d="M22.286 21.286h-4.572A5.714 5.714 0 0012 27v.229s3.429.914 8 .914 8-.914 8-.914V27a5.714 5.714 0 00-5.714-5.714z"
      ></path>
    </>
  )
}
