import { cn } from '@lib/cn'
import { FC, InputHTMLAttributes } from 'react'

type Props = InputHTMLAttributes<HTMLInputElement>

export const Checkbox: FC<Props> = ({ checked, disabled, ...rest }) => {
  return (
    <>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        className={cn(
          'peer absolute block h-6 w-6 cursor-pointer text-tabaccoBrown opacity-0',
          'sm:h-12 sm:w-12',
          'disabled:text-shade-300',
        )}
        {...rest}
      />
      <span
        className={cn(
          'pointer-events-none mr-3 flex h-6 w-6 shrink-0 items-center justify-center rounded-full border border-tabaccoBrown bg-white shadow-transparent transition-all duration-100',
          'sm:mr-6 sm:h-12 sm:w-12 sm:border-2',
          'peer-hover:shadow-[0_0_0_2px_tabaccoBrown] peer-hover:shadow-tabaccoBrown/30',
          'peer-disabled:border-shade-300',
        )}
      >
        {checked && (
          <>
            <img
              className="hidden sm:block"
              alt="check"
              src={
                disabled
                  ? '/images/checkmark_disabled_desktop.svg'
                  : '/images/checkmark_desktop.svg'
              }
            />
            <img
              className="block sm:hidden"
              alt="check"
              src={
                disabled ? '/images/checkmark_disabled_mobile.svg' : '/images/checkmark_mobile.svg'
              }
            />
          </>
        )}
      </span>
    </>
  )
}
