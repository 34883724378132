import { HeaderImageColumn } from './image-column';
import { HeaderImageListColumn } from './image-list-column';
import { HeaderLinkColumn } from './link-column';
import { hideMenuElement } from '@components/Page/Header/styles';
import { Container, Link } from '@components/gassan-ui';
import { parse } from '@config/theme';
import { DiamondsContent } from '@lib/kontent/navigation-helpers/get-diamonds-content';
import { cx } from '@linaria/core';
import NextLink from 'next/link';
import { FC } from 'react';
type HeaderDiamondsMenuProps = DiamondsContent & {
  isVisible: boolean;
};
export const HeaderDiamondsMenu: FC<HeaderDiamondsMenuProps> = ({
  linkList,
  cutsList,
  imageLinkFirstColumn,
  imageLinkSecondColumn,
  isVisible
}) => {
  return <Container className={cx(grid, hideMenuElement)} data-is-visible={isVisible}>
      <HeaderLinkColumn title={linkList.title} readMoreLink={linkList.viewMore && <NextLink href={linkList.viewMore.url} passHref>
              <Link variant="brown">{linkList.viewMore.label}</Link>
            </NextLink>} links={linkList.links} linkSpace="regular" />
      <HeaderImageListColumn title={cutsList.title} links={cutsList.links} />
      <HeaderImageColumn {...imageLinkFirstColumn} />
      <HeaderImageColumn {...imageLinkSecondColumn} showOnlyOnXlarge />
    </Container>;
};
const grid = parse({
  display: 'grid'
}, "g66uewd");

require("./DiamondsMenu.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./DiamondsMenu.tsx");