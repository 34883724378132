import { parse } from '@config/theme';
export const backdrop = parse({
  position: 'fixed',
  width: '100%',
  height: '100%'
}, "b452olk");
export const dropdown = parse({
  position: 'absolute',
  width: '100%'
}, "d93jaal");

require("./Dropdown.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Dropdown.styles.ts");