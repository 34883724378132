import { useLocalization } from '@components/Localization'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Icon, IconType } from '@components/gassan-ui'
import { useAuth } from '@lib/hooks/use-auth'
import { useTranslation } from 'next-i18next'
import { FC, HTMLAttributes } from 'react'

type Props = HTMLAttributes<HTMLDivElement> & {
  isVisible: boolean
}

const nlFlag = '/logos/flag_nl.png'
const enFlag = '/logos/flag_en.png'

const HeaderMobileSubNav: FC<Props> = ({ isVisible, ...rest }) => {
  const { isLoggedIn, logout } = useAuth()
  const { setLang } = useLocalization()
  const {
    t,
    i18n: { language },
  } = useTranslation(['navigation', 'other'])

  return (
    <div
      className={`absolute left-0 w-full border-t border-tabaccoBrown/10 py-3 opacity-100  ${
        isVisible ? 'opacity-100' : 'pointer-events-none opacity-0'
      }`}
      {...rest}
    >
      {getItems().map(({ icon, title, href, onClick }, index) => (
        <div className="flex items-center justify-between px-2 md:px-5" key={title}>
          {href ? (
            <LocalizedLink href={href}>
              <a className="flex items-center gap-1  py-3 text-sm text-shade-800 md:gap-2  md:text-base">
                <Icon icon={icon} dynamicStroke={false} className="text-shade-700" />
                {title}
              </a>
            </LocalizedLink>
          ) : onClick ? (
            <button
              className="flex items-center gap-1  py-3 text-sm text-shade-800 md:gap-2  md:text-base"
              onClick={onClick}
            >
              <Icon icon={icon} dynamicStroke={false} className="text-shade-700" />
              {title}
            </button>
          ) : null}
          {index === getItems().length - 1 && (
            <button
              className="mr-3 flex items-center gap-2.5 text-sm text-shade-800 md:mr-6 md:text-base"
              onClick={() => setLang(language === 'nl' ? 'en' : 'nl')}
            >
              {t('switchTo', { ns: 'other' })}
              <img src={language === 'nl' ? enFlag : nlFlag} alt="" className="h-5 w-5" />
            </button>
          )}
        </div>
      ))}
    </div>
  )

  function getItems(): { icon: IconType; title: string; href?: string; onClick?: () => void }[] {
    return isLoggedIn
      ? [
          {
            icon: 'diamond',
            title: t('secondaryNav.myGassan.title'),
            href: t('secondaryNav.myGassan.url'),
          },
          {
            icon: 'account-profile',
            title: t('secondaryNav.account.title'),
            href: t('secondaryNav.account.url'),
          },
          {
            icon: 'account-heart',
            title: t('secondaryNav.wishlist.title'),
            href: t('secondaryNav.wishlist.url'),
          },
          {
            icon: 'map-marker',
            title: t('secondaryNav.stores.title'),
            href: t('secondaryNav.stores.url'),
          },
          {
            icon: 'contact',
            title: t('secondaryNav.contact.title'),
            href: t('secondaryNav.contact.url'),
          },
          {
            icon: 'account-logout',
            title: t('logOut', { ns: 'other' }),
            onClick: logout,
          },
        ]
      : [
          {
            icon: 'diamond',
            title: t('account.myGassanInfo.title'),
            href: t('account.myGassanInfo.url'),
          },
          {
            icon: 'account-profile',
            title: t('account.login.title'),
            href: t('account.login.url'),
          },
          {
            icon: 'map-marker',
            href: t('secondaryNav.stores.url'),
            title: t('secondaryNav.stores.title'),
          },
          {
            icon: 'contact',
            title: t('secondaryNav.contact.title'),
            href: t('secondaryNav.contact.url'),
          },
        ]
  }
}

export default HeaderMobileSubNav
