// @ts-strict-ignore
import { BaseProps } from '../theme'
import css, { ResponsiveStyleValue, SystemStyleObject } from '@styled-system/css'
import shouldForwardProp from '@styled-system/should-forward-prop'
import React, { FC } from 'react'
import styled from 'styled-components'
import {
  BackgroundProps,
  BorderProps,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TypographyProps,
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system'

type StylesProps = SpaceProps &
  ColorProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  GridProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps

type SxStyleProp =
  | SystemStyleObject
  | Record<
      string,
      | SystemStyleObject
      | ResponsiveStyleValue<number | string>
      | Record<string, SystemStyleObject | ResponsiveStyleValue<number | string>>
    >

export type BoxProps = React.RefAttributes<HTMLElement> &
  Omit<React.HTMLAttributes<HTMLElement>, 'color'> &
  BaseProps &
  StylesProps & {
    sx?: SxStyleProp
  }

export const Box: FC<BoxProps> = styled<React.FC<BoxProps>>(
  React.forwardRef((props: BoxProps, ref: any) => {
    const As = props.as || 'div'
    return <As {...props} ref={ref}></As>
  }),
).withConfig({
  shouldForwardProp,
})(
  (props) => props.sx && css(props.sx),
  compose(space, color, typography, layout, flexbox, grid, background, border, position, shadow),
)
