import { parse } from '@config/theme';
export const refStyling = parse({
  width: '100%'
}, "razxpnt");
export const scrollTopRef = parse({
  position: 'absolute'
}, "s1aoyxvy");
export const headerHeightRef = parse({
  position: 'relative'
}, "h18qc1tw");
export const hideMenuElement = "hrgsrwx";

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");