export function ChevronDownSmall() {
  return (
    <path
      d="M15.625 18.125C17.3077 18.4659 20 21.875 20 21.875C20 21.875 22.6923 18.4659 24.375 18.125"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
    />
  )
}
