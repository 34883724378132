import { useAuth } from './use-auth'
import { useUpdateUserWishlistMutation, useWishlistsQuery } from '@generated'

export function useWishlistData() {
  // This will be updated in the future to load a specific wishlist based on an ID
  const { isLoading, isLoggedIn } = useAuth()
  const [{ data }] = useWishlistsQuery({
    pause: !isLoggedIn || isLoading,
  })

  const [, updateWishlistMutation] = useUpdateUserWishlistMutation()
  // Currently a user can only have one wishlist
  const firstWishlist = data && data.wishlists ? data.wishlists[0] : null

  return {
    wishlist: firstWishlist,
    updateWishlist: (articleIds: string[]) =>
      firstWishlist &&
      updateWishlistMutation({
        id: firstWishlist.id,
        articleIds: articleIds,
        status: firstWishlist.status,
      }),
  }
}
