export function CheckCircle() {
  return (
    <>
      <circle cx="20" cy="20" r="10" fill="currentColor" />
      <path
        d="M15 20.3636L18.3333 24C18.3333 24 19.5 22 21 20C22.5 18 25 16 25 16"
        stroke="white"
        fill="none"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </>
  )
}
