export function IconDiamond() {
  return (
    <>
      <path
        stroke="currentColor"
        className="dynamic-stroke-width"
        strokeLinejoin="round"
        d="M24.42 17.732l-4.415 12.267a.505.505 0 00.389-.177l10.025-12.071-.013-.02H24.42zM9.603 17.732l-.013.019 10.025 12.071a.505.505 0 00.389.177l-4.415-12.267H9.603z"
      ></path>
      <path
        stroke="currentColor"
        className="dynamic-stroke-width"
        strokeLinejoin="round"
        d="M26.25 11.25H13.628L9.58 17.734h20.842L26.25 11.25z"
      ></path>
      <path
        stroke="currentColor"
        className="dynamic-stroke-width"
        strokeLinejoin="round"
        d="M15.586 17.732L20 29.999l4.415-12.267h-8.83z"
      ></path>
      <path
        stroke="currentColor"
        className="dynamic-stroke-width"
        strokeLinejoin="round"
        d="M24.416 17.734l-2.541-6.484h-3.75l-2.54 6.484h8.83z"
      ></path>
    </>
  )
}
