import { handleFetchResponse } from '../../lib/handleFetchResponse'
import fetch from 'isomorphic-unfetch'
import * as qs from 'query-string'

const PROJECT_ID = 'f3c6491f-b1ec-01e4-bf22-cb84ae84970c'
const PREVIEW_API_KEY =
  'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjM0ZDU4ZGNhZTFmMDQ2N2M4ODQ3YzhiODIzMDdhZTU5IiwNCiAgImlhdCI6ICIxNTc4OTMwOTI4IiwNCiAgImV4cCI6ICIxOTI0NTMwOTI4IiwNCiAgInByb2plY3RfaWQiOiAiZjNjNjQ5MWZiMWVjMDFlNGJmMjJjYjg0YWU4NDk3MGMiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.tBcF_o4vNolgW6UXZEjgr0mhg8KkbwnTrZ7X5x29FoI'

const getKontentApiUrl = (preview?: boolean): string =>
  `https://${preview ? 'preview-' : ''}deliver.kontent.ai/${PROJECT_ID}/`

type System = {
  id: string
  name: string
  codename: string
  language: string
  type: string
  sitemap_locations: any[]
  last_modified: Date
}

export type Item<Elements = any> = { system: System; elements: Elements }

export type SingleItemResponse<Elements = any> = {
  item: Item<Elements>
  modular_content: { [key: string]: any }
}

export type MultipleItemResponse<Elements = any> = {
  items: Item<Elements>[]
  modular_content: { [key: string]: any }
  pagination: {
    skip: number
    limit: number
    count: number
    total_count?: number
  }
}

type Params = {
  [key: string]: string | number | string[] | boolean
}

type Args = {
  path: string
  preview?: boolean
  params: Params
  route?: string
}

export const fetchContent = ({ path, preview, params, route = 'items' }: Args) => {
  let url = `${getKontentApiUrl(preview)}${route}${path}`
  if (params) {
    url += `?depth=2&${qs.stringify(params, { arrayFormat: 'comma' })}`
  }
  const usePreview = preview || process.env.NODE_ENV === 'development'
  return fetch(url, {
    headers: usePreview
      ? {
          Authorization: `Bearer ${PREVIEW_API_KEY}`,
        }
      : undefined,
  }).then(handleFetchResponse)
}
