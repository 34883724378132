export function IconAccountDiamondDocument() {
  return (
    <>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        className="dynamic-stroke-width"
        d="M11 30V8h18v22H11zM15 22h10M15 26h5"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
        d="M17.933 11.867h5.134L24 13.733l-3.5 4.2-3.5-4.2.933-1.866z"
      ></path>
    </>
  )
}
