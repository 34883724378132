// @ts-strict-ignore
import { RefObject, useEffect, useRef } from 'react'

// Combines two refs to one, useful when you have a component where you want an internal ref
// and an external one. E.g. a video player that can be controlled from both inside and outside the component
export function useCombinedRefs<T>(...refs): RefObject<T> {
  const targetRef = useRef()

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}
