export function ChevronUp() {
  return (
    <path
      d="M27 23C24.3077 22.4545 20 17 20 17C20 17 15.6923 22.4545 13 23"
      stroke="currentColor"
      className="dynamic-stroke-width"
      fill="none"
    />
  )
}
