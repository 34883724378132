export function ChevronLeftSmall() {
  return (
    <path
      d="M21.875 15.625C21.5341 17.3077 18.125 20 18.125 20C18.125 20 21.5341 22.6923 21.875 24.375"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
    />
  )
}
