export function AccountMyGassan() {
  return (
    <>
      <rect
        x="8"
        y="12"
        width="24"
        height="16"
        stroke="currentColor"
        fill="transparent"
        className="dynamic-stroke-width"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 15.625V24.375C27 24.7202 26.7151 25 26.3636 25C26.0122 25 25.7273 24.7202 25.7273 24.375V15.625V15.625C25.7273 15.2798 26.0122 15 26.3636 15C26.7151 15 27 15.2798 27 15.625V15.625ZM24.4545 15V15C24.806 15 25.0909 15.2798 25.0909 15.625V24.375C25.0909 24.7202 24.806 25 24.4545 25H23.8182V25C23.4667 25 23.1818 24.7202 23.1818 24.375V24.375V15.625V15.625C23.1818 15.2798 23.4667 15 23.8182 15H24.4545ZM21.2727 15V15C21.6242 15 21.9091 15.2798 21.9091 15.625V24.375C21.9091 24.7202 21.6242 25 21.2727 25H20.9545V25C20.6031 25 20.3182 24.7202 20.3182 24.375V24.375V15.625V15.625C20.3182 15.2798 20.6031 15 20.9545 15H21.2727ZM19.6818 15.625V24.375C19.6818 24.7202 19.3969 25 19.0455 25C18.694 25 18.4091 24.7202 18.4091 24.375V15.625V15.625C18.4091 15.2798 18.694 15 19.0455 15C19.3969 15 19.6818 15.2798 19.6818 15.625V15.625ZM16.8182 15.625V24.375C16.8182 24.7202 16.5333 25 16.1818 25C15.8304 25 15.5455 24.7202 15.5455 24.375V15.625V15.625C15.5455 15.2798 15.8304 15 16.1818 15C16.5333 15 16.8182 15.2798 16.8182 15.625V15.625ZM14.2727 15V15C14.6242 15 14.9091 15.2798 14.9091 15.625V24.375C14.9091 24.7202 14.6242 25 14.2727 25H13.6364V25C13.2849 25 13 24.7202 13 24.375V24.375V15.625V15.625C13 15.2798 13.2849 15 13.6364 15H14.2727Z"
        fill="currentColor"
      />
    </>
  )
}
