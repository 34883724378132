export function AccountContact() {
  return (
    <>
      <path
        fill="none"
        stroke="currentColor"
        className="dynamic-stroke-width"
        d="M11 20a8.96 8.96 0 001.048 4.219l-.808 3.034a.6.6 0 00.681.746l3.067-.523A9 9 0 1011 20z"
      ></path>
      <path stroke="currentColor" className="dynamic-stroke-width" d="M16 22h6M16 18h8"></path>
    </>
  )
}
