export function AccountLock() {
  return (
    <>
      <g>
        <path
          d="M13.3333 17.6804C13.3333 18.2326 13.781 18.6804 14.3333 18.6804C14.8856 18.6804 15.3333 18.2326 15.3333 17.6804H13.3333ZM24.6667 17.6804C24.6667 18.2326 25.1144 18.6804 25.6667 18.6804C26.219 18.6804 26.6667 18.2326 26.6667 17.6804H24.6667ZM28.5 29.55V30.55C29.0523 30.55 29.5 30.1023 29.5 29.55H28.5ZM11.5 29.55H10.5C10.5 30.1023 10.9477 30.55 11.5 30.55V29.55ZM15.3333 15.5857C15.3333 13.0666 17.4091 11 20 11V9C16.3317 9 13.3333 11.935 13.3333 15.5857H15.3333ZM20 11C22.5909 11 24.6667 13.0666 24.6667 15.5857H26.6667C26.6667 11.935 23.6683 9 20 9V11ZM13.3333 15.5857V17.6804H15.3333V15.5857H13.3333ZM24.6667 15.5857V17.6804H26.6667V15.5857H24.6667ZM13.5 19.3786H26.5V17.3786H13.5V19.3786ZM27.5 20.3786V29.55H29.5V20.3786H27.5ZM28.5 28.55H11.5V30.55H28.5V28.55ZM12.5 29.55V20.3786H10.5V29.55H12.5ZM26.5 19.3786C27.0523 19.3786 27.5 19.8263 27.5 20.3786H29.5C29.5 18.7217 28.1569 17.3786 26.5 17.3786V19.3786ZM13.5 17.3786C11.8431 17.3786 10.5 18.7217 10.5 20.3786H12.5C12.5 19.8263 12.9477 19.3786 13.5 19.3786V17.3786Z"
          fill="currentColor"
        />
        <circle cx="20.0004" cy="23.9643" r="1.21429" fill="currentColor" />
      </g>
    </>
  )
}
