export function IconBarCode() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="18" fill="none" viewBox="0 0 26 18">
      <g fill="#182F40" clipPath="url(#clip0_7408_68258)">
        <path d="M2.33.067H1.275V18.49H2.33V.067zM25.49.067h-1.053V18.49h1.052V.067zM4.96.067H3.909V18.49h1.053V.067zM22.857.067h-1.052V18.49h1.052V.067zM20.226.067h-1.053V18.49h1.053V.067zM10.225.067H6.54V18.49h3.685V.067zM18.12.067h-3.158V18.49h3.158V.067zM13.383.067h-1.58V18.49h1.58V.067z"></path>
      </g>
      <defs>
        <clipPath id="clip0_7408_68258">
          <path fill="currentColor" d="M0 0H24.5V17.865H0z" transform="translate(.75 .067)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
