export function Calendar() {
  return (
    <>
      <g className="hide-on-small">
        <path
          d="M11 17.5V27.5C11 28.6046 11.8954 29.5 13 29.5H27C28.1046 29.5 29 28.6046 29 27.5V17.5M11 17.5V12.5H20H29V17.5M11 17.5H29M15 15V10M25 15V10"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
        />
        <rect x="14" y="20.5" width="2" height="2" fill="currentColor" />
        <rect x="14" y="24.5" width="2" height="2" fill="currentColor" />
        <rect x="19" y="20.5" width="2" height="2" fill="currentColor" />
        <rect x="19" y="24.5" width="2" height="2" fill="currentColor" />
        <rect x="24" y="20.5" width="2" height="2" fill="currentColor" />
        <rect x="24" y="24.5" width="2" height="2" fill="currentColor" />
      </g>
      <g className="show-on-small">
        <path
          d="M8.75 16.875V29.875C8.75 30.9796 9.64543 31.875 10.75 31.875H29.25C30.3546 31.875 31.25 30.9796 31.25 29.875V16.875M8.75 16.875V10.625H20H31.25V16.875M8.75 16.875H31.25M13.75 13.75V7.5M26.25 13.75V7.5"
          stroke="currentColor"
          fill="none"
        />
        <rect x="13.125" y="21.25" width="1.25" height="1.25" fill="currentColor" />
        <rect x="13.125" y="26.25" width="1.25" height="1.25" fill="currentColor" />
        <rect x="19.375" y="21.25" width="1.25" height="1.25" fill="currentColor" />
        <rect x="19.375" y="26.25" width="1.25" height="1.25" fill="currentColor" />
        <rect x="25.625" y="21.25" width="1.25" height="1.25" fill="currentColor" />
        <rect x="25.625" y="26.25" width="1.25" height="1.25" fill="currentColor" />
      </g>
    </>
  )
}
