export function IconAccountPrivileges() {
  return (
    <>
      <path
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
        d="M10.594 20.025h.836c1.897 0 4.036 1.043 5.018 2.509h3.345c1.678 0 3.345 1.667 3.345 3.345h-7.527"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
        d="M22.604 24.206h5.552c3.031 0 3.345 1.673 3.345 1.673l-11.976 4.864a3.592 3.592 0 01-2.941-.122l-5.99-3.043"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
        d="M10.594 18.353H7.25v10.871h3.345V18.353zM20 12h3.5M27.085 11.813l-5.318 7.446-5.319-7.446 2.128-2.66h6.382l2.127 2.66z"
      ></path>
    </>
  )
}
