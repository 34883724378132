// @ts-strict-ignore
import { BaseLink } from '.'
import { linkStyle } from '../Typography/styles'
import styled, { css } from 'styled-components'
import { display, space } from 'styled-system'

const styles = {
  blue: css`
    color: ${(p) => p.theme.colors.bigStone};
  `,
  brown: css`
    color: ${(p) => p.theme.colors.tabaccoBrown};
  `,
  grey: css`
    color: ${(p) => p.theme.colors.shades[500]};
  `,
}

export const Link = styled.a<BaseLink>`
  ${linkStyle};
  ${space};
  ${display};
  width: auto;
  transition: all 0.25s ease-in-out;
  padding: 0;
  outline: 0;
  align-items: center;
  line-height: 1.45;
  border-bottom: 1px solid currentColor;
  padding-bottom: 0.25rem;

  ${(p) =>
    p.display === 'block' &&
    css`
      clear: left;
      float: left;
    `}

  ${(p) => styles[p.variant]};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`
