import { parse } from '@config/theme';
export const background = parse({
  position: 'absolute',
  width: '100%'
}, "b1orfv8q");
export const header = parse({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  position: 'absolute'
}, "h1d4y1fv");

require("./HeaderContent.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./HeaderContent.styles.ts");