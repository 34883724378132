import Buttons from './Buttons'
import { FloatingServiceMenuModal } from './Modal'
import { useFloatingServiceMenu } from './hooks/use-floating-service-menu'
import { Box, CleanButton, Flex, Icon, breakpoints, theme } from '@gassan-ui'
import { motion } from 'framer-motion'
import { useRouter } from 'next/router'
import { rem } from 'polished'
import { FC, useState } from 'react'
import styled from 'styled-components'

const RoundButton = styled(CleanButton)<{ showElement: boolean }>`
  background: ${(p) => p.theme.colors.bigStone};
  display: flex;
  pointer-events: ${(p) => (p.showElement ? 'all' : 'none')};
  align-items: center;
  justify-content: center;
  color: white;
  height: 3rem;
  width: 3rem;
  border-radius: 100%;
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.07);
  bottom: env(safe-area-inset-bottom);

  @media screen and (min-width: ${breakpoints.small}) {
    height: 3.5rem;
    width: 3.5rem;
  }
`

const Image = styled.img`
  height: ${rem(24)};
  width: ${rem(24)};

  @media screen and (min-width: ${breakpoints.small}) {
    height: ${rem(30)};
    width: ${rem(30)};
  }
`

const Container = styled(Flex)`
  transition-property: opacity;
  transition-duration: 0.3s;
`

const BackToTopBox = styled(motion(Box))``

const BackToTop = styled(CleanButton)`
  width: 100%;
  height: 100%;
  background: ${(p) => p.theme.colors.bigStone};
  color: white;
`
export type FSMVariant =
  | 'default'
  | 'appear-on-scroll'
  | 'move-element-up'
  | 'move-element-up-product-page'
type Props = {
  variant?: FSMVariant
  showBackToTop?: boolean
}

const FloatingServiceMenu: FC<Props> = ({ variant = 'default', showBackToTop = false }) => {
  const { route } = useRouter()
  const paths = route.split('/')
  const [showModal, setShowModal] = useState(false)
  const { state, setState, showElement, bottomPosition, onClickBackToTop } = useFloatingServiceMenu(
    variant,
    showBackToTop,
  )

  if (paths[2] === 'rolex') return null

  return (
    <>
      <Container
        position="fixed"
        flexDirection="column"
        alignItems="flex-end"
        bottom={bottomPosition}
        right="0"
        maxWidth="22rem"
        opacity={showElement ? 1 : 0}
        width="calc(100% - 2rem)"
        zIndex={theme.zIndices.floatingServiceMenu}
        mb={{ _: '1.5rem', small: '2.5rem', large: '3rem' }}
        mx={{ _: '1rem', small: '2.5rem', large: '3rem' }}
        style={{ pointerEvents: 'none' }}
      >
        <motion.div layoutId="fsm-button">
          <RoundButton
            onClick={() => setState(state === 'closed' ? 'open' : 'closed')}
            showElement={showElement}
          >
            {state === 'closed' ? (
              <>
                <Box display={['none', 'flex']}>
                  <Image src="/images/contact_desktop.svg" alt="open menu" />
                </Box>
                <Box display={['flex', 'none']}>
                  <Image src="/images/contact_mobile.svg" alt="open menu" />
                </Box>
              </>
            ) : (
              <Icon icon="cross" size={[32, 40]}></Icon>
            )}
          </RoundButton>
        </motion.div>
        {state === 'open' && (
          <Buttons
            handleClickContact={() => {
              setState('closed')
              setShowModal(true)
            }}
          />
        )}
        {showModal && <FloatingServiceMenuModal handleRequestClose={() => setShowModal(false)} />}
      </Container>
      {showBackToTop && (
        <BackToTopBox
          initial={{ opacity: 0, y: 30 }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          exit={{ opacity: 0, y: 30 }}
          transition={{ duration: 0.2, ease: [0.47, 0, 0.745, 0.715] }}
          width="3.5rem"
          height="3.5rem"
          position="fixed"
          bottom="env(safe-area-inset-bottom)"
          right="0"
          zIndex={theme.zIndices.floatingServiceMenu}
          mb={{ _: '1.5rem', small: '2.5rem', large: '3rem' }}
          mx={{ _: '1rem', small: '2.5rem', large: '3rem' }}
        >
          <BackToTop onClick={() => onClickBackToTop()}>
            <Icon icon="chevron-up" />
          </BackToTop>
        </BackToTopBox>
      )}
    </>
  )
}

export default FloatingServiceMenu
