// @ts-strict-ignore
import * as qs from 'query-string'

type ImageArgs = {
  width?: number
  height?: number
}

export const getKontentImage = (imageUrl: string, args: ImageArgs = {}): string => {
  let params = {
    auto: 'format',
    lossless: true,
  }
  if (args.width) {
    params['w'] = args.width
  }
  if (args.height) {
    params['h'] = args.height
  }
  if (!imageUrl) {
    return ''
  }
  const url = `${imageUrl.replace(
    'https://assets-eu-01.kc-usercontent.com:443',
    '/assets',
  )}?${qs.stringify(params)}`
  return url
}
