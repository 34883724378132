import { parse } from '@config/theme';
export const subrowButton = parse({
  textAlign: 'left',
  width: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  p: '4'
}, "s1gntmxl");
export const linkStyle = parse({
  width: '100%',
  color: 'shade800',
  textAlign: 'left',
  fontFamily: 'body'
}, "l144zxf2");
export const subrowContent = parse({
  height: '100%'
}, "s1esk9od");

require("./Subrow.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Subrow.styles.ts");