import * as cookie from 'js-cookie'
import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'

const Awin: FC = () => {
  const { query } = useRouter()

  useEffect(() => {
    if (query.awc) {
      cookie.set('awc', JSON.stringify(query.awc), { expires: 365, secure: true })
    }
  }, [query]) //eslint-disable-line

  return null
}

export default Awin
