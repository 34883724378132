import { breakpoints } from '../theme'
import { rgba } from 'polished'
import { css } from 'styled-components'
import { space } from 'styled-system'

export type InputStatusProp = {
  status?: 'error' | 'valid' | null
}

export const baseStyles = css<InputStatusProp>`
  ${space};
  -webkit-appearance: none;
  font-family: ${(p) => p.theme.fonts.body};
  transition:
    transform 0.2s ease,
    border-color 0.2s ease;
  font-size: 1rem;
  display: block;

  padding: 0 0.75rem;

  letter-spacing: 0.01rem;
  outline: 0;
  border: 1px solid ${(p) => p.theme.colors.shades[400]};
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  color: ${(p) => p.theme.colors.shades[800]};
  border-radius: 0;

  &::placeholder {
    opacity: 0;
  }

  ${(p) =>
    p.status === 'error'
      ? css`
          border-color: ${p.theme.colors.error};
          &:focus {
            box-shadow: 0 0 0 3px ${(p) => p.theme.colors.errorLight};
          }
        `
      : css`
          &:hover {
            border-color: ${(p) => p.theme.colors.shades[800]};
          }
          &:focus {
            box-shadow: 0 0 0 3px ${(p) => rgba(p.theme.colors.tabaccoBrown, 0.2)};
            border-color: ${(p) => p.theme.colors.shades[800]};
          }
        `}

  &:disabled {
    cursor: not-allowed;
    background-color: ${(p) => p.theme.colors.shades[100]};
    border-color: ${(p) => p.theme.colors.shades[400]};
    & + label {
      color: ${(p) => p.theme.colors.shades[500]};
    }
  }
`

export const selectStyles = css`
  ${baseStyles};
  height: 3rem;
  line-height: 3rem;
  background-size: 2rem;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;

  appearance: none;
  cursor: pointer;
  position: relative;

  @media screen and (min-width: ${breakpoints.small}) {
    height: 3.5rem;
    line-height: 3.5rem;
  }
`

export const popoverStyle = css`
  display: block;
  position: absolute;
  bottom: -0.5rem;
  transform: translateY(100%);
  min-width: min-content;
  padding: 0.25rem 0;
  background: hsl(0, 0%, 100%);
  outline: none;
  border: 1px solid ${(p) => p.theme.colors.shades[200]};
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.09);
  padding: 0.5rem 0;
  z-index: ${(p) => p.theme.zIndices.popOver};
  &[hidden] {
    display: none;
  }
`

export const popoverOptionStyle = css`
  transition: all 0.2s ease-in-out;
  font-family: ${(p) => p.theme.fonts.body};
  color: ${(p) => p.theme.colors.shades[800]};
  line-height: 2.5rem;
  padding: 0 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &[data-current] {
    background: ${(p) => p.theme.colors.shades[100]} !important;
    font-weight: bold;
    &:hover {
      background: ${(p) => p.theme.colors.shades[100]};
      font-weight: bold;
    }
  }
  &[data-selected],
  &[aria-selected='true'] {
    background: ${(p) => p.theme.colors.shades[100]};
  }
`
