// @ts-strict-ignore
import { Checkbox, CheckboxProps } from './Checkbox'
import { FieldControl } from './FieldControl'
import { Input, InputProps } from './Input'
import { Radio, RadioProps } from './Radio'
import { Select, SelectProps } from './Select'
import { Textarea, TextareaProps } from './Textarea'
import { useField } from 'formik'
// @ts-ignore
import { useTranslation } from 'next-i18next'
import React, { FC } from 'react'
import { GridColumnProps, MarginBottomProps, MarginTopProps } from 'styled-system'

type InputType = InputProps & {
  field: 'input'
}

type TextareaType = TextareaProps & {
  field: 'textarea'
}
type SelectType = SelectProps & {
  field: 'select'
}
type CheckboxType = CheckboxProps & {
  field: 'checkbox'
}
type RadioType = RadioProps & {
  field: 'radio'
}

type FieldType = InputType | TextareaType | SelectType | CheckboxType | RadioType

export type FieldProps = FieldType &
  MarginBottomProps &
  MarginTopProps &
  GridColumnProps & {
    label: React.ReactNode
    assistiveText?: string
    errorMsg?: string
  }

export const Field: FC<FieldProps> = ({
  field = 'input',
  label,
  assistiveText,
  mt,
  marginTop,
  mb,
  marginBottom,
  gridColumn,
  ...rest
}) => {
  const { t } = useTranslation('forms')
  // The ts-ignores and any are a bit messy, but the most important thing
  // is that the Field component is correctly typed based on the value of the field prop and that works.
  // If you use Field and set field as "input", e.g. <Field field="input" />,
  // field will be typed with the Input interface 🤘
  const [fieldProps, meta] = useField<any>(rest.name)
  let status = 'idle'
  if (meta.touched && fieldProps.value && !meta.error) {
    status = 'valid'
  }
  if (meta.error && meta.touched) {
    status = 'error'
  }
  const error = meta.error?.replace('forms.', '')
  switch (field) {
    case 'input':
      return (
        <FieldControl
          label={label}
          assistiveText={assistiveText}
          errorMsg={meta.touched && meta.error ? t(error) : undefined}
          gridColumn={gridColumn}
          field={field}
          htmlFor={rest.name}
          mb={mb}
        >
          {/*
          // @ts-ignore */}
          <Input status={status} {...fieldProps} {...rest} />
        </FieldControl>
      )
    case 'textarea':
      return (
        <FieldControl
          label={label}
          assistiveText={assistiveText}
          errorMsg={meta.touched && meta.error ? t(error) : undefined}
          gridColumn={gridColumn}
          field={field}
          htmlFor={rest.name}
          mb={mb}
          mt={mt}
        >
          {/*
          // @ts-ignore */}
          <Textarea status={status} {...fieldProps} {...rest} />
        </FieldControl>
      )
    case 'select':
      return (
        <FieldControl
          assistiveText={assistiveText}
          errorMsg={meta.touched && meta.error ? t(error) : undefined}
          gridColumn={gridColumn}
          field={field}
          htmlFor={rest.name}
          mb={mb}
        >
          {/*
          // @ts-ignore */}
          <Select
            status={!!meta.touched && !!meta.error ? 'error' : null}
            {...fieldProps}
            {...rest}
          />
        </FieldControl>
      )
    case 'checkbox':
      // @ts-ignore
      return (
        <FieldControl
          assistiveText={assistiveText}
          errorMsg={meta.touched && meta.error ? t(error) : undefined}
          gridColumn={gridColumn}
          field={field}
          htmlFor={rest.name}
          mb={mb}
        >
          {/*
          // @ts-ignore */}
          <Checkbox
            checked={fieldProps.value}
            label={label}
            status={!!meta.touched && !!meta.error ? 'error' : null}
            {...fieldProps}
            {...rest}
          />
        </FieldControl>
      )
    case 'radio':
      return (
        // @ts-ignore
        <Radio
          checked={fieldProps.value === rest.value}
          label={label}
          mt={mt}
          mb={mb}
          {...fieldProps}
          {...rest}
        />
      )
  }
  return null
}
