import Notice from './Notice'
import { hasCookieConsent } from '@lib/cookies'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

type Props = {}

const Cookies: FC<Props> = () => {
  const [cookieConsent, setCookieConsent] = useState(true)
  const router = useRouter()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCookieConsent(hasCookieConsent())
    }
  }, [setCookieConsent])

  if (cookieConsent || router.pathname === '/invoice-download') {
    return null
  }

  return <Notice />
}

export default Cookies
