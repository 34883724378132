export function AccountDocument() {
  return (
    <>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        d="M14.435 16.574h11.13m-11.13 3.098h11.13m-11.13 3.098h6.609M28 13.131V30H12V9h11.826M28 13.131L23.826 9M28 13.131h-4.174V9"
      ></path>
    </>
  )
}
