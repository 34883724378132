export function IconAccountEvents() {
  return (
    <>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.888 7v2.6M14.984 9.03l1.839 1.839M24.79 9.03l-1.838 1.839M21.837 18.747l6.61-.87M21.272 14.45l.026 7.864a4.333 4.333 0 108.593-1.131l-2.01-7.603-6.61.87zM26.16 26.045l.905 6.874M23.628 33.371l6.874-.905M10.914 17.74l6.61.87M11.48 13.443l-2.01 7.603a4.333 4.333 0 108.593 1.131l.026-7.864-6.61-.87zM13.201 25.908l-.905 6.874M8.858 32.33l6.874.904"
      ></path>
    </>
  )
}
