export function SliderArrowRight() {
  return (
    <>
      <g className="hide-on-small">
        <path
          d="M31.1618 26.5C31.6471 24 36.5 20 36.5 20C36.5 20 31.6471 16 31.1618 13.5"
          stroke="currentColor"
          className="dynamic-stroke-width"
          fill="none"
        />
        <path
          d="M36.5 20L3.5 20"
          stroke="currentColor"
          className="dynamic-stroke-width"
          fill="none"
        />
      </g>
      <g className="show-on-small">
        <path
          d="M18 27C18.5455 24.3077 24 20 24 20C24 20 18.5455 15.6923 18 13"
          stroke="currentColor"
          className="dynamic-stroke-width"
          fill="none"
        />
      </g>
    </>
  )
}
