// @ts-strict-ignore
import create from 'zustand'

type EmarsysStore = {
  scriptIsLoaded: boolean
  setScriptIsLoaded: () => void
  featuredProducts?: string[]
  relatedProducts?: string[]
  recommendedProducts?: string[]
  setProductIds: (type: 'featured' | 'related' | 'recommended', ids: string[]) => void
}

export const useEmarsysStore = create<EmarsysStore>((set) => ({
  scriptIsLoaded: false,
  setScriptIsLoaded: () => set({ scriptIsLoaded: true }),
  featuredProducts: null,
  relatedProducts: null,
  recommendedProducts: null,
  setProductIds: (type, ids) => {
    if (type === 'featured') {
      set({
        featuredProducts: ids,
      })
    }
    if (type === 'related') {
      set({
        relatedProducts: ids,
      })
    }
    set({
      recommendedProducts: ids,
    })
  },
}))
