import { HeaderContent } from './HeaderContent'
import * as styles from './styles'
import { useHeader } from './utils/useHeader'
import { NavigationContent } from '@lib/kontent/navigation-helpers/normalize-navigation-content'
import { cx } from '@linaria/core'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { useInView } from 'react-intersection-observer'

const navigationContentEN = require('../../../__generated__/navigation-en.json')
const navigationContentNL = require('../../../__generated__/navigation-nl.json')
const navigationContentDE = require('../../../__generated__/navigation-de.json')

export type Variant = 'absolute' | 'fixed'
export type Visibility = 'hidden' | 'visible' | null
export type Style = 'dark' | 'light'

type Props = {
  initialVariant?: Variant
  canHeaderShow?: boolean
  headerStyle?: Style
}

const localizedContent = {
  en: navigationContentEN as NavigationContent,
  nl: navigationContentNL as NavigationContent,
  de: navigationContentDE as NavigationContent,
}

const Header: FC<Props> = ({
  initialVariant = 'absolute',
  canHeaderShow,
  headerStyle = 'dark',
}) => {
  const {
    i18n: { language },
  } = useTranslation()
  const content: NavigationContent =
    localizedContent[language as 'en' | 'nl' | 'de'] ?? localizedContent.en

  const [topRef, topInView] = useInView()
  const [headerRef, headerInView] = useInView()
  const { variant, visibility } = useHeader({
    topInView,
    headerInView,
    canHeaderShow,
    initialVariant,
  })

  return (
    <>
      <HeaderContent
        variant={variant}
        visibility={visibility}
        headerStyle={headerStyle}
        content={content}
      />
      {initialVariant === 'absolute' && (
        <>
          <div className={cx(styles.refStyling, styles.scrollTopRef)} ref={topRef} />
          <div className={cx(styles.refStyling, styles.headerHeightRef)} ref={headerRef} />
        </>
      )}
    </>
  )
}
export default Header
