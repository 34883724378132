import { Box } from '../Layout'
import { rem } from 'polished'
import styled from 'styled-components'

export const FilterMenuFooter = styled(Box)`
  position: absolute;
  bottom: 0;
  z-index: 2;
  background: #fff;
  border-top: 1px solid ${(p) => p.theme.colors.shades[300]};
  width: 100%;
  left: 0;
  background: #fff;
  display: flex;
  align-items: center;
  height: ${rem(70)};
`
FilterMenuFooter.defaultProps = {
  px: '1.5rem',
}
