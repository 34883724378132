import { Text } from './Typography'
import { cn } from '@lib/cn'
import React, { AnchorHTMLAttributes, FC } from 'react'

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  image?: string
}

export const CategoryButton: FC<Props> = React.forwardRef<HTMLAnchorElement, Props>(
  ({ image, children, className, ...rest }, ref) => {
    return (
      <a
        className={cn(
          'flex h-12 items-center justify-center gap-4 whitespace-nowrap rounded-full px-4',
          'border border-shade-300 transition-colors duration-200',
          'hover:border-shade-800 focus:border-shade-800 md:h-16 md:px-6',
          className,
        )}
        ref={ref}
        {...rest}
      >
        {image && <img src={image} alt="" className="h-6" />}
        <Text variant="regular" mb={0}>
          {children}
        </Text>
      </a>
    )
  },
)
