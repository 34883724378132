import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { RefObject, useEffect } from 'react'

export function useBodyScrollLock(ref: RefObject<HTMLElement>, isOpen: boolean) {
  useEffect(() => {
    if (!ref.current) return
    if (isOpen) {
      disableBodyScroll(ref.current)
    } else {
      enableBodyScroll(ref.current)
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isOpen, ref])
}
