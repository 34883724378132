import { parse } from '@config/theme';
export const parent = parse({
  display: {
    _: 'flex',
    medium: 'grid'
  }
}, "pemhhry");
export const column = parse({
  mb: {
    _: '4',
    medium: '0'
  }
});

require("./Results.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Results.styles.ts");