// @ts-strict-ignore
import Cookies from 'js-cookie'
import { useEffect } from 'react'

type Props = {
  id: string
}

export const RECENTLY_VIEWED_STORAGE_KEY = 'recently_viewed_products'

const SaveRecentlyViewed = ({ id }: Props) => {
  const setRecentlyViewedProducts = (arr: string[]) => {
    Cookies.set(RECENTLY_VIEWED_STORAGE_KEY, arr, { expires: 365 })
  }

  useEffect(() => {
    const cookie = Cookies.get(RECENTLY_VIEWED_STORAGE_KEY)
    if (cookie) {
      const recentlyViewedProducts = cookie ? JSON.parse(cookie) : []
      // if the id already exists, remove it and add it to the front of the array
      if (recentlyViewedProducts.includes(id)) {
        setRecentlyViewedProducts([id, ...recentlyViewedProducts.filter((item) => item !== id)])
      } else {
        // if the length of the array is smaller than 5, add new id to front of array
        if (recentlyViewedProducts.length < 5) {
          setRecentlyViewedProducts([id, ...recentlyViewedProducts])
        } else {
          // if the length of the array is not smaller than 5, remove last element and add new id to front of array
          setRecentlyViewedProducts([id, ...recentlyViewedProducts.slice(0, 4)])
        }
      }
    } else {
      setRecentlyViewedProducts([id])
    }
  }, [id])
  return null
}

export default SaveRecentlyViewed
