import NextHead from 'next/head'
import { FC } from 'react'

export const Head: FC = () => {
  return (
    <NextHead>
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1.0, viewport-fit=cover"
      />
      <meta name="google-site-verification" content="bfGweToFCHLEBA35hao7JcbsV1MA3WL9uI8cFnjIDjQ" />
      <meta name="facebook-domain-verification" content="r0u2ffqnc9z4d3t2xd4fq7poqu2yst" />
    </NextHead>
  )
}
