import { Flex } from '../Layout'
import { linkStyles } from '../Typography/Text'
import { bodyRegularStyle } from '../Typography/styles'
import { InputStatusProp } from './styles'
import { rgba } from 'polished'
import React, { FC, InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { MarginBottomProps } from 'styled-system'

const Label = styled.label`
  ${bodyRegularStyle}
  transition: 0.2s ease-in-out all;
  font-size: 0.875rem;
  display: block;
  color: ${(p) => p.theme.colors.shades[700]};
  padding: 0.25rem 1rem 0.25rem 2rem;
  left: 0;

  &::before {
    transition: 0.2s ease-in-out all;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    content: '';
    left: 0;
    top: 0.25rem;
    border: 1px solid ${(p) => p.theme.colors.shades[400]};
    background: rgba(255, 255, 255.6);
  }

  &::after {
    transition: all 0.2s ease-in-out;
    background-image: url(/images/input-check.svg);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    display: flex;
    content: '';
    align-items: center;
    justify-content: center;
    width: 0.75rem;
    height: 0.75rem;
    top: 0.5rem;
    left: 0.25rem;
    opacity: 0;
  }

  a {
    ${linkStyles};
  }
`

const Input = styled.input<InputStatusProp>`
  opacity: 0;
  display: none;

  ${(p) =>
    p.disabled
      ? // Disabled state
        css`
          + ${Label} {
            cursor: not-allowed;
            color: ${p.theme.colors.shades[400]};
          }
          + ${Label}::before {
            border-color: ${p.theme.colors.shades[300]};
            background-color: ${p.theme.colors.shades[100]};
          }
        `
      : p.status === 'error'
      ? // Error state
        css`
          + ${Label} {
            cursor: pointer;
            color: ${p.theme.colors.error};
          }
          + ${Label}::before {
            border-color: ${p.theme.colors.error};
          }
          &:focus {
            + ${Label}::before {
              box-shadow: 0 0 0 3px ${(p) => rgba(p.theme.colors.error, 0.2)};
            }
          }
        `
      : // Default state
        css`
          &:hover {
            + ${Label} {
              cursor: pointer;
              color: ${(p) => p.theme.colors.shades[800]};
            }
            + ${Label}::before {
              border-color: ${p.theme.colors.shades[500]};
            }
          }
          &:focus {
            + ${Label}::before {
              box-shadow: 0 0 0 3px ${(p) => p.theme.colors.tabaccoLight};
            }
          }
        `};

  &:checked {
    + ${Label}::before {
      border-color: ${(p) => p.theme.colors.tabaccoBrown};
    }
    + ${Label}::after {
      opacity: 1;
    }
  }
`

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> &
  Pick<InputStatusProp, 'status'> &
  MarginBottomProps & {
    label: React.ReactNode
    id?: string
  }

export const Checkbox: FC<CheckboxProps> = ({ label, status, mb = '0', ...rest }) => {
  const id = rest.id || rest.name || 'checkbox'

  return (
    <Flex position="relative" mb={mb}>
      <Input type="checkbox" data-field-id={rest.name} id={id} {...rest} status={status}></Input>
      <Label htmlFor={id}>{label}</Label>
    </Flex>
  )
}
