// @ts-strict-ignore
export function genericPushToTrackingLayer(event: string, action?: string) {
  if (window['dataLayer']) {
    window['dataLayer'].push({ ecommerce: null })

    window['dataLayer'].push({
      event,
      action,
    })
  }
}
