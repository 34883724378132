// @ts-strict-ignore
import { MenuLabel } from '../MenuLabel';
import RecentlyViewed from './RecentlyViewed';
import Result from './Result';
import { LinkTranslated } from '@components/types';
import { parse } from '@config/theme';
import { getKontentImage } from '@lib/kontent';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
type Props = {};
const NoInput: FC<Props> = () => {
  const {
    t
  } = useTranslation(['dropdown', 'dropdown-items']);
  return <motion.div animate={{
    opacity: 1
  }} initial={{
    opacity: 0
  }} exit={{
    opacity: 0
  }}>
      <div className={parent}>
        <div className={first}>
          <MenuLabel>{t('search.popularBrands', {
            ns: 'dropdown'
          })}</MenuLabel>
          {t<string, LinkTranslated[]>('search.popularBrands', {
          ns: 'dropdown-items',
          returnObjects: true
        }).map(item => <Result key={item.urlSlug} variant="brand" href={`/${item.urlSlug}`} title={item.title} image={getKontentImage(item.image, {
          width: 100,
          height: 100
        })} />)}
        </div>
        <div className={second}>
          <RecentlyViewed />
        </div>
      </div>
    </motion.div>;
};
const parent = parse({
  display: {
    _: 'flex',
    medium: 'grid'
  }
}, "p16wjal8");
const first = "f1a9c9n8";
const second = "s1izifkl";
export default NoInput;

require("./NoInput.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./NoInput.tsx");