export function PersonalAdvice() {
  return (
    <>
      <path
        d="M35.9998 17.8125C35.9998 19.1358 35.6694 20.3823 35.0865 21.4746L35.7304 23.8844C35.8563 24.3559 35.4521 24.7995 34.9709 24.7178L32.5247 24.3022C31.2763 25.1376 29.7736 25.625 28.1566 25.625C23.825 25.625 20.3135 22.1272 20.3135 17.8125C20.3135 13.4978 23.825 10 28.1566 10C32.4883 10 35.9998 13.4978 35.9998 17.8125Z"
        fill="white"
        stroke="currentColor"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
      />
      <path
        d="M25.9604 19.375L32.235 19.375"
        stroke="currentColor"
        fill="none"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
      />
      <path
        d="M24.0786 16.875L32.2355 16.875"
        stroke="currentColor"
        fill="none"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
      />
      <path
        d="M4.00018 20C4.00018 21.6939 4.42298 23.2894 5.16919 24.6875L4.26957 28.0543C4.14359 28.5258 4.54786 28.9695 5.029 28.8877L8.44829 28.3069C10.0462 29.3761 11.9697 30 14.0394 30C19.5839 30 24.0786 25.5228 24.0786 20C24.0786 14.4772 19.5839 10 14.0394 10C8.49489 10 4.00018 14.4772 4.00018 20Z"
        fill="white"
        stroke="currentColor"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
      />
      <path
        d="M9.01953 22.5L15.9215 22.5"
        stroke="currentColor"
        fill="none"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
      />
      <path
        d="M9.01953 20L19.0587 20"
        stroke="currentColor"
        fill="none"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
      />
      <path
        d="M9.01953 17.5L19.0587 17.5"
        stroke="currentColor"
        fill="none"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
      />
    </>
  )
}
