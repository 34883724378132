import { Box } from '../Layout'
import { rem } from 'polished'
import styled, { css } from 'styled-components'

export const menuStyles = css`
  position: absolute;
  transform: translateY(100%);
  min-width: min-content;
  background: hsl(0, 0%, 100%);
  outline: none;
  border: 1px solid ${(p) => p.theme.colors.shades[200]};
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.09);
  z-index: ${(p) => p.theme.zIndices.popOver};
  min-width: ${rem(265)};
`

export const Menu = styled(Box)<{ variant: 'align-right' | 'align-left' }>`
  bottom: -0.5rem;
  ${menuStyles};

  ${(p) =>
    p.variant === 'align-right' &&
    `
  left: inherit;
  right: 0;`}
`

export const menuListStyles = css`
  white-space: nowrap;
  display: block;
  overflow-y: auto;
  max-height: ${rem(265)};
  -webkit-overflow-scrolling: touch;
`

export const MenuList = styled.ul`
  ${menuListStyles};
  padding-top: 0.5rem;
  padding-bottom: 1.25rem;
  li {
    cursor: pointer;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`

Menu.defaultProps = {
  variant: 'align-left',
}
