// @ts-strict-ignore
import { FC, useEffect } from 'react'

type Props = {}

const Debug: FC<Props> = () => {
  useEffect(() => {
    window['__DEBUG__'] = {
      stage: process.env.NEXT_PUBLIC_STAGE,
      env: process.env.NODE_ENV,
      version: process.env.NEXT_PUBLIC_COMMIT_SHA,
      apiUrl: process.env.NEXT_PUBLIC_API_URL,
      adyenOriginKey: process.env.NEXT_PUBLIC_ADYEN_ORIGIN_KEY,
      googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    }
  }, [])
  return null
}

export default Debug
