export function IconShapeOval() {
  return (
    <>
      <path
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
        d="M14 15l-4-2M16 11l-3-4M26 15l4-2M24 11l3-4M14 25l-4 2M16 29l-3 4M26 25l4 2M24 29l3 4M18.834 35a9.834 9.834 0 01-8.326-4.6v0A9.834 9.834 0 019 25.165V14.834c0-1.852.523-3.666 1.508-5.233v0A9.834 9.834 0 0118.834 5h2.332a9.834 9.834 0 018.326 4.6v0A9.834 9.834 0 0131 14.835v10.332a9.834 9.834 0 01-1.508 5.233v0A9.834 9.834 0 0121.166 35h-2.332z"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
        d="M20 30a5.9 5.9 0 01-5.246-3.201l-.072-.14A6.157 6.157 0 0114 23.843v-7.686c0-.98.234-1.945.682-2.816l.072-.14A5.9 5.9 0 0120 10v0a5.9 5.9 0 015.247 3.201l.071.14c.448.871.682 1.837.682 2.816v7.686c0 .98-.234 1.945-.682 2.816l-.072.14A5.9 5.9 0 0120 30v0z"
      ></path>
    </>
  )
}
