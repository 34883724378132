export function Wifi() {
  return (
    <>
      <mask id="path-2-inside-1" fill="transparent">
        <ellipse cx="20.0003" cy="24.584" rx="0.756144" ry="0.756143" />
      </mask>
      <path
        d="M19.7564 24.584C19.7564 24.4493 19.8656 24.3402 20.0003 24.3402V26.3402C20.9702 26.3402 21.7564 25.5539 21.7564 24.584H19.7564ZM20.0003 24.3402C20.135 24.3402 20.2441 24.4493 20.2441 24.584H18.2441C18.2441 25.5539 19.0304 26.3402 20.0003 26.3402V24.3402ZM20.2441 24.584C20.2441 24.7187 20.135 24.8279 20.0003 24.8279V22.8279C19.0304 22.8279 18.2441 23.6141 18.2441 24.584H20.2441ZM20.0003 24.8279C19.8656 24.8279 19.7564 24.7187 19.7564 24.584H21.7564C21.7564 23.6141 20.9702 22.8279 20.0003 22.8279V24.8279Z"
        fill="currentColor"
        mask="url(#path-2-inside-1)"
      />
      <circle cx="20.0037" cy="24.584" r="0.324062" stroke="currentColor" />
      <path
        d="M14.707 19.7069C17.791 17.1381 22.2695 17.1381 25.3535 19.7069"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M12 17.0226C16.572 12.9925 23.428 12.9925 28 17.0226"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M17.376 22.3987C18.9494 21.2809 21.0578 21.2809 22.6312 22.3987"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
        strokeLinejoin="round"
      />
    </>
  )
}
