export function ChevronLeft() {
  return (
    <path
      d="M22 13C21.4545 15.6923 16 20 16 20C16 20 21.4545 24.3077 22 27"
      stroke="currentColor"
      fill="none"
      className="dynamic-stroke-width"
    />
  )
}
