export const initialValues = {
  customer: {
    email: '',
    phone: '',
    createAccount: true,
    password: '',
    billingAddressSame: true,
    deliveryAddress: {
      gender: 'male',
      firstName: '',
      lastName: '',
      postalCode: '',
      city: '',
      street: '',
      companyName: '',
      country: 'NL',
      countryOption: 'netherlands',
      state: '',
      houseNumber: '',
      houseNumberSuffix: '',
    },
    billingAddress: {
      gender: 'male',
      firstName: '',
      lastName: '',
      postalCode: '',
      city: '',
      street: '',
      companyName: '',
      country: 'NL',
      countryOption: 'netherlands',
      state: '',
      houseNumber: '',
      houseNumberSuffix: '',
    },
  },
  orderMeta: {
    wrapping: {
      wrapping: null,
      text: '',
    },
    newsletterSignup: false,
    message: '',
    delivery: null,
  },
  acceptTermsAndConditions: false,
}
