export function AccountAddress() {
  return (
    <>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11 11h5.667v0a3.778 3.778 0 013.777 3.778V28a2.833 2.833 0 00-2.833-2.833H11V11z"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M29.889 11h-5.667v0a3.778 3.778 0 00-3.778 3.778V28a2.833 2.833 0 012.834-2.833h6.61V11z"
      ></path>
    </>
  )
}
