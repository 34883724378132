import { FSMVariant } from '..'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export function useFloatingServiceMenu(variant: FSMVariant, showBackToTop: boolean) {
  const { route } = useRouter()
  const [state, setState] = useState<'open' | 'closed'>('closed')
  const [enoughPixelsScrolled, setEnoughPixelsScrolled] = useState(false)
  const showElement = variant === 'appear-on-scroll' ? enoughPixelsScrolled : true

  const debouncedScrollHandler = useDebouncedCallback(() => {
    const currentPos = window.pageYOffset || document.documentElement.scrollTop
    if (currentPos > window.innerHeight / 8) {
      setEnoughPixelsScrolled(true)
    } else if (currentPos < window.innerHeight / 8) {
      setEnoughPixelsScrolled(false)
    }
  }, 150)

  useEffect(() => {
    setState('closed')
  }, [route])

  useEffect(() => {
    window.addEventListener('scroll', debouncedScrollHandler, false)
    return () => {
      window.removeEventListener('scroll', debouncedScrollHandler, false)
    }
  }, [debouncedScrollHandler])

  function getBottomPosition() {
    if (showBackToTop) {
      return 'calc(env(safe-area-inset-bottom) + 4.5rem)'
    } else {
      switch (variant) {
        case 'move-element-up':
          return [
            `calc(env(safe-area-inset-bottom) + 2.5rem)`,
            `calc(env(safe-area-inset-bottom) + 3.5rem)`,
          ]
        case 'move-element-up-product-page':
          return [
            `calc(env(safe-area-inset-bottom) + 3.5rem)`,
            `calc(env(safe-area-inset-bottom) + 4.5rem)`,
          ]
        default:
          return 'env(safe-area-inset-bottom)'
      }
    }
  }

  function onClickBackToTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  return {
    state,
    setState,
    showElement,
    bottomPosition: getBottomPosition(),
    onClickBackToTop,
  }
}
