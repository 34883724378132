// @ts-strict-ignore
import { cn } from '@lib/cn'
import { FC } from 'react'

type Props = {
  active: number
  total: number
  onClickItem?: (index: number) => void
}

export const SliderIndicators: FC<Props> = ({ active, total, onClickItem }) => {
  return (
    <>
      {[...Array.from(new Array(total))].map((_, index) => (
        <button
          key={index}
          className={cn(
            'h-5 w-5 border-none outline-none',
            active === index ? 'text-shade-800' : 'text-shade-400',
          )}
          onClick={() => onClickItem(index)}
        >
          <span
            className={cn(
              'mr-2 block h-1 w-1 rotate-45 bg-current transition-colors duration-200',
              'sm:mr-3 sm:h-[5px] sm:w-[5px]',
            )}
          />
        </button>
      ))}
    </>
  )
}
