import * as clientCookie from 'js-cookie'

export type Options = 'personal:rolex' | 'personal' | 'analytical' | 'functional'

const COOKIE_KEY = 'cookie_settings_08_22'

export const updateCookieSettings = (options: Options[]) => {
  const settings = {
    options,
    acceptedAt: new Date(),
  }

  clientCookie.set(COOKIE_KEY, JSON.stringify(settings), { expires: 365 })
}

export const acceptAllCookies = () => {
  updateCookieSettings(['personal:rolex', 'personal', 'analytical', 'functional'])
}

export const hasCookieConsent = (): boolean => {
  return !!clientCookie.get(COOKIE_KEY)
}

export const getCookieSettings = (): Options[] => {
  let cookieSettings
  const rawSettings = clientCookie.get(COOKIE_KEY)
  cookieSettings = rawSettings && JSON.parse(rawSettings)

  return cookieSettings && cookieSettings.options ? cookieSettings.options : ['functional']
}
