import { breakpoints } from '../theme'
import { InputStatusProp, baseStyles } from './styles'
import { ChangeEvent, FC, TextareaHTMLAttributes, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { minHeight } from 'styled-system'

const StyledTextarea = styled.textarea<InputStatusProp>`
  ${baseStyles};
  ${minHeight};
  line-height: 1.4;
  padding: 0 0.75rem 3rem;
  & + label {
    top: 1rem;
    color: ${(p) => p.theme.colors.shades[500]};
  }

  &:focus,
  &.filled {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    & + label {
      transform: translateY(-0.3rem);
      font-size: 0.625rem;
    }
  }

  &:focus + label {
    color: ${(p) => p.theme.colors.tabaccoBrown};
  }

  ${(p) =>
    p.status === 'error' &&
    css`
      &.filled + label {
        color: ${p.theme.colors.error};
      }
    `};

  @media screen and (min-width: ${breakpoints.small}) {
    padding: 0 1rem 3rem 1rem;
  }
`

export type TextareaProps = InputStatusProp &
  TextareaHTMLAttributes<HTMLTextAreaElement> & {
    autosize?: boolean
    minRows?: number
    maxRows?: number
  }

export const Textarea: FC<TextareaProps> = ({
  autosize,
  minRows = 5,
  maxRows,
  onChange,
  value,
  ...rest
}) => {
  const lineHeight = 23
  const ref = useRef<HTMLTextAreaElement | null>(null)
  const [rows, setRows] = useState(minRows || 5)
  const isFilled = typeof value !== 'undefined' && value.toString().length > 0

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (autosize && e.target) {
      const newRows = Math.round(e.target.scrollHeight / lineHeight)
      if (newRows > e.target.rows) {
        if (maxRows) {
          if (newRows < maxRows) {
            setRows(newRows)
          }
        } else {
          setRows(newRows)
        }
      }
    }

    onChange && onChange(e)
  }
  return (
    <StyledTextarea
      rows={autosize ? rows : minRows ? minRows : undefined}
      onChange={handleChange}
      ref={ref}
      value={value}
      className={isFilled ? 'filled' : undefined}
      data-field-id={rest.name}
      {...rest}
    />
  )
}
