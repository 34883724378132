import { useMembershipModal } from '@lib/hooks/use-membership-modal'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'

export function useMembershipModalStatus() {
  const { showMembershipVariant, setShowMembershipModalVariant } = useMembershipModal()
  const { pathname } = useRouter()
  const handleRequestClose = useCallback(
    () => setShowMembershipModalVariant(null),
    [], //eslint-disable-line
  )

  useEffect(() => {
    handleRequestClose()
  }, [pathname, handleRequestClose])

  return {
    showMembershipVariant,
    handleRequestClose,
  }
}
