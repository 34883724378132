export const getSubrowAnimation = ({
  index,
  active,
  height,
  margin,
}: {
  index: number
  active: number | null
  height: number
  margin: number
}) => {
  if (active === null) {
    return {
      animate: { y: 0, opacity: 1 },
      transition: { ease: 'easeOut', duration: 0.2 },
    }
  }
  if (index === active) {
    return {
      animate: { y: 0 - index * height - margin, opacity: 1 },
      transition: { ease: 'easeOut', duration: 0.2 },
    }
  } else {
    return {
      animate: { opacity: 0 },
      transition: { ease: 'easeOut', duration: 0.2 },
    }
  }
}
