export function ChevronRight() {
  return (
    <path
      d="M18 27C18.5455 24.3077 24 20 24 20C24 20 18.5455 15.6923 18 13"
      stroke="currentColor"
      className="dynamic-stroke-width"
      fill="none"
    />
  )
}
