export function Lock() {
  return (
    <>
      <g className="hide-on-small">
        <path
          d="M13.3333 17.6804C13.3333 18.2326 13.781 18.6804 14.3333 18.6804C14.8856 18.6804 15.3333 18.2326 15.3333 17.6804H13.3333ZM24.6667 17.6804C24.6667 18.2326 25.1144 18.6804 25.6667 18.6804C26.219 18.6804 26.6667 18.2326 26.6667 17.6804H24.6667ZM28.5 29.55V30.55C29.0523 30.55 29.5 30.1023 29.5 29.55H28.5ZM11.5 29.55H10.5C10.5 30.1023 10.9477 30.55 11.5 30.55V29.55ZM15.3333 15.5857C15.3333 13.0666 17.4091 11 20 11V9C16.3317 9 13.3333 11.935 13.3333 15.5857H15.3333ZM20 11C22.5909 11 24.6667 13.0666 24.6667 15.5857H26.6667C26.6667 11.935 23.6683 9 20 9V11ZM13.3333 15.5857V17.6804H15.3333V15.5857H13.3333ZM24.6667 15.5857V17.6804H26.6667V15.5857H24.6667ZM13.5 19.3786H26.5V17.3786H13.5V19.3786ZM27.5 20.3786V29.55H29.5V20.3786H27.5ZM28.5 28.55H11.5V30.55H28.5V28.55ZM12.5 29.55V20.3786H10.5V29.55H12.5ZM26.5 19.3786C27.0523 19.3786 27.5 19.8263 27.5 20.3786H29.5C29.5 18.7217 28.1569 17.3786 26.5 17.3786V19.3786ZM13.5 17.3786C11.8431 17.3786 10.5 18.7217 10.5 20.3786H12.5C12.5 19.8263 12.9477 19.3786 13.5 19.3786V17.3786Z"
          fill="currentColor"
        />
        <circle cx="20.0004" cy="23.9643" r="1.21429" fill="currentColor" />
      </g>
      <g className="show-on-small">
        <path
          d="M12.2917 17.1004C12.2917 17.4456 12.5715 17.7254 12.9167 17.7254C13.2618 17.7254 13.5417 17.4456 13.5417 17.1004H12.2917ZM26.4583 17.1004C26.4583 17.4456 26.7382 17.7254 27.0833 17.7254C27.4285 17.7254 27.7083 17.4456 27.7083 17.1004H26.4583ZM30.625 31.9375V32.5625C30.9702 32.5625 31.25 32.2827 31.25 31.9375H30.625ZM9.375 31.9375H8.75C8.75 32.2827 9.02982 32.5625 9.375 32.5625V31.9375ZM13.5417 14.4821C13.5417 10.9796 16.4247 8.125 20 8.125V6.875C15.7513 6.875 12.2917 10.2724 12.2917 14.4821H13.5417ZM20 8.125C23.5753 8.125 26.4583 10.9796 26.4583 14.4821H27.7083C27.7083 10.2724 24.2487 6.875 20 6.875V8.125ZM12.2917 14.4821V17.1004H13.5417V14.4821H12.2917ZM26.4583 14.4821V17.1004H27.7083V14.4821H26.4583ZM11.875 18.5982H28.125V17.3482H11.875V18.5982ZM30 20.4732V31.9375H31.25V20.4732H30ZM30.625 31.3125H9.375V32.5625H30.625V31.3125ZM10 31.9375V20.4732H8.75V31.9375H10ZM28.125 18.5982C29.1605 18.5982 30 19.4377 30 20.4732H31.25C31.25 18.7473 29.8509 17.3482 28.125 17.3482V18.5982ZM11.875 17.3482C10.1491 17.3482 8.75 18.7473 8.75 20.4732H10C10 19.4377 10.8395 18.5982 11.875 18.5982V17.3482Z"
          fill="currentColor"
        />
        <circle cx="20.0003" cy="24.9554" r="1.51786" fill="currentColor" />
      </g>
    </>
  )
}
