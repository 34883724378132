import { parse } from '@config/theme';
export const title = parse({
  mb: {
    _: '8',
    medium: '12'
  }
}, "t14qopvz");
export const grid = parse({
  display: {
    _: 'flex',
    medium: 'grid'
  }
}, "gu9x1wl");
export const column = parse({
  mb: {
    _: '8',
    medium: '0'
  }
});
export const firstColumn = "f7q29p6";
export const secondColumn = "stxcee1";

require("./NoResults.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./NoResults.styles.ts");