export function IconStore() {
  return (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.295 7.743c0-.82.664-1.486 1.484-1.486H25.22c.82 0 1.483.665 1.483 1.486V18.19h5.537v2.673l-.851 1.279v11.6H8.61v-11.6l-.852-1.279v-2.673h5.537V7.743zm1.484 10.448H25.22V7.743H14.779V18.19zm-4.685 4.47v9.596h6.182v-3.561c0-.99.393-1.938 1.091-2.637a3.72 3.72 0 015.265 0 3.73 3.73 0 011.091 2.637v3.56h6.183v-9.595H10.094zm20.157-1.486l.507-.76v-.738H9.242v.737l.507.761H30.25zm-8.012 11.082v-3.561a2.238 2.238 0 10-4.479 0v3.56h4.48zm-3.091-21.104h-1.704V9.667h1.704v1.486zm3.407 0h-1.703V9.667h1.703v1.486zm-3.407 2.557h-1.704v-1.486h1.704v1.486zm3.407 0h-1.703v-1.486h1.703v1.486zm-3.407 2.557h-1.704v-1.485h1.704v1.485zm3.407 0h-1.703v-1.485h1.703v1.485z"
        clipRule="evenodd"
        className="hide-on-small"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.779 7.371a.371.371 0 00-.371.372v10.82h11.184V7.743a.371.371 0 00-.371-.372H14.779zm11.555 11.192V7.743c0-.616-.499-1.114-1.113-1.114H14.779c-.615 0-1.113.498-1.113 1.114v10.82H8.129v2.189l.852 1.278v11.341h22.038v-11.34l.852-1.28v-2.188h-5.537zm4.115 2.984l.68-1.02v-1.221H8.87v1.22l.68 1.02h20.898zm-20.726.742v10.34h6.924v-3.933c0-.89.354-1.745.983-2.374a3.35 3.35 0 014.74 0c.629.63.982 1.483.982 2.374v3.932h6.925V22.29H9.723zM22.61 32.63v-3.933a2.608 2.608 0 10-5.22 0v3.932h5.22zM19.148 10.78h-1.704v-.743h1.704v.743zm3.407 0h-1.703v-.743h1.703v.743zm-3.407 2.558h-1.704v-.743h1.704v.743zm3.407 0h-1.703v-.743h1.703v.743zm-3.407 2.557h-1.704v-.743h1.704v.743zm3.407 0h-1.703v-.743h1.703v.743z"
        clipRule="evenodd"
        className="show-on-small"
      ></path>
    </>
  )
}
