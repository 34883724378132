import { AnimatePresence, motion } from 'framer-motion'
import { FC } from 'react'

interface Props {}

const PageTransition: FC<Props> = ({ children }) => {
  return (
    <AnimatePresence initial={false}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: 'easeInOut', duration: 0.3 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default PageTransition
