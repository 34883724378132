import styled from 'styled-components'
import { SizeProps, SpaceProps, size, space } from 'styled-system'

interface Props extends SpaceProps, SizeProps {}

export const CleanButton = styled.button<Props>`
  ${size};
  ${space}
  background: none;
  outline: 0;
  border: 0;
`

CleanButton.defaultProps = {
  m: 0,
}
