export function ChevronDown() {
  return (
    <path
      d="M13 17C15.6923 17.5455 20 23 20 23C20 23 24.3077 17.5455 27 17"
      stroke="currentColor"
      className="dynamic-stroke-width"
      fill="none"
    />
  )
}
