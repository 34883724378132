import { parse } from '@config/theme';
export const linkText = parse({
  fontFamily: 'body',
  color: 'shade800',
  position: 'relative',
  display: 'inline-block',
  mb: '0'
}, "lxqx5bw");
export const link = parse({
  display: 'grid',
  alignItems: 'center',
  mb: {
    _: '3'
  }
}, "lg0c3c9");
export const parent = parse({
  height: '100%',
  position: 'relative',
  display: 'grid',
  width: '100%',
  py: '2'
}, "p168ti5r");
export const scrollIndicator = parse({
  width: '100%',
  position: 'absolute'
}, "saxlbmk");
export const mobileImage = parse({
  display: {
    _: 'flex',
    large: 'none'
  },
  justifyContent: 'center',
  alignItems: 'center'
}, "msq9rzf");
export const readMore = parse({}, "r6pbtc2");
export const desktopImage = parse({
  display: {
    _: 'none',
    large: 'flex'
  },
  justifyContent: 'center',
  alignItems: 'center'
}, "d11x8ucs");
export const list = parse({
  mb: '4',
  pb: '2',
  height: '100%'
}, "l1xiuu3o");

require("./styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");