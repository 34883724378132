// @ts-strict-ignore
import { HeaderSearchHeader } from './Header'
import ResultsList from './ResultsList'
import SearchInput from './SearchInput'
import * as styles from './styles'
import { Icon } from '@gassan-ui'
import { getAlgoliaClient } from '@lib/algolia'
import { useBodyScrollLock } from '@lib/hooks/useBodyScrollLock'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useRef, useState } from 'react'

const client = getAlgoliaClient()

type Props = {
  onRequestClose: () => void
}
export type IndexItem = {
  objectID: string
  title: string
  productGroup: string
  categories: string[]
  image: string
  brand?: string
  urlSlug?: string
  category?: string
  price?: number
}

export type ResultsState = {
  totalProducts: number
  products: IndexItem[]
  brands: IndexItem[]
  categories: IndexItem[]
  status: 'searching' | 'idle'
}

const Search: FC<Props> = ({ onRequestClose }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['dropdown', 'other'])
  const [query, setQuery] = useState('')
  const timer = useRef<NodeJS.Timer>()
  const [isMounted, setIsMounted] = useState(false)
  const ref = useRef<HTMLDivElement>()
  const [results, setResults] = useState<ResultsState>({
    totalProducts: 0,
    products: [],
    brands: [],
    categories: [],
    status: 'idle',
  })

  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  useBodyScrollLock(ref, isMounted)

  useEffect(() => {
    async function search() {
      clearTimeout(timer.current)
      setResults((state) => ({ ...state, status: 'searching' }))
      const queries = [
        {
          indexName: `products_${language}`,
          query,
          params: {
            hitsPerPage: 4,
          },
        },
        {
          indexName: `categories_${language}`,
          query,
          params: {
            hitsPerPage: 4,
          },
        },
        {
          indexName: `brands_${language}`,
          query,
          params: {
            hitsPerPage: 4,
          },
        },
      ]

      const { results } = await client.multipleQueries<IndexItem>(queries)
      timer.current = setTimeout(() => {
        setResults({
          totalProducts: results[0].nbHits,
          products: results[0].hits as IndexItem[],
          categories: results[1].hits as IndexItem[],
          brands: results[2].hits as IndexItem[],
          status: 'idle',
        })
      }, 300)
    }

    if (query.length) {
      search()
    }

    return () => {
      clearTimeout(timer.current)
    }
  }, [query]) // eslint-disable-line

  return (
    <div className={styles.parent}>
      <HeaderSearchHeader onClickClose={onRequestClose}>
        {t('search.search', { ns: 'dropdown' })}
      </HeaderSearchHeader>
      <div className={styles.scrollContainer} ref={ref}>
        <div className={styles.content}>
          <div className={styles.buttonParent}>
            <button className={styles.closeButton} onClick={onRequestClose}>
              {t('closeSearch', { ns: 'other' })}
              <Icon icon="cross" size={24} />
            </button>
          </div>
          <SearchInput
            onChange={setQuery}
            placeholder={t('whatAreYouLookingFor', { ns: 'other' })}
          />
          <ResultsList query={query} results={results}></ResultsList>
        </div>
      </div>
    </div>
  )
}

export default Search
