export function Profile() {
  return (
    <>
      <circle
        cx="20"
        cy="13.75"
        r="3.75"
        fill="none"
        stroke="currentColor"
        className="dynamic-stroke-width"
      />
      <path
        className="dynamic-stroke-width"
        fill="none"
        d="M22.5 21.25H17.5C14.0482 21.25 11.25 24.0482 11.25 27.5V27.75C11.25 27.75 15 28.75 20 28.75C25 28.75 28.75 27.75 28.75 27.75V27.5C28.75 24.0482 25.9518 21.25 22.5 21.25Z"
        stroke="currentColor"
      />
    </>
  )
}
