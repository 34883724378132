import { parse } from '@config/theme';
export const wrap = parse({
  display: 'flex',
  position: 'relative',
  width: '100%'
}, "wfynxx0");
export const parent = parse({
  position: 'absolute',
  height: '100%',
  width: '100%'
}, "p9ab79o");
export const hoverContent = parse({
  position: 'absolute',
  width: '100%',
  display: 'flex',
  textAlign: 'left',
  alignItems: 'flex-start'
}, "hen6gd6");
export const title = parse({
  mb: '0'
}, "t1mcx7d3");
export const content = parse({
  position: 'absolute',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  py: {
    _: '2',
    small: '4',
    large: '6'
  }
}, "c1gq5g3t");

require("./styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");