export function AccountOrderLarge() {
  return (
    <>
      <path
        fill="currentColor"
        className="show-on-small"
        fillRule="evenodd"
        d="M20 10.574l9.375 3.86V25.03L20 29.441l-9.375-4.412V14.434L20 10.574zm-2.782 2.497l7.149 3.154 2.834-1.334L20 11.926l-2.782 1.145zm10.907 2.767L25 17.308v4.494a.625.625 0 11-1.25 0v-3.905l-3.125 1.47v8.398l7.5-3.53v-8.397zm-8.75 11.927v-8.398l-7.5-3.53v8.399l7.5 3.53zm-6.576-12.874L20 18.28l2.868-1.35-7.251-3.2-2.818 1.161z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        className="hide-on-small"
        fillRule="evenodd"
        d="M20 6.919l13 5.353v14.716l-13 6.117-13-6.117V12.272l13-5.353zm-3.45 3.583l9.437 4.164 3.535-1.664L20 9.082l-3.45 1.42zM31 14.517l-4 1.882v6.072a1 1 0 11-2 0v-5.13l-4 1.882v11.201l10-4.706V14.517zM19 30.424V19.223L9 14.517v11.201l10 4.706zm-8.522-17.422L20 17.483l3.59-1.689-9.604-4.236-3.508 1.444z"
        clipRule="evenodd"
      ></path>
    </>
  )
}
