export function Globe() {
  return (
    <>
      <path
        d="M25.6569 14.3431C28.781 17.4673 28.781 22.5327 25.6569 25.6569C22.5327 28.781 17.4673 28.781 14.3431 25.6569C11.219 22.5327 11.219 17.4673 14.3431 14.3431C17.4673 11.219 22.5327 11.219 25.6569 14.3431"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M12 20H28"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M19.9998 12V12C22.0008 14.1907 23.138 17.0336 23.1998 20V20C23.138 22.9664 22.0008 25.8093 19.9998 28H19.9998C17.9988 25.8093 16.8616 22.9664 16.7998 20L16.7998 20C16.8616 17.0336 17.9988 14.1907 19.9998 12L19.9998 12Z"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
        strokeLinejoin="round"
      />
    </>
  )
}
