export function NoImage() {
  return (
    <>
      <path
        fill="#B2B1A5"
        fillRule="evenodd"
        d="M1.659.293L34.63 33.265h3.035V9.277H28.94l-2.934-2.934H15.558l-2.651 2.651-1.366-1.366 3.217-3.217h12.048l2.934 2.935h9.858v27.85h-3.036l3.731 3.731-1.366 1.366-5.096-5.097H.987V7.346H5.98L.293 1.659 1.659.293zM7.91 9.277H2.92v23.988h28.979L7.91 9.277z"
        clipRule="evenodd"
        className="hide-on-small"
      ></path>
      <path
        fill="#B2B1A5"
        fillRule="evenodd"
        d="M21.253 14.152a7.073 7.073 0 00-2.874.605l-.81-1.828a9.101 9.101 0 0111.988 12.056l-1.824-.821a7.101 7.101 0 00-6.48-10.012zm-5.157 2.22A7.101 7.101 0 0026.135 26.41l1.375 1.451a9.101 9.101 0 01-12.866-12.866l1.452 1.375z"
        clipRule="evenodd"
        className="hide-on-small"
      ></path>
      <path
        fill="#B2B1A5"
        className="show-on-small"
        fillRule="evenodd"
        d="M1.341.646L35.03 34.335h3.783V8.948h-9.166l-2.985-2.985H15.626l-2.841 2.841-.695-.695 3.129-3.129h11.85l2.985 2.986h9.741v27.352h-3.783l4.635 4.634-.695.695-5.33-5.33H1.499V7.967H7.27L.646 1.34l.695-.695zm6.912 8.302H2.48v25.387h31.16L8.253 8.948z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#B2B1A5"
        fillRule="evenodd"
        className="show-on-small"
        d="M21.253 13.652a7.574 7.574 0 00-3.077.648l-.405-.914a8.601 8.601 0 0111.33 11.393l-.912-.41c.427-.95.665-2.005.665-3.116a7.601 7.601 0 00-7.6-7.601zm-5.52 2.375A7.601 7.601 0 0026.48 26.773l.688.726a8.601 8.601 0 01-12.16-12.16l.726.688z"
        clipRule="evenodd"
      ></path>
    </>
  )
}
