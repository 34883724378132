export function FreeDelivery() {
  return (
    <>
      <path
        d="M28.7764 26.6852H12.6274V21.0556M28.7764 18.5926V10.5H12.6274V13.3148M12.6274 16.8333V18.2407"
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinejoin="round"
      />
      <path
        d="M28.7764 26.6852H36.4998V20.7917L33.1897 12.6111H28.7764V18.5926"
        stroke="currentColor"
        fill="none"
        strokeLinejoin="round"
        className="dynamic-stroke-width"
      />
      <path
        d="M19.3976 24.8796C20.4522 25.9366 20.4522 27.6503 19.3976 28.7073C18.343 29.7643 16.6332 29.7643 15.5786 28.7073C14.5239 27.6503 14.5239 25.9366 15.5786 24.8796C16.6332 23.8227 18.343 23.8227 19.3976 24.8796"
        fill="white"
      />
      <path
        d="M19.3976 24.8796C20.4522 25.9366 20.4522 27.6503 19.3976 28.7073C18.343 29.7643 16.6332 29.7643 15.5786 28.7073C14.5239 27.6503 14.5239 25.9366 15.5786 24.8796C16.6332 23.8227 18.343 23.8227 19.3976 24.8796"
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinejoin="round"
      />
      <path
        d="M33.4406 24.8796C34.4952 25.9366 34.4952 27.6503 33.4406 28.7073C32.386 29.7643 30.6761 29.7643 29.6215 28.7073C28.5669 27.6503 28.5669 25.9366 29.6215 24.8796C30.6761 23.8227 32.386 23.8227 33.4406 24.8796Z"
        fill="white"
        stroke="currentColor"
        className="dynamic-stroke-width"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 15.0741H17.1915"
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
      />
      <path
        d="M5.60645 19.6482L18.9469 19.6482"
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
      />
    </>
  )
}
