export function IconAccountTrends() {
  return (
    <>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22.182 17.909s.944-5.577-2.91-8c0 1.696-.916 3.489-2.563 4.942-1.646 1.454-4.741 4.716-4.709 8.204.026 2.791 1.535 6.2 5.005 7.45.013-3.32 2.86-4.7 2.995-8.005 2.092 1.968 2.984 4.789 3 7.957 2.507-.923 4.665-3.071 4.972-6.756.3-3.586-1.746-6.668-3.007-8.2"
      ></path>
    </>
  )
}
