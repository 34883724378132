// @ts-strict-ignore
import * as StyledComponents from 'styled-components'

export const breakpoints = {
  small: '40em', // 640px
  medium: '48em', // 768px
  large: '64em', // 1024px
  xlarge: '90em', // 1440px
  xxlarge: '97em', // 1552px
  huge: '120em', // 1920px,
}

const themeBreakpoints = Object.values(breakpoints)
themeBreakpoints['small'] = breakpoints.small
themeBreakpoints['medium'] = breakpoints.medium
themeBreakpoints['large'] = breakpoints.large
themeBreakpoints['xlarge'] = breakpoints.xlarge
themeBreakpoints['xxlarge'] = breakpoints.xxlarge
themeBreakpoints['huge'] = breakpoints.huge

export const theme = {
  breakpoints: themeBreakpoints,
  colors: {
    bigStone: '#182F40',
    bigStoneLight: '#2D4353',
    oldLace: '#FCF0E4',
    oldLaceDark: '#F5E3D0',
    pampas: '#F0EBE8',
    pampasLight: '#F7F5F3',
    eagle: '#B2B1A5',
    tabaccoBrown: '#785D42',
    tabaccoLight: '#D7CEC6',
    error: '#CB3333',
    errorLight: '#EFC2C2',
    success: '#4CAF50',
    white: '#ffffff',
    shades: {
      50: '#FAFAFA',
      100: '#F7F7F7',
      200: '#F0F0F0',
      300: '#D9D9D9',
      400: '#BDBDBD',
      500: '#909090',
      600: '#545454',
      700: '#2E2E2E',
      800: '#141414',
    },
    tudorRed: '#be0100',
    tudorText: '#151515',
    tudorShade100: '#f5f5f5',
    tudorShade800: '#141416',
  },
  fonts: {
    heading: 'Louize Display, serif',
    body: 'Graphik, Arial, sans-serif',
    specials: 'Artegra Sans Extended, Arial, sans-serif',
    quote: 'Quentin, serif',
  },
  zIndices: {
    fullscreenGallery: 30,
    notifications: 99,
    cookieNotice: 14,
    quickCart: 22,
    popOver: 10,
    dropdown: 1,
    floatingServiceMenu: 18,
    header: {
      backdropFullScreen: 99,
      backdrop: 29,
      default: 22,
      opened: 40,
    },
    mobileFilters: {
      confirmButton: 25,
      buttons: 24,
      backdrop: 22,
      overlay: 23,
    },
  },
  rolex: {
    colors: {
      primary: '#127749',
      secondary: '#cbb484',
      red: '#c00',
      shades: {
        100: '#f6f6f6',
        200: '#f0f0f0',
        300: '#e6e6e6',
        500: '#a0a0a0',
        700: '#767676',
        900: '#212121',
      },
    },
  },
}

export type Theme = typeof theme

export type BaseProps = React.RefAttributes<any> & {
  as?: React.ElementType
  css?: StyledComponents.CSSObject | StyledComponents.FlattenSimpleInterpolation | string
}
