import { Icon } from '../Icon'
import { Spinner } from '../Spinner'
import { cn } from '@lib/cn'
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from 'react'

type ButtonVariant = 'light' | 'dark' | 'outline' | 'rolex' | 'outline-red'
type BaseButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: ButtonVariant
  status?: 'loading' | 'finished' | 'disabled' | 'idle'
  iconLeft?: React.ReactNode
}

type ButtonType = ButtonHTMLAttributes<HTMLButtonElement> & {
  as?: 'button'
}
type LinkType = AnchorHTMLAttributes<HTMLAnchorElement> & {
  as?: 'link'
  disabled?: boolean
}

type ButtonAs = LinkType | ButtonType

type ButtonProps = BaseButtonProps & ButtonAs

export const Button: FC<ButtonProps> = React.forwardRef(
  (
    {
      variant = 'light',
      status = 'idle',
      children,
      disabled,
      as = 'button',
      iconLeft,
      className,
      ...rest
    },
    outerRef,
  ) => {
    const Comp: any = as === 'link' ? 'a' : 'button'

    // @ts-ignore
    if (process.env.NODE_ENV === 'development' && disabled) {
      console.error(
        `The 'disabled' prop is given, this won't be added to the button. Please use 'status="disabled" instead.'`,
      )
    }
    return (
      <Comp
        {...rest}
        ref={outerRef}
        variant={variant}
        disabled={['loading', 'finished', 'disabled'].includes(status)}
        className={cn(
          'relative inline-flex h-12 items-center justify-center px-8 font-special text-[9px] uppercase tracking-[0.28em] outline-none transition-all duration-200 ease-in-out',
          'md:h-14 md:px-10 md:text-[11px]',
          'disabled:cursor-not-allowed',
          'data-[status=loading]:cursor-wait',
          variants[variant],
          className,
        )}
        data-status={status}
      >
        <span
          className="flex items-center text-[inherit]"
          style={{
            opacity: ['loading', 'finished'].includes(status) ? 0 : 1,
          }}
        >
          {iconLeft && <span className="mr-4 block">{iconLeft}</span>}
          {children}
        </span>
        {status === 'loading' && (
          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <Spinner size="1.25rem" />
          </span>
        )}
        {status === 'finished' && (
          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <Icon icon="check" />
          </span>
        )}
      </Comp>
    )
  },
)

const variants: Record<ButtonVariant, string> = {
  light: `
    bg-oldLace text-bigStone
    data-[status=idle]:hover:bg-oldLaceDark data-[status=idle]:focus:bg-oldLaceDark data-[status=idle]:focus:shadow  shadow-oldLaceDark/20
    data-[status=disabled]:opacity-40
    `,
  dark: `
    bg-bigStone text-white
    data-[status=idle]:hover:bg-bigStoneLight data-[status=idle]:focus:bg-bigStoneLight data-[status=idle]:focus:shadow  shadow-bigStoneLight/20
    data-[status=disabled]:opacity-40
  `,
  rolex: `
    bg-rolexPrimary text-white rounded-full
    data-[status=idle]:hover:bg-white data-[status=idle]:hover:text-rolexPrimary data-[status=idle]:focus:bg-white data-[status=idle]:focus:shadow  shadow-rolexPrimary/20
    data-[status=disabled]:opacity-40
  `,
  outline: `
    bg-white border border-bigStone text-bigStone
    data-[status=idle]:hover:border-bigStoneLight data-[status=idle]:focus:border-bigStoneLight data-[status=idle]:focus:text-bigStoneLight
    data-[status=disabled]:opacity-40
  `,
  'outline-red': `
    bg-white border border-error text-error
    data-[status=idle]:hover:border-error/50 data-[status=idle]:focus:border-error/50 data-[status=idle]:focus:text-border-error/50
    data-[status=disabled]:opacity-40
  `,
}
