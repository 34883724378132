import { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type Direction = 'up' | 'down' | null

export function useScrollDirection(maxWait = 50): Direction {
  const lastScrollTop = useRef(0)
  const [scrollDirection, setScrollDirection] = useState<Direction>(null)
  const debounced = useDebouncedCallback(
    () => {
      const currentPos = window.pageYOffset || document.documentElement.scrollTop
      if (currentPos > lastScrollTop.current && scrollDirection !== 'down') {
        setScrollDirection('down')
      }
      if (currentPos < lastScrollTop.current && scrollDirection !== 'up') {
        setScrollDirection('up')
      }
      lastScrollTop.current = currentPos <= 0 ? 0 : currentPos
    },
    maxWait,
    { maxWait: maxWait },
  )

  useEffect(() => {
    window.addEventListener('scroll', debounced, false)
    return () => {
      window.removeEventListener('scroll', debounced, false)
    }
  }, [debounced])

  return scrollDirection
}
