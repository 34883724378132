import { Icon } from '../Icon'
import { rgba } from 'polished'
import { FC } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background: ${(p) => rgba(p.theme.colors.error, 0.1)};
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem;
  color: ${(p) => p.theme.colors.error};
  display: flex;
  font-family: ${(p) => p.theme.fonts.body};
  font-weight: bold;
  vertical-align: top;
  line-height: 1.4;
`

const IconContainer = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  background: ${(p) => rgba(p.theme.colors.error, 0.2)};
  color: ${(p) => p.theme.colors.error};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
  border-radius: 2px;
`

type Props = {}

export const ErrorNotice: FC<Props> = ({ children }) => {
  return (
    <Container>
      <IconContainer>
        <Icon icon="exclamation" />
      </IconContainer>
      {children}
    </Container>
  )
}
