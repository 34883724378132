import { cn } from '@lib/cn'
import React from 'react'

type TrackProps = React.HTMLAttributes<HTMLDivElement>

export const Track = React.forwardRef<HTMLDivElement, TrackProps>(
  ({ className, children, ...rest }, ref) => {
    return (
      <div
        className={cn(
          'scrollbar-hidden flex snap-x snap-mandatory overflow-x-scroll scroll-smooth',
          'hoverAndPointerScreens:overflow-x-hidden',
          className,
        )}
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    )
  },
)
