import create from 'zustand'

type ThirdPartyScriptStore = {
  rolexScriptIsLoaded: boolean
  setScriptIsLoaded: (type: 'rolex') => void
}

export const useThirdPartyScriptStore = create<ThirdPartyScriptStore>((set) => ({
  rolexScriptIsLoaded: false,
  setScriptIsLoaded: (type) => {
    if (type === 'rolex') {
      set({
        rolexScriptIsLoaded: true,
      })
    }
  },
}))
