export function Search() {
  return (
    <>
      <g className="hide-on-small">
        <path
          d="M24.1406 24.1631L25.1311 25.1536L26.1216 26.1441L28.1026 28.125"
          stroke="currentColor"
          fill="none"
          vectorEffect="non-scaling-size"
          strokeWidth="2"
        />
        <circle
          cx="18.75"
          cy="18.75"
          r="7.75"
          stroke="currentColor"
          fill="none"
          vectorEffect="non-scaling-size"
          strokeWidth="2"
        />
      </g>
      <g className="show-on-small">
        <path
          d="M24.1406 24.1631L28.1026 28.125"
          stroke="currentColor"
          fill="none"
          vectorEffect="non-scaling-size"
          strokeWidth="1"
        />
        <circle
          cx="18.75"
          cy="18.75"
          r="7.5"
          stroke="currentColor"
          fill="none"
          vectorEffect="non-scaling-size"
          strokeWidth="1"
        />
      </g>
    </>
  )
}
