import styled from 'styled-components'

const SocialLink = styled.a`
  transition: all 0.2s ease;
  color: ${(p) => p.theme.colors.shades[600]};
  text-decoration: none;

  &:hover {
    color: ${(p) => p.theme.colors.shades[800]};
  }
`
export default SocialLink
