import { HeaderImageColumn } from '../image-column'
import { HeaderImageListColumn } from '../image-list-column'
import { HeaderLinkColumn } from '../link-column'
import { Link } from '@gassan-ui'
import { JewelryContent } from '@lib/kontent/navigation-helpers/get-jewelry-content'
import NextLink from 'next/link'
import { FC } from 'react'

type HeaderShopMenuJewelryProps = JewelryContent

export const HeaderShopMenuJewelry: FC<HeaderShopMenuJewelryProps> = ({
  linkList,
  brandList,
  imageLink,
}) => {
  return (
    <>
      <HeaderLinkColumn
        title={linkList.title}
        readMoreLink={
          linkList.viewMore && (
            <NextLink href={linkList.viewMore.url} passHref>
              <Link variant="blue">{linkList.viewMore.label}</Link>
            </NextLink>
          )
        }
        links={linkList.links}
        linkSpace="regular"
      />
      <HeaderImageListColumn
        title={brandList.title}
        readMoreLink={
          brandList.viewMore && (
            <NextLink href={brandList.viewMore.url} passHref>
              <Link variant="blue">{brandList.viewMore.label}</Link>
            </NextLink>
          )
        }
        links={brandList.links}
      />
      <HeaderImageColumn {...imageLink} showOnlyOnXlarge />
    </>
  )
}
