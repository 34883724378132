export function IconInstagram() {
  return (
    <>
      <path
        fill="currentColor"
        d="M20.001 7.704c4.005 0 4.48.015 6.06.087a8.28 8.28 0 0 1 2.785.517c.652.24 1.242.623 1.725 1.122a4.636 4.636 0 0 1 1.122 1.725 8.28 8.28 0 0 1 .517 2.784c.072 1.582.087 2.056.087 6.061s-.015 4.48-.087 6.061a8.28 8.28 0 0 1-.517 2.784 4.97 4.97 0 0 1-2.847 2.847 8.28 8.28 0 0 1-2.784.517c-1.582.072-2.056.087-6.061.087s-4.48-.014-6.061-.087a8.28 8.28 0 0 1-2.784-.517A4.636 4.636 0 0 1 9.43 30.57a4.636 4.636 0 0 1-1.122-1.725 8.28 8.28 0 0 1-.517-2.784c-.072-1.582-.087-2.056-.087-6.061s.014-4.48.087-6.061a8.28 8.28 0 0 1 .517-2.784A4.636 4.636 0 0 1 9.43 9.43a4.637 4.637 0 0 1 1.725-1.122 8.28 8.28 0 0 1 2.784-.517c1.582-.072 2.056-.087 6.061-.087Zm0-2.703c-4.073 0-4.584.018-6.184.09a11.056 11.056 0 0 0-3.64.697 7.67 7.67 0 0 0-4.388 4.394 11.055 11.055 0 0 0-.697 3.641c-.072 1.593-.09 2.104-.09 6.177 0 4.073.018 4.584.09 6.184.026 1.244.261 2.475.697 3.64a7.67 7.67 0 0 0 4.394 4.388c1.166.436 2.397.671 3.641.697 1.593.072 2.104.09 6.177.09 4.073 0 4.584-.018 6.184-.09a11.055 11.055 0 0 0 3.64-.697 7.672 7.672 0 0 0 4.388-4.395c.436-1.165.671-2.396.697-3.64.072-1.593.09-2.104.09-6.177 0-4.073-.018-4.584-.09-6.184a11.056 11.056 0 0 0-.697-3.64 7.67 7.67 0 0 0-4.395-4.388 11.056 11.056 0 0 0-3.64-.697c-1.593-.072-2.104-.09-6.177-.09Z"
      />
      <path
        fill="currentColor"
        d="M20 12.297a7.702 7.702 0 1 0 0 15.405 7.702 7.702 0 0 0 0-15.405ZM20 25a5 5 0 1 1 0-10 5 5 0 0 1 0 10ZM28.008 13.793a1.8 1.8 0 1 0 0-3.6 1.8 1.8 0 0 0 0 3.6Z"
      />
    </>
  )
}
