export function SettingsAlt() {
  return (
    <>
      <path d="M11.5 16L28.5 16" stroke="currentColor" className="dynamic-stroke-width" />
      <path d="M11.5 24L28.5 24" stroke="currentColor" className="dynamic-stroke-width" />
      <path d="M15.5 13L18.5 16L15.5 19L12.5 16L15.5 13Z" fill="currentColor" />
      <path d="M24.5 21L27.5 24L24.5 27L21.5 24L24.5 21Z" fill="currentColor" />
    </>
  )
}
