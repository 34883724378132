export function ChevronRightSmall() {
  return (
    <path
      d="M18.125 24.375C18.4659 22.6923 21.875 20 21.875 20C21.875 20 18.4659 17.3077 18.125 15.625"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
    />
  )
}
