import styled from 'styled-components'
import { ColorProps, SizeProps, SpaceProps, color, compose, size, space } from 'styled-system'

export const CleanButton = styled.button<ColorProps & SpaceProps & SizeProps>`
  ${compose(space, color, size)}
  border: 0;
  outline: none;
`

CleanButton.defaultProps = {}
