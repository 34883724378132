const defaultSources = [
  '/logos/amex.png',
  '/logos/mastercard.png',
  '/logos/visa.png',
  '/logos/bancontact.png',
  '/logos/paypal.png',
  '/logos/alipay.png',
  '/logos/unionpay.png',
]

export function getSupportedPaymentImageSources(lang: string) {
  if (lang === 'de') {
    return defaultSources
  }

  return [...defaultSources.slice(0, 1), '/logos/ideal.png', ...defaultSources.slice(1)]
}
