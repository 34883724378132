import styled from 'styled-components'

const Link = styled.a`
  font-family: ${(p) => p.theme.fonts.body};
  transition: all 0.2s ease;
  font-size: 1rem;
  color: ${(p) => p.theme.colors.shades[600]};
  display: inline-block;
  background: none;
  outline: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  &:hover {
    color: ${(p) => p.theme.colors.shades[800]};
    border-bottom-color: currentColor;
  }
`

export default Link
