import { breakpoints } from '../theme'
import { Box } from './Box'
import styled, { css } from 'styled-components'

export { Box } from './Box'
export { LinariaBox } from './LinariaBox'

export const Flex = styled(Box)`
  display: flex;
`

export const Grid = styled(Box)`
  display: grid;
`

Grid.defaultProps = {
  as: 'section',
}

export const containerStyle = css`
  width: calc(100% - 2rem);
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: ${breakpoints.medium}) {
    width: calc(100% - 3rem);
  }
  @media screen and (min-width: ${breakpoints.xlarge}) {
    width: calc(100% - 5.5rem);
  }
`

export const Container = styled(Box)`
  ${containerStyle};
`

Container.defaultProps = {
  as: 'section',
  maxWidth: '109rem',
}
