export function AccountLogout() {
  return (
    <>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        d="M17 26.5c.485-2.5 5.338-6.5 5.338-6.5S17.485 16 17 13.5M22 20H8"
      ></path>
      <path
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
        d="M22 12h4a2 2 0 012 2v12a2 2 0 01-2 2h-4"
      ></path>
    </>
  )
}
