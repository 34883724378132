import { FC } from 'react';
const list = "lpn1t66";
type MenuListProps = {
  space: 'large' | 'regular' | 'small';
};
export const MenuList: FC<MenuListProps> = ({
  space,
  children
}) => {
  return <ul data-space={space} className={list}>
      {children}
    </ul>;
};

require("./MenuList.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./MenuList.tsx");