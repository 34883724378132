import create from 'zustand'

type Variant = 'wishlist' | 'brand' | null

type MembershipModalStore = {
  showMembershipVariant: Variant
  setShowMembershipModalVariant: (val: Variant) => void
}

const useMembershipModalStore = create<MembershipModalStore>((set) => ({
  showMembershipVariant: null,
  setShowMembershipModalVariant: (val: Variant) => set({ showMembershipVariant: val }),
}))

export function useMembershipModal() {
  const store = useMembershipModalStore()

  return store
}
