export function Wheelchair() {
  return (
    <>
      <path
        d="M22.6183 23.3255C22.8185 24.2406 22.729 25.1953 22.3622 26.0572C21.9954 26.9191 21.3694 27.6455 20.5712 28.1356C19.7729 28.6258 18.8419 28.8553 17.9073 28.7925C16.9727 28.7297 16.0808 28.3776 15.3553 27.785C14.6298 27.1925 14.1067 26.3888 13.8586 25.4856C13.6104 24.5823 13.6495 23.6242 13.9703 22.7442C14.2912 21.8641 14.878 21.1058 15.6493 20.5742C16.4206 20.0427 17.3383 19.7644 18.2749 19.7778"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
      />
      <path
        d="M27.857 25.4182L25.6007 26.5464L22.9683 23.1619L18.0796 22.7859L18.5862 19.0253L20.336 15.2648H16.9515L15.4473 18.2732"
        stroke="currentColor"
        className="dynamic-stroke-width"
        fill="none"
        strokeLinejoin="round"
      />
      <ellipse cx="21.4643" cy="11.8803" rx="1.88027" ry="1.88027" fill="currentColor" />
    </>
  )
}
