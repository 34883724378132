export function AccountSettings() {
  return (
    <>
      <path
        stroke="currentColor"
        fill="none"
        strokeLinejoin="bevel"
        className="dynamic-stroke-width"
        d="M19.5 10l1.28 1.367a3 3 0 002.754.895l1.837-.352.234 1.862a3 3 0 001.7 2.34L29 16.91l-.904 1.645a3 3 0 000 2.89L29 23.09l-1.695.798a3 3 0 00-1.7 2.34l-.234 1.862-1.837-.352a3 3 0 00-2.753.895L19.5 30l-1.28-1.367a3 3 0 00-2.754-.895l-1.837.352-.234-1.862a3 3 0 00-1.7-2.34L10 23.09l.904-1.645a3 3 0 000-2.89L10 16.91l1.695-.798a3 3 0 001.7-2.34l.234-1.862 1.837.352a3 3 0 002.754-.895L19.5 10z"
      ></path>
      <circle
        cx="19.5"
        cy="20"
        r="3"
        stroke="currentColor"
        fill="none"
        className="dynamic-stroke-width"
      ></circle>
    </>
  )
}
