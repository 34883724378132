import { parse } from '@config/theme';
export const button = parse({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  position: 'relative',
  bg: 'pampas'
}, "b14wc1l9");
export const container = parse({
  width: '100%',
  position: 'absolute',
  bg: 'pampasLight'
}, "c1le4x19");
export const subRowsContainer = "sl7i0hh";

require("./Row.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Row.styles.ts");