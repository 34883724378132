// When a fetch fails because of a 3** or 5** error, the fetch call doesn't throw
// It's easier to have it throw, because that makes it easier to catch failed requests
// handleFetchResponse is used like this: fetch(url).then(handleFetchResponse)
// If the response is ok (status 2**) it will return the json output of the body
// If it isn't ok, it will throw with the statusText
export function handleFetchResponse(res: Response) {
  if (res.ok) {
    return res.json()
  } else {
    throw new Error(res.statusText)
  }
}
